import {CloudArrowUpIcon} from '@heroicons/react/20/solid';
import {useEffect, useRef, useState} from 'react';

const AUDIO_EXTENSIONS =
  '.3ga,.webm,.8svx,.mts,.m2ts,.ts,.aac,.mov,.ac3,.mp2,.aif,.mp4,.m4v,.aiff,.mxf,.alac,.amr,.ape,.au,.dss,.flac,.flv,.m4a,.m4b,.m4p,.m4r,.mp3,.mpga,.ogg,.oga,.mogg,.opus,.qcp,.tta,.voc,.wav,.wma,.wv';
const CHAPTER_EXTENSIONS = '.chapters,.text,.txt';

export interface UploadDocumentProps {
  header: string;
  type?: UploadType;
  open: boolean;
  upload: (files: FileList, type: UploadType, name?: string) => void;
  onClose: () => void;
}

export enum UploadType {
  Recording = 'Recording',
  Chapters = 'Chapters',
  Any = 'Any',
}

export function UploadDocument({
  header,
  type,
  open,
  upload,
  onClose,
}: UploadDocumentProps) {
  const [files, setFiles] = useState<FileList | null>(null);
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (dialogRef.current && open) {
      dialogRef.current.showModal();
    }
  }, [open, dialogRef]);

  const clearInput = () => {
    setFiles(null);
  };

  let extensions = '*';
  if (type === UploadType.Recording) {
    extensions = AUDIO_EXTENSIONS;
  } else if (type === UploadType.Chapters) {
    extensions = CHAPTER_EXTENSIONS;
  }

  return (
    <dialog className="modal" ref={dialogRef}>
      <form method="dialog" className="modal-box flex flex-col gap-4 w-full">
        <h2 className="text-2xl font-bold">{header}</h2>
        <input
          onChange={event => setFiles(event.target.files)}
          type="file"
          accept={extensions}
          className="file-input file-input-bordered w-full max-w-xs"
        />
        <div className="flex flex-row gap-2 w-full justify-end">
          <button
            className="btn btn-neutral btn-sm"
            onClick={() => {
              clearInput();
              onClose();
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-sm flex flex-row items-center gap-2"
            disabled={files === null || files.length === 0}
            onClick={() => {
              if (files) {
                clearInput();
                upload(files, type ?? UploadType.Any);
                onClose();
              }
            }}
          >
            <CloudArrowUpIcon className="w-4" />
            Upload
          </button>
        </div>
      </form>
    </dialog>
  );
}
