import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  GenericAbortSignal,
} from 'axios';
import {DataNodeDefData, NodeDef} from '../components/talos/types/node';
import {
  Observable,
  Subject,
  from,
  interval,
  lastValueFrom,
  merge,
  mergeMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

import {ApiResponse} from '../../bindings/api/ApiResponse';
import {CollectionEndpoints} from './client/collection';
import {DataNode} from '../../bindings/DataNode';
import {PaymentEndpoints} from './client/payment';
import {TranscriptionConfig} from '../../bindings/TranscriptionConfig';
import {UserWorkflowSummary} from '../../bindings/api/UserWorkflowSummary';
import {WorkflowEndpoints} from './client/workflows';
import {WorkflowTrigger} from '../../bindings/WorkflowTrigger';
import {UserWorkflowInstanceSummary} from '../../bindings/api/UserWorkflowInstanceSummary';
import {UserWorkflowStatus} from '../../bindings/api/UserWorkflowStatus';
import {AuthEndpoints} from './client/auth';

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
// Storage location for API keys / etc.
export const LOCAL_TOKEN_STORAGE = 'SG_TOKEN';
export const MAX_FILE_SIZE = 500000000;

export type WorkflowSuggestionCallback = (update: ChatUpdate) => void;

export interface JsonResponse {
  content: any;
}

export interface ProcessedAudio {
  language_model: string;
  acoustic_model: string;
  language_code: string;
  status: string;
  utterances: ProcessedAudioUtterance[];
  audio_duration: number;
  error: any;
  text: string;
}

export interface ProcessedAudioUtterance {
  confidence: number;
  start: number;
  end: number;
  speaker?: string;
  text: string;
}

export interface ParsedDocContent {
  content: string;
  meta: {[key: string]: string};
  title?: string;
  segments?: DocumentSegments[];
}

export interface DocumentSegments {
  Time: DocumentTimeSegment;
  Page: DocumentPageSegment;
}

export interface DocumentTimeSegment {
  segment_length: number;
  start_timestamp: number;
  end_timestamp: number;
}

export interface DocumentPageSegment {
  page_num: number;
  char_start: number;
  char_end: number;
}

export type ChatUpdate =
  | string
  | {ChatStart: string}
  | {WorkflowSuggestion: WorkflowSuggestion}
  | {Error: {UnknownError: string}}
  | {Token: string};

export interface UserConnection {
  id: number;
  apiId: string;
  account: string;
  grantedAt: string;
}

export interface ConnectionRequest {
  url: string;
}

export interface AuthorizeRequest {
  scope: string | null;
  state: string | null;
  code: string | null;
}

export interface FeatureDefinitions {
  enabledFeatures: FeatureDefinition[];
}

export interface FeatureDefinition {
  feature: string;
}

export interface UserWorkflow {
  uuid: string;
  version?: string;
  name?: string;
  on?: WorkflowTrigger;
  input?: DataNode;
  flow: UserWorkflowDef;
}

export interface UserWorkflowDef {
  steps: NodeDef[];
}

export interface WorkflowResponse {
  workflow: UserWorkflow;
  workflowState: UserWorkflowState;
  workflowType: UserWorkflowType;
}

export interface WorkflowSuggestion {
  name?: string;
  userMessage?: string;
  actions: WorkflowSuggestionAction<any>[];
}

export enum SuggestionActionType {
  UrlInput = 'urlInput',
  Summarize = 'summarize',
  Template = 'template',
  TextInput = 'textInput',
  PdfInput = 'pdfInput',
  TextFileInput = 'textFileInput',
  Extract = 'extract',
  Loop = 'loop',
  ReadGoogleSheet = 'readGoogleSheet',
}

export interface WorkflowSuggestionAction<T> {
  workflowAction: SuggestionActionType;
  workflowActionProperties: T;
}

export interface UrlInputAction {
  url: string;
}

export interface TemplateAction {
  template: string;
}

export interface TextInputAction {
  text: string;
}

export interface ReadGoogleSheet {
  sheetId: string;
  tabName: string;
}

export interface SystemChatMessage {
  role: string;
  message: string[];
}

export interface LoopAction {
  actions: WorkflowSuggestionAction<any>[];
}

export interface WorkflowPage {
  page: number;
  pageSize: number;
  totalCount: number;
  workflows: WorkflowSummary[];
}

export interface WorkflowSummary {
  name: string;
  uuid: string;
  numFailed: number;
  numCompleted: number;
  numQueued: number;
  numTotal: number;
  lastRunStatus: UserWorkflowStatus | null;
  lastRunErrorMsg: string | null;
  lastRunFinishedOn: string | null;
}

export enum UserWorkflowState {
  Active = 'Active',
  Disabled = 'Disabled',
}

export enum UserWorkflowType {
  User = 'User',
  Template = 'Template',
}

export interface UserWorkflowInstanceResult {
  taskUuid: string;
  taskDefUuid: string;
  taskStatus: UserWorkflowStatus;
  taskType: WorkflowTaskType;
  createdAt: string;
  taskData?: any;
  finishedOn?: string;
  errorMsg?: string;
  input?: any;
  output?: any;
}

export enum WorkflowTaskType {
  Summarize = 'Summarize',
  TextData = 'TextData',
  DocumentData = 'DocumentData',
  CrawlUrl = 'CrawlUrl',
  Extract = 'Extract',
  TextGeneration = 'TextGeneration',
  TemplateExpansion = 'TemplateExpansion',
  ConnectionSource = 'ConnectionSource',
  JsonData = 'JsonData',
  CustomProcessing = 'CustomProcessing',
  InputData = 'InputData',
  AudioTranscription = 'AudioTranscription',
}

export interface UserWorkflowDefSummary {
  name: string;
  uuid: string;
}

export enum WorkflowSummaryStatus {
  Ok = 'Ok',
  Degraded = 'Degraded',
  Failing = 'Failing',
  NoRuns = 'NoRuns',
}

export interface SavedTranscriptTextMessage {
  speaker: string;
  text: string;
  confidence: number;
  start: number;
  end: number;
}

export interface SavedTranscriptData {
  status: string;
  utterances: SavedTranscriptTextMessage[];
  error: string;
}

export interface Checklist {
  version: number;
  checklist: ChecklistDefinition;
}

export interface SavedChecklist {
  uuid: string;
  version: number;
  checklist: ChecklistDefinition;
}

export interface ChecklistDefinition {
  name: string;
  items: GoalItem[];
}

export interface GoalItem {
  uuid: string;
  title: string;
  description: string;
  isComplete: boolean;
}

export interface CollectionTaskDefinition {
  task: CollectionTaskType;
  metadata?: any;
}

export enum CollectionTaskType {
  BuildFullVTT = 'BuildFullVTT',
}

export interface TaskDefinition {
  task: DocumentTaskType;
  metadata?: any;
}

export enum DocumentTaskType {
  Transcribe = 'transcribe',
}

export interface TranscriptionMetadata {
  transcription_config: TranscriptionConfig;
}

export enum TranscriptionLanguageCode {
  English = 'English',
  AustralianEnglish = 'AustralianEnglish',
  BritishEnglish = 'BritishEnglish',
  UsEnglish = 'UsEnglish',
  Spanish = 'Spanish',
  French = 'French',
  German = 'German',
  Italian = 'Italian',
  Portuguese = 'Portuguese',
  Dutch = 'Dutch',
  Hindi = 'Hindi',
  Japanese = 'Japanese',
  Chinese = 'Chinese',
  Finnish = 'Finnish',
  Korean = 'Korean',
  Polish = 'Polish',
  Russian = 'Russian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Vietnamese = 'Vietnamese',
}

export interface WorkflowTaskResponse {
  tasks: GeneralTaskResponse[];
  status: UserWorkflowStatus;
}

export interface GeneralTaskResponse {
  uuid: string;
  status: GeneralTaskStatus;
  taskType: GeneralTaskType;
  result?: any;
  error?: string;
  operationsComplete: number;
  operationCount: number;
  finishedOn: string;
  startedOn: string;
}

export enum GeneralTaskType {
  Summarize = 'Summarize',
  ConnectionSource = 'ConnectionSource',
  JsonData = 'JsonData',
  TextData = 'TextData',
  CrawlUrl = 'CrawlUrl',
  DocumentData = 'DocumentData',
  Extract = 'Extract',
  TextGeneration = 'TextGeneration',
  TemplateExpansion = 'TemplateExpansion',
  CustomProcessing = 'CustomProcessing',
  AudioTranscription = 'AudioTranscription',
  DataDestination = 'DataDestination',
}

export enum GeneralTaskStatus {
  Queued = 'Queued',
  Started = 'Started',
  Processing = 'Processing',
  Complete = 'Complete',
  Failed = 'Failed',
}

export interface TaskStatusFilter {
  taskType?: GeneralTaskType;
  taskStatus?: GeneralTaskStatus;
  documentUuid?: string;
}

export interface ManualTriggerUserWorkflow {
  uuid: String;
  inputData?: DataNode;
}

export interface WorkflowStateRequest {
  state: UserWorkflowState;
}

export function create_headers(token: string | null | undefined): HeadersInit {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  return headers;
}

export async function authorizeConnection(
  token: string,
  apiId: string,
  body: AuthorizeRequest
): Promise<ApiResponse<string>> {
  return fetch(`${API_ENDPOINT}/connections/authorize/${apiId}`, {
    method: 'POST',
    headers: create_headers(token),
    body: JSON.stringify(body),
  }).then(res => res.json());
}

export async function requestConnection(
  token: string,
  apiId: string
): Promise<ApiResponse<ConnectionRequest>> {
  return fetch(`${API_ENDPOINT}/connections/authorize/${apiId}`, {
    method: 'GET',
    headers: create_headers(token),
  }).then(res => res.json());
}

export async function listConnections(
  token: string
): Promise<ApiResponse<UserConnection[]>> {
  return fetch(`${API_ENDPOINT}/connections`, {
    method: 'GET',
    headers: create_headers(token),
  }).then(res => res.json());
}

export async function deleteConnection(
  token: string,
  apiId: number
): Promise<ApiResponse<UserConnection[]>> {
  return fetch(`${API_ENDPOINT}/connections/${apiId}`, {
    method: 'DELETE',
    headers: create_headers(token),
  }).then(res => res.json());
}

export interface SummaryResult {
  message: string;
}

export interface CheckoutResponse {
  url: string;
}

export interface MicrosoftTodoConnectionRequest {
  MicrosoftTodo: MicrosoftTodoConnectionRequestDef;
}

export interface MicrosoftTodoConnectionRequestDef {
  action: MicrosoftTodoAction;
  request: MicrosoftTodoRequest;
}

export enum MicrosoftTodoAction {
  CreateList = 'CreateList',
  AddTasks = 'AddTasks',
}

export interface MicrosoftTodoRequest {
  task?: MicrosoftTodoTaskData[];
  list?: string;
}

export interface MicrosoftTodoTaskData {
  title: string;
  details?: string;
  dueDate?: string;
}

export function waitForWorkflowCompletion(
  client: SightglassClient,
  workflowUUID: string,
  cancel: Observable<boolean>,
  onUpdate: (update: ApiResponse<WorkflowTaskResponse>) => void,
  intervalTime = 3000
): Promise<ApiResponse<WorkflowTaskResponse>> {
  const finished = new Subject<boolean>();
  return lastValueFrom(
    timer(500, intervalTime)
      .pipe(
        takeUntil(merge(finished, cancel)),
        mergeMap(() => from(client.workflow.getTasks(workflowUUID)))
      )
      .pipe(
        tap(val => {
          if (val.result) {
            if (
              val.result.status === 'Failed' ||
              val.result.status === 'Complete'
            ) {
              finished.next(true);
              finished.complete();
            } else {
              onUpdate(val);
            }
          }
        })
      )
  );
}

export function waitForTaskCompletion(
  client: SightglassClient,
  taskUUID: string,
  maxTry: number,
  intervalTime = 3000
): Promise<ApiResponse<GeneralTaskResponse>> {
  const finished = new Subject<boolean>();
  return lastValueFrom(
    interval(intervalTime)
      .pipe(
        takeUntil(merge(finished)),
        mergeMap(val => {
          // val is 0 indexed
          if (val >= maxTry - 1) {
            finished.next(true);
            finished.complete();
          }
          return from(client.getTaskStatus(taskUUID));
        })
      )
      .pipe(
        tap(val => {
          if (
            val.result?.status.startsWith('Complete') ||
            val.result?.status === 'Failed'
          ) {
            finished.next(true);
            finished.complete();
          }
        })
      )
  );
}

export class SightglassClient {
  token: string;

  public client: AxiosInstance;
  public auth: AuthEndpoints;
  public collection: CollectionEndpoints;
  public payment: PaymentEndpoints;
  public workflow: WorkflowEndpoints;

  constructor(token: string) {
    this.token = token;
    this.client = axios.create({
      baseURL: API_ENDPOINT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // Wait up to two minutes before timing out.
      timeout: 120000,
    });

    this.auth = new AuthEndpoints(this);
    this.collection = new CollectionEndpoints(this);
    this.payment = new PaymentEndpoints(this);
    this.workflow = new WorkflowEndpoints(this);
  }

  async get(
    endpoint: string,
    query?: {[key: string]: any},
    config?: AxiosRequestConfig
  ): Promise<any> {
    const url = new URL(`${API_ENDPOINT}/${endpoint}`);
    if (query) {
      for (const param in query) {
        url.searchParams.append(param, query[param]);
      }
    }
    return this.client.get(url.href, config).then(resp => resp.data);
  }

  async getJson<T>(endpoint: string, query?: {[key: string]: any}): Promise<T> {
    const url = new URL(`${API_ENDPOINT}/${endpoint}`);
    if (query) {
      for (const param in query) {
        const value = query[param];
        // Arrays need to be flattened out.
        if (value && Array.isArray(value)) {
          value.map(val => url.searchParams.append(param, val.toString()));
        } else if (value) {
          url.searchParams.append(param, value.toString());
        }
      }
    }

    return this.client.get<T>(url.href).then(resp => resp.data);
  }

  async deleteJson<T>(endpoint: string): Promise<T> {
    return this.client.delete<T>(endpoint).then(resp => resp.data);
  }

  async postJson<T>(
    endpoint: string,
    data?: any,
    abortSignal?: GenericAbortSignal
  ): Promise<ApiResponse<T>> {
    const config: AxiosRequestConfig = {
      signal: abortSignal,
    };

    const response = await this.client
      .post<ApiResponse<T>>(endpoint, data, config)
      .then(resp => resp.data)
      .catch((err: AxiosError) => {
        console.error('axios error: ', err);
        throw err;
      })
      .catch(err => {
        console.error('other error: ', err);
        throw err;
      });

    return response;
  }

  async putJson<T>(
    endpoint: string,
    data?: any,
    abortSignal?: GenericAbortSignal
  ): Promise<ApiResponse<T>> {
    const config: AxiosRequestConfig = {
      signal: abortSignal,
    };

    const response = await this.client
      .put<ApiResponse<T>>(endpoint, data, config)
      .then(resp => resp.data)
      .catch((err: AxiosError) => {
        console.error('axios error: ', err);
        throw err;
      })
      .catch(err => {
        console.error('other error: ', err);
        throw err;
      });

    return response;
  }

  async patchJson<T>(
    endpoint: string,
    data?: any,
    abortSignal?: GenericAbortSignal
  ): Promise<ApiResponse<T>> {
    const config: AxiosRequestConfig = {
      signal: abortSignal,
    };

    const response = await this.client
      .patch<ApiResponse<T>>(endpoint, data, config)
      .then(resp => resp.data)
      .catch((err: AxiosError) => {
        console.error('axios error: ', err);
        throw err;
      })
      .catch(err => {
        console.error('other error: ', err);
        throw err;
      });

    return response;
  }

  async checklists(): Promise<ApiResponse<SavedChecklist[]> | null> {
    return this.getJson<ApiResponse<SavedChecklist[]>>('checklist');
  }

  async updateWorkflowSate(
    workflowUuid: string,
    state: UserWorkflowState
  ): Promise<ApiResponse<UserWorkflowSummary> | null> {
    const content: WorkflowStateRequest = {
      state,
    };
    return this.putJson(`workflow/state/${workflowUuid}`, content);
  }

  async saveChecklist(
    checklist: Checklist
  ): Promise<ApiResponse<SavedChecklist> | null> {
    return this.postJson<SavedChecklist>('checklist', checklist);
  }

  async deleteChecklist(uuid: string): Promise<ApiResponse<number> | null> {
    return this.deleteJson<ApiResponse<number>>(`checklist/${uuid}`);
  }

  async updateChecklist(
    uuid: string,
    checklist: Checklist
  ): Promise<ApiResponse<SavedChecklist> | null> {
    return this.putJson<SavedChecklist>(`checklist/${uuid}`, checklist);
  }

  async summarizeText(
    text: string
  ): Promise<ApiResponse<SummaryResult> | null> {
    return this.postJson<SummaryResult>('action/summarize', {
      summaryType: 'BulletPoint',
      fastSummary: false,
      text,
    });
  }

  async extractJson(
    query: string,
    text: string,
    schema: any
  ): Promise<ApiResponse<DataNodeDefData> | null> {
    const data = {
      query,
      textSource: {
        sourceType: 'text',
        data: text,
      },
      jsonSchema: schema,
    };

    return this.postJson<JsonResponse>('action/ask', data);
  }

  async listConnections(): Promise<ApiResponse<UserConnection[]>> {
    return listConnections(this.token);
  }

  async executeConnectionRequest(
    connection: string,
    request: any
  ): Promise<ApiResponse<any>> {
    return this.postJson<any>(`connections/${connection}`, request);
  }

  async getWorkflowOverview(page: number): Promise<ApiResponse<WorkflowPage>> {
    const params = {page: page.toString()};
    return this.getJson<ApiResponse<WorkflowPage>>('workflow/overview', params);
  }

  async getWorkflowInstance(
    workflowInstance: string
  ): Promise<ApiResponse<UserWorkflowInstanceSummary>> {
    return this.getJson(`workflow/run/${workflowInstance}`);
  }

  async generateText(
    query: string,
    input: string
  ): Promise<ApiResponse<string>> {
    const data = {
      config: {
        query,
      },
      input,
    };
    return this.postJson('action/generate', data);
  }

  async getAllTaskStatus(
    filter: TaskStatusFilter
  ): Promise<ApiResponse<GeneralTaskResponse[]>> {
    const query: {[key: string]: string} = {};
    if (filter.taskStatus) {
      query['taskStatus'] = filter.taskStatus.toString();
    }

    if (filter.taskType) {
      query['taskType'] = filter.taskType.toString();
    }

    if (filter.documentUuid) {
      query['documentUuid'] = filter.documentUuid;
    }

    return this.getJson('tasks', query);
  }

  async getTaskStatus(
    taskUuid: string
  ): Promise<ApiResponse<GeneralTaskResponse>> {
    return this.getJson(`tasks/${taskUuid}`);
  }
}
