import {useEffect, useState} from 'react';
import {DataNodeDefData} from '../../types/node';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {GlobeAltIcon} from '@heroicons/react/20/solid';
import {Step} from '../../../../../bindings/Step';
import {EditableText} from '../../editable';

export class RssNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Web Source';
  public nodeLabel = 'RSS Feed';
  public getNodeIcon(className?: string): JSX.Element {
    return <GlobeAltIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <RssDataNode {...baseProps}></RssDataNode>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'DataSource',
      templateId: null,
      data: {
        type: 'rssFeed',
        data: {url: ''},
      },
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const RSS_NODE_DEF = new RssNodeDefinition();

export function RssDataNode({data, onUpdateData = () => {}}: NodeBodyProps) {
  const nodeData = data as DataNodeDefData;
  const [dataValue, setDataValue] = useState<string>('');

  useEffect(() => {
    setDataValue(nodeData.url ?? '');
  }, [nodeData]);

  const handleOnChange = (newVal: string) => {
    setDataValue(newVal ?? '');
    onUpdateData({
      url: newVal,
    } as DataNodeDefData);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="join items-center bg-base-100 px-4">
        <div className="join-item">
          <GlobeAltIcon className="w-4" />
        </div>
        <EditableText
          className="input p-3 join-item w-full placeholder:text-gray-700 border-none"
          data={dataValue}
          onChange={newVal => handleOnChange(newVal)}
        />
      </div>
    </div>
  );
}
