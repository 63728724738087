import React, {useState, MouseEvent} from 'react';
import {ClipboardIcon} from '@heroicons/react/20/solid';

interface CopyProps {
  text: string;
}

const CopyTxtToClipboardButton = ({text}: CopyProps) => {
  const [isCopying, setIsCopying] = useState<boolean>(false);

  const copyToClipboard = async (e: MouseEvent) => {
    setIsCopying(true);
    e.preventDefault();
    if ('clipboard' in navigator) {
      return await navigator.clipboard
        .writeText(text ?? '')
        .then(() => setTimeout(() => setIsCopying(false), 256));
    }
  };

  return (
    <div className={'flex flex-row gap-1 items-center w-full'}>
      <input
        type="text"
        value={text}
        className="input rounded-lg input-bordered input-primary flex-grow"
        readOnly={true}
        autoFocus={false}
      />
      <button
        className="btn btn-square"
        onClick={copyToClipboard}
        disabled={isCopying}
      >
        {isCopying ? (
          <span className="loading loading-spinner" />
        ) : (
          <ClipboardIcon className="h-4 w-4" />
        )}
      </button>
    </div>
  );
};

export default CopyTxtToClipboardButton;
