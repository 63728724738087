import {useEffect, useState} from 'react';
import {NodeBodyProps, NodeDefinition} from '../nodes';
import {TranscriptionLanguageCode} from '../../../api/Client';
import {MicrophoneIcon} from '@heroicons/react/20/solid';
import {Step} from '../../../../bindings/Step';
import {AudioTranscriptionNode} from '../../../../bindings/AudioTranscriptionNode';
import {PiiConfiguration} from '../../../../bindings/PiiConfiguration';

export class TranscriptionNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Transcription';
  public nodeLabel = 'Audio Transcription';
  public getNodeIcon(className?: string): JSX.Element {
    return <MicrophoneIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <AudioTranscription {...baseProps}></AudioTranscription>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'AudioTranscription',
      templateId: null,
      data: {audioSource: null, transcriptionConfig: null},
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const TRANSCRIPTION_NODE_DEF = new TranscriptionNodeDefinition();

export default function AudioTranscription({
  uuid,
  data,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [labelSpeakers, setLabelSpeakers] = useState<boolean>(false);
  const [hidePii, setHidePii] = useState<boolean>(false);

  useEffect(() => {
    const audioData = data as AudioTranscriptionNode;

    setSelectedLanguage(
      audioData.transcriptionConfig?.language_code?.toString() ?? 'auto'
    );
    setHidePii(
      (audioData.transcriptionConfig?.pii?.pii_policies.length ?? 0) > 0 ??
        false
    );
    setLabelSpeakers(
      audioData.transcriptionConfig?.speaker_labels !== null &&
        audioData.transcriptionConfig?.speaker_labels !== undefined
    );
  }, [data]);

  const onSelectLanguage = (language: string) => {
    const audioData = data as AudioTranscriptionNode;
    const updatedValue: AudioTranscriptionNode = {...audioData};

    if (language === 'auto') {
      if (updatedValue.transcriptionConfig) {
        updatedValue.transcriptionConfig.language_code = null;
      }
    } else {
      const langCodeKey = language as keyof typeof TranscriptionLanguageCode;
      const langCode = TranscriptionLanguageCode[langCodeKey];

      if (updatedValue.transcriptionConfig) {
        updatedValue.transcriptionConfig.language_code = langCode;
      } else {
        updatedValue.transcriptionConfig = {
          language_code: langCode,
          pii: null,
          speaker_labels: null,
        };
      }
    }

    console.error('updated value ', updatedValue);
    onUpdateData(updatedValue);
  };

  const toggleLabelSpeakers = () => {
    const updatedValue: AudioTranscriptionNode = {
      ...(data as AudioTranscriptionNode),
    };
    const newValue = !labelSpeakers;
    if (newValue) {
      const speaker_labels = {expected_speakers: 0};

      if (updatedValue.transcriptionConfig) {
        updatedValue.transcriptionConfig.speaker_labels = speaker_labels;
      } else {
        updatedValue.transcriptionConfig = {
          language_code: null,
          pii: null,
          speaker_labels,
        };
      }
    } else if (updatedValue.transcriptionConfig) {
      updatedValue.transcriptionConfig.speaker_labels = null;
    }

    setLabelSpeakers(newValue);
    onUpdateData(updatedValue);
  };

  const toggleHidePii = () => {
    const updatedValue: AudioTranscriptionNode = {
      ...(data as AudioTranscriptionNode),
    };
    const newValue = !hidePii;
    if (newValue) {
      const pii: PiiConfiguration = {
        pii_policies: [
          'CreditCardNumber',
          'EmailAddress',
          'PhoneNumber',
          'PersonName',
        ],
        pii_redacted_audio: null,
        pii_substitution: 'Hash',
      };

      if (updatedValue.transcriptionConfig) {
        updatedValue.transcriptionConfig.pii = pii;
      } else {
        updatedValue.transcriptionConfig = {
          language_code: null,
          speaker_labels: null,
          pii,
        };
      }
    } else if (updatedValue.transcriptionConfig) {
      updatedValue.transcriptionConfig.pii = null;
    }

    setHidePii(newValue);
  };

  return (
    <div className="flex flex-col gap-4 w-full" key={uuid}>
      <label className="form-control w-full flex flex-row justify-between items-center gap-4">
        <span className="label-text text-base font-bold">Language</span>
        <select
          value={selectedLanguage}
          defaultValue="select"
          className="select select-bordered w-full max-w-xs"
          onChange={event => onSelectLanguage(event.target.value)}
        >
          <option key="input-collection-header" value="auto">
            Auto Detect
          </option>
          {Object.entries(TranscriptionLanguageCode).map(([languageStr]) => {
            return (
              <option
                key={`input-collection-${languageStr}`}
                value={languageStr}
              >
                {languageStr}
              </option>
            );
          })}
        </select>
      </label>

      <div className="flex flex-col gap-2">
        <label className="form-control flex flex-row w-full justify-between items-center gap-4">
          <div>
            <span className="label-text text-base font-bold">
              Label Speakers
            </span>
            <div className="text-xs text-neutral-500">
              When enabled, if multiple people are speaking in a recording, each
              person will be labeled as "Speaker 1", "Speaker 2", etc.
            </div>
          </div>
          <input
            type="checkbox"
            className="toggle toggle-primary"
            checked={labelSpeakers}
            onChange={() => toggleLabelSpeakers()}
          />
        </label>
      </div>

      <div className="flex flex-col gap-2">
        <label className="form-control flex flex-row w-full justify-between items-center gap-4">
          <div>
            <span className="label-text text-base font-bold">Hide PII</span>
            <div className="text-xs text-neutral-500">
              When enabled, will detect Personally Identifiable Information
              (PII) and remove it from the transcript.
            </div>
          </div>
          <input
            type="checkbox"
            className="toggle toggle-primary"
            checked={hidePii}
            onChange={() => toggleHidePii()}
          />
        </label>
      </div>
    </div>
  );
}
