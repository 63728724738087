import {DocumentIcon, TableCellsIcon} from '@heroicons/react/20/solid';
import {useState, useEffect} from 'react';
import {DataNodeDefData} from '../../../types/node';
import {ConnectionConfig} from '../connection';
import {SiGooglesheets} from '@icons-pack/react-simple-icons';

export const DEFAULT_GSHEET_PROPS: {[key: string]: any} = {
  spreadsheetId: '',
  sheetId: '',
};

export function GoogleSheetConfig({data, updateNodeData}: ConnectionConfig) {
  const nodeData = data as DataNodeDefData;

  const [spreadsheetId, setSpreadsheetID] = useState<string | null>(null);
  const [sheetId, setSheetId] = useState<string | null>(null);
  const [numRows, setNumRows] = useState<number | null>(null);

  useEffect(() => {
    if (
      nodeData.connectionData &&
      nodeData.connectionData.connectionType === 'GSheets'
    ) {
      setSpreadsheetID(nodeData.connectionData.spreadsheetId);
      setSheetId(nodeData.connectionData.sheetId);
      setNumRows(nodeData.connectionData.numRows ?? null);
    }
  }, [nodeData]);

  return (
    <div className="flex flex-col gap-2 rounded-box">
      <div className="flex flex-row gap-2 justify-center items-center rounded-box py-3">
        <SiGooglesheets className="w-8 h-8 text-[#34A853]" />
        <span className="text-lg">Google Sheets</span>
      </div>
      <div className="join items-center bg-base-100">
        <div className="join-item pl-4">
          <TableCellsIcon className="w-4" />
        </div>
        <input
          className="input join-item w-full placeholder:text-gray-700"
          placeholder="Spreadsheet ID"
          value={spreadsheetId || ''}
          onChange={event => {
            const spreadsheetId = event.target.value ?? '';
            setSpreadsheetID(spreadsheetId);
            updateNodeData({spreadsheetId});
          }}
        />
      </div>

      <div className="join items-center bg-base-100">
        <div className="join-item pl-4">
          <DocumentIcon className="w-4" />
        </div>
        <input
          className="input join-item w-full placeholder:text-gray-700"
          placeholder="Sheet title, defaults to first sheet if blank"
          value={sheetId || ''}
          onChange={event => {
            const sheetId = event.target.value ?? '';
            setSheetId(sheetId);
            updateNodeData({sheetId});
          }}
        />
      </div>

      <div className="join items-center bg-base-100">
        <div className="join-item pl-4">
          <DocumentIcon className="w-4" />
        </div>
        <input
          className="input join-item w-full placeholder:text-gray-700"
          placeholder="Number of rows to read, leave blank for all rows"
          value={numRows || ''}
          onChange={event => {
            const numRows = parseInt(event.target.value ?? '');
            setNumRows(numRows ?? null);
            updateNodeData({numRows});
          }}
        />
      </div>
    </div>
  );
}
