import {useEffect, useState} from 'react';
import {ScheduledTrigger} from '../../../../../bindings/ScheduledTrigger';
import {WorkflowTrigger} from '../../../../../bindings/WorkflowTrigger';
import {TriggerDefinition, TriggerProps} from '../../nodes';

class ScheduleTriggerDef implements TriggerDefinition {
  defaultTriggerConfig: WorkflowTrigger = {
    collection: null,
    email: null,
    hubspot: null,
    microsoft: null,
    schedule: 'daily',
    ringcentral: null,
  };
  public renderTrigger(baseProps: TriggerProps): JSX.Element {
    return <ScheduleTriggerConfig {...baseProps}></ScheduleTriggerConfig>;
  }
}
export const SCHEDULED_TRIGGER_DEF = new ScheduleTriggerDef();

export function ScheduleTriggerConfig({
  config,
  onUpdate = () => {},
}: TriggerProps) {
  const [selected, setSelected] = useState<ScheduledTrigger>(
    config.schedule ?? 'daily'
  );

  const options: {label: string; value: ScheduledTrigger}[] = [
    {label: 'Daily', value: 'daily'},
    {label: 'Weekly', value: 'weekly'},
    {label: 'Monthly', value: 'monthly'},
  ];

  useEffect(() => {
    if (selected !== config.schedule) {
      onUpdate({trigger: {schedule: selected} as WorkflowTrigger});
    }
  });

  return (
    <div className="join items-center">
      <div className="join-item bg-base-200 p-2">Run workflow</div>
      <div role="tablist" className="tabs tabs-boxed join-item">
        {options.map(({label, value}) => (
          <a
            key={value}
            role="tab"
            className={`tab ${selected === value ? 'tab-active' : null}`}
            onClick={() => setSelected(value)}
          >
            {label}
          </a>
        ))}
      </div>
    </div>
  );
}
