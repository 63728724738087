export interface VTTIconProps {
  className?: string;
}

export function VTTIcon({className}: VTTIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={className}
    >
      <path
        fill="currentColor"
        d="M182.463 335.866C5.534 319.312 25.397 93.196 172.11 79.298h162.28c159.69 12.311 166.623 236.519 4.72 256.568zm161.622 55.027c238.598-36.552 208.935-351.868-.834-367.657H169.676c-219.358 30.813-221.67 311.072-32.144 361.97l100.204 103.558v-97.87zM128.49 149.203c-8.332-24.284-39.22-12.162-30.434 11.364l38.259 104.254c8.355 24.03 37.44 19.6 42.448 1.108l37.296-99.319h30.578v94.889c-.169 25.136 29.88 26.58 32.095 5.008l.77-99.897h66.646v101.173c.304 17.571 32.268 19.696 32.745-3.443v-97.73h27.737c20.388 0 21.248-28.928 0-28.363H213.675c-11.764-6.606-19.782-1.88-23.162 7.416l-31.59 93.107z"
      ></path>
    </svg>
  );
}
