import {downloadBlob} from './supportUtils';
import {rawAudioToWav} from './waveUtils';

const lamejs = require('lamejs');

export function encodeMono(
  channels: number,
  sampleRate: number,
  samples: Int16Array
): Blob {
  const buffer = [];
  const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
  let remaining = samples.length;
  const maxSamples = 1152;
  for (let i = 0; remaining >= maxSamples; i += maxSamples) {
    const mono = samples.subarray(i, i + maxSamples);
    console.error('mono', mono);
    const mp3buf = mp3enc.encodeBuffer(mono);
    if (mp3buf.length > 0) {
      buffer.push(new Int8Array(mp3buf));
    }
    remaining -= maxSamples;
  }
  const d = mp3enc.flush();
  if (d.length > 0) {
    buffer.push(new Int8Array(d));
  }

  console.log('done encoding, size=', buffer.length);
  const blob = new Blob(buffer, {type: 'audio/mp3'});
  return blob;
}

export function downloadMonoMp3(
  channels: number,
  sampleRate: number,
  samples: Int16Array
) {
  const blob = encodeMono(channels, sampleRate, samples);
  downloadBlob('audio.mp3', blob);
}

export function rawAudioToMonoMp3(rawAudio: Float32Array[]): Blob {
  const wav = rawAudioToWav([rawAudio[0]]);
  wav.toBitDepth('16');
  const buffer = wav.toBuffer();

  const mp3WavView = lamejs.WavHeader.readHeader(new DataView(buffer.buffer));
  const samples = new Int16Array(
    buffer.buffer,
    mp3WavView.dataOffset,
    mp3WavView.dataLen / 2
  );
  return encodeMono(mp3WavView.channels, mp3WavView.sampleRate, samples);
}
