import {NewspaperIcon} from '@heroicons/react/20/solid';
import {NodeBodyProps, NodeDefinition} from '../nodes';
import {Step} from '../../../../bindings/Step';
import {OcrNode} from '../../../../bindings/OcrNode';

export class OcrNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Read Documents';
  public nodeLabel = 'Read Documents';
  public getNodeIcon(className?: string): JSX.Element {
    return <NewspaperIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <Ocr {...baseProps}></Ocr>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      inputSource: null,
      nodeType: 'Ocr',
      templateId: null,
      data: {processingMode: null} as OcrNode,
      parentNode: false,
    };
    return newNode;
  }
}
export const OCR_NODE_DEF = new OcrNodeDefinition();

export function Ocr({uuid}: NodeBodyProps) {
  return (
    <div key={uuid} className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <label className="uppercase font-bold text-xs mb-1">Description</label>
        <div>
          Attempts to reads through all documents provided by the step before.
        </div>
        <ul className="list-disc mx-4">
          <li>
            <em className="font-bold text-accent">PDFs</em> are processed and
            converted to text.
          </li>
          <li>
            Any clear, readable text in{' '}
            <em className="font-bold text-accent">images</em> will read.
          </li>
          <li>
            <em className="font-bold text-accent">Plain text files</em> such as
            .txt, .html, etc will be read as is.
          </li>
        </ul>
      </div>
    </div>
  );
}
