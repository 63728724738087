import {useAuth0} from '@auth0/auth0-react';
import LoginButton from '../components/buttons/LoginButton';

export interface WelcomeDashboardParams {
  redirecting: boolean;
}

export default function WelcomeDashboard({
  redirecting,
}: WelcomeDashboardParams) {
  const {isLoading} = useAuth0();

  const message = redirecting
    ? 'Redirecting To Login'
    : isLoading
    ? 'Loading Sightglass'
    : '';

  return (
    <div className="hero min-h-[480px] bg-base-200">
      <div className="hero-content text-center">
        <div className="flex flex-col items-center">
          {redirecting || isLoading ? (
            <div className="py-6 flex flex-col gap-2 justify-center items-center">
              <span className="text-3xl font-bold">{message}</span>
              <progress className="progress progress-primary"></progress>
            </div>
          ) : null}
          <p className="py-6 text-3xl font-bold">Sightglass AI</p>
          <div className="flex flex-row">
            <LoginButton />
          </div>
        </div>
      </div>
    </div>
  );
}
