import {DocumentTextIcon} from '@heroicons/react/20/solid';
import {NodeBodyProps, NodeDefinition} from '../nodes';
import {Step} from '../../../../bindings/Step';

export class PullDocsNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Pull Documents';
  public nodeLabel = 'Pull Related Documents';
  public getNodeIcon(className?: string): JSX.Element {
    return <DocumentTextIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <PullDocumentAction {...baseProps}></PullDocumentAction>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'PullDocuments',
      templateId: null,
      data: {name: null},
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const PULL_DOCS_NODE_DEF = new PullDocsNodeDefinition();

export function PullDocumentAction({data, onUpdateData}: NodeBodyProps) {
  return (
    <div>
      <span className="text-sm">
        Accesses the content of any documents provided by previous steps
      </span>
    </div>
  );
}
