import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context';
import {DocumentResponse} from '../../../bindings/api/DocumentResponse';

export interface DocumentSelectorProps {
  selectedCollection?: string;
  selectedDocument?: string;
  onSelect: (collectionUuid: string, documentUuid: string) => void;
}

export function DocumentSelector({
  selectedCollection,
  selectedDocument,
  onSelect,
}: DocumentSelectorProps) {
  const {client} = useContext(UserContext);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const [documents, setDocuments] = useState<DocumentResponse[]>([]);
  const [select, setSelect] = useState<string>('select');

  useEffect(() => {
    if (selectedCollection) {
      setLoadingDocuments(true);
      setSelect('select');

      setDocuments([]);
      client?.collection
        .listDocuments(selectedCollection)
        .then(result => {
          if (result.result) {
            setDocuments(result.result);
          }
        })
        .catch(err => {
          console.error('Error accessing documents', err);
        })
        .finally(() => {
          setLoadingDocuments(false);
        });
    } else {
      setDocuments([]);
    }
  }, [client, selectedCollection]);

  useEffect(() => {
    if (selectedDocument) {
      setSelect(selectedDocument);
    }
  }, [selectedDocument]);

  return (
    <div className="flex flex-row gap-2 items-center">
      <span className="font-semibold">Document</span>
      <select
        value={select}
        defaultValue="select"
        className="select select-bordered w-full max-w-xs"
        onChange={event => {
          if (selectedCollection) {
            onSelect(selectedCollection, event.target.value);
          }
        }}
      >
        <option key="input-document-header" value="select" disabled>
          Select Document
        </option>
        {documents.map(document => {
          return (
            <option
              key={`input-document-${document.uuid}`}
              value={document.uuid}
            >
              {document.displayName}
            </option>
          );
        })}
      </select>
      {loadingDocuments ? <span className="loading loading-spinner" /> : null}
    </div>
  );
}
