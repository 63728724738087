export interface RingCentralIconProps {
  className?: string;
}

export function RingCentralIcon({className}: RingCentralIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 64 64"
      fill="#f80"
    >
      <path d="M55.518 27.484c0-1.193 0-1.193 0 0-1.193-2.216-2.216-3.4-4.43-4.43-3.4-2.216-7.84-5.624-16.873-7.84-4.43-1.193-7.84-1.193-10.056 0-6.817 1.193-11.25 3.4-13.464 4.43H9.502a5.45 5.45 0 0 1-7.84 0 5.45 5.45 0 0 1 0-7.84s6.817-6.817 20.28-7.84c4.43 0 9.033 0 13.464 1.193 20.28 3.4 28.12 16.873 28.12 18.066 1.193 2.216 0 5.624-2.216 6.817h-2.216c-.17-.34-2.556-1.534-3.58-2.556zm-12.44 11.248l-2.216-2.216c-1.023-1.023-4.43-3.4-9.033-4.43h-5.624c-4.43 0-6.817 2.216-6.817 2.216-2.216 2.216-4.43 2.216-6.817 0-2.216-2.216-2.216-4.43 0-6.817 0 0 4.43-4.43 12.442-5.624h7.84c12.442 2.216 16.873 10.056 18.066 11.25 1.193 2.216 0 4.43-2.216 5.624H47.5c-2.216 3.4-4.43 2.216-4.43 0zM38.646 49.98c0 5.624-5.624 10.056-11.25 10.056-6.817 0-11.25-4.43-11.25-10.056s5.624-10.056 11.25-10.056c6.647-.17 11.25 4.43 11.25 10.056" />
    </svg>
  );
}
