import {useCallback, useContext, useEffect, useState} from 'react';
import {UserContext} from '../context';
import {useLoaderData} from 'react-router-dom';
import {CollectionView} from './Collections/CollectionView';
import {CollectionResponse} from '../../bindings/api/CollectionResponse';

export interface RecordingViewParams {
  collectionUuid: string;
}

export interface GeneralRecordingsViewParams {
  collection: CollectionResponse;
}

export function RecordingsView() {
  const {collectionUuid} = useLoaderData() as RecordingViewParams;
  const {client} = useContext(UserContext);
  const [collection, setCollection] = useState<CollectionResponse>();

  const fetchCollection = useCallback(() => {
    client?.collection.get(collectionUuid).then(response => {
      if (response.result) {
        const collectionResponse = response.result;
        setCollection(collectionResponse);
      }
    });
  }, [client, collectionUuid]);

  useEffect(() => {
    fetchCollection();
  }, [client, collectionUuid, fetchCollection]);

  return collection ? (
    <CollectionView
      collection={collection}
      onUpdate={() => fetchCollection()}
    />
  ) : null;
}
