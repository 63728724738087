import React, {useContext, useEffect, useState} from 'react';
import {DataNodeDefData} from '../../types/node';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {
  CircleStackIcon,
  DocumentIcon,
  TableCellsIcon,
} from '@heroicons/react/20/solid';
import {UserContext} from '../../../../context';
import {UserConnection} from '../../../../api/Client';
import {SiGooglesheets} from '@icons-pack/react-simple-icons';
import {DestinationConnectionData} from '../../../../../bindings/DestinationConnectionData';
import {Step} from '../../../../../bindings/Step';
import {DestinationAction} from '../../../../../bindings/DestinationAction';
import {EditableText} from '../../editable';

export class DataDestinationNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Google Sheets';
  public nodeLabel = 'Send To Google Sheet';
  public getNodeIcon(className?: string): JSX.Element {
    return <CircleStackIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <DataDestinationNode {...baseProps}></DataDestinationNode>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'DataDestination',
      templateId: null,
      data: {
        connectionData: {
          connectionType: 'GSheets',
          action: 'append',
          connectionId: '',
          sheetId: null,
          spreadsheetId: null,
        },
      },
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const DATA_DESTINATION_NODE_DEF = new DataDestinationNodeDefinition();

export default function DataDestinationNode({
  data,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const {client} = useContext(UserContext);
  const [action, setAction] = useState<string>('update');
  const actions = [
    {label: 'Update Rows', id: 'update' as DestinationAction},
    {label: 'Append to End', id: 'append' as DestinationAction},
  ];

  const nodeData = data as DataNodeDefData;
  const connectionData = nodeData.connectionData as DestinationConnectionData;
  const [userConns, setUserConns] = useState<UserConnection[]>([]);
  const [connectionId, setConnectionId] = useState<string | undefined>();
  const [spreadsheetId, setSpreadsheetID] = useState<string | null>(null);
  const [sheetId, setSheetId] = useState<string | null>(null);

  useEffect(() => {
    if (client) {
      client.listConnections().then(result => {
        const connections = result.result;
        if (connections) {
          setUserConns(
            connections.filter(
              connection => connection.apiId === 'sheets.google.com'
            )
          );
        }
      });
    }
  }, [client]);

  useEffect(() => {
    if (nodeData.connectionData) {
      const data = nodeData.connectionData as DestinationConnectionData;
      setSpreadsheetID(
        data.connectionType === 'GSheets' ? data.spreadsheetId : null
      );
      setSheetId(data.connectionType === 'GSheets' ? data.sheetId : null);
      setAction(data.action);
      setConnectionId(data.connectionId ?? '');
    }
  }, [connectionData, nodeData.connectionData]);

  const updateNodeData = (newData: Partial<DestinationConnectionData>) => {
    onUpdateData({
      connectionData: {
        action: newData.action ?? connectionData.action,
        connectionId: newData.connectionId ?? connectionData.connectionId,
        connectionType: newData.connectionType ?? connectionData.connectionType,
        spreadsheetId: newData.spreadsheetId ?? connectionData.spreadsheetId,
        sheetId: newData.sheetId ?? connectionData.sheetId,
      },
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="tabs tabs-boxed">
        {actions.map(tab => {
          const selected = tab.id === action ? 'tab-active' : '';
          return (
            <div
              key={tab.id}
              className={`tab ${selected}`}
              onClick={() => {
                setAction(tab.id);
                updateNodeData({
                  action: tab.id,
                });
              }}
            >
              {tab.label}
            </div>
          );
        })}
      </div>
      <span className="text-sm self-center">
        {action === 'update'
          ? 'Row data must have `_idx` field to indicate which row to update.'
          : 'Row data will be added to the end of the specified sheet.'}
      </span>
      <div className="join items-center bg-base-100">
        <div className="join-item pl-4">
          <SiGooglesheets className="join-item w-8 h-8 text-[#34A853]" />
        </div>
        <select
          className="input join-item w-full placeholder:text-gray-700"
          onChange={event => {
            const connectionId = event.target.value;
            if (connectionId) {
              setConnectionId(connectionId);
              updateNodeData({
                connectionId,
              });
            }
          }}
          value={connectionId}
          defaultValue={connectionId || ''}
        >
          <option>Select an account</option>
          {userConns.map((conn, idx) => (
            <option key={`conn-${idx}`} value={conn.id}>
              {`${conn.apiId} (${conn.account})`}
            </option>
          ))}
        </select>
      </div>
      <div className="join items-center bg-base-100">
        <div className="join-item pl-4">
          <TableCellsIcon className="w-4" />
        </div>
        <EditableText
          className="input p-3 join-item w-full placeholder:text-gray-700 border-none"
          placeholder="Spreadsheet ID"
          data={spreadsheetId || ''}
          onChange={newVal => {
            const spreadsheetId = newVal ?? '';
            setSpreadsheetID(spreadsheetId);
            updateNodeData({
              spreadsheetId,
            });
          }}
        />
      </div>

      <div className="join items-center bg-base-100">
        <div className="join-item pl-4">
          <DocumentIcon className="w-4" />
        </div>
        <EditableText
          className="input p-3 join-item w-full placeholder:text-gray-700 border-none"
          data={sheetId || ''}
          onChange={newVal => {
            const sheetId = newVal ?? '';
            setSheetId(sheetId);
            updateNodeData({
              sheetId,
            });
          }}
        />
      </div>
    </div>
  );
}
