import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChatBubbleBottomCenterTextIcon,
  MagnifyingGlassCircleIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import {Editor} from '@tiptap/react';
import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../../../../context';
import {waitForTaskCompletion} from '../../../../../api/Client';

enum Views {
  QNA,
  SEARCH,
}

export interface EditorSidebarProps {
  editor: Editor;
  collectionUuid: string;
  documentUuid: string;
}

interface ChatResponse {
  answer: string;
  citations: Citation[];
}

interface Citation {
  quote: string;
  pageNumber?: number;
}

const QUERY_SCHEMA = {
  answer: {
    type: 'string',
    description: 'The answer to the question',
  },
  citations: {
    type: 'array',
    items: {
      type: 'object',
      required: ['quote'],
      properties: {
        quote: {
          type: 'string',
          description: 'A direct quote from the document.',
        },
        pageNumber: {
          type: 'integer',
          description: 'The page number where the quote can be found.',
        },
      },
    },
    description: 'List of citations with direct quotes from the document.',
  },
};

export function EditorSidebar({
  editor,
  collectionUuid,
  documentUuid,
}: EditorSidebarProps) {
  const {client} = useContext(UserContext);
  const [question, setQuestion] = useState<string>('');
  const [asking, setAsking] = useState<boolean>(false);
  const [chatResponse, setChatResponse] = useState<ChatResponse>();
  const [modified, setModified] = useState<boolean>(false);
  const [view, setView] = useState<Views>(Views.QNA);

  const [searchResponse, setSearchResponse] = useState<string[]>();
  const [selectedResponse, setSelectedResponse] = useState<number>(-1);

  const search = () => {
    if (question.trim().length === 0) {
      return;
    }

    setSearchResponse(undefined);
    setAsking(true);
    setSelectedResponse(-1);
    client?.collection
      .searchDocumentContext(collectionUuid, documentUuid, question)
      .then(result => {
        if (result.result && result.result.length > 0) {
          setSearchResponse(result.result[0].context);
        }
      })
      .finally(() => {
        setAsking(false);
      });
  };

  const ask = () => {
    if (question.trim().length === 0) {
      return;
    }
    setChatResponse(undefined);
    const fullQuestion = `${question}. Respond to this question or request with a fully formed well structured answer. Include citations from the document. The citations must be direct quotes from the document with page numbers if known.`;
    setAsking(true);

    setModified(false);

    client?.collection
      .extractFromDocument(collectionUuid, documentUuid, fullQuestion, {
        type: 'object',
        required: ['answer', 'citations'],
        properties: QUERY_SCHEMA,
      })
      .then(response => {
        if (response.result) {
          const taskUuid = response.result;
          waitForTaskCompletion(client, taskUuid, 10)
            .then(response => {
              if (response.result?.result) {
                const chatResponse: ChatResponse =
                  response.result.result.data.data.content;

                setChatResponse(chatResponse);
              }
            })
            .finally(() => {
              setAsking(false);
            });
        } else {
          setAsking(false);
        }
      })
      .catch(err => {
        setAsking(false);
      });
  };

  useEffect(() => {
    setModified(true);
  }, [view]);

  useEffect(() => {
    if (selectedResponse !== undefined && searchResponse) {
      if (selectedResponse >= 0 && selectedResponse < searchResponse.length) {
        const context = searchResponse[selectedResponse];
        if (editor) {
          selectText(editor, context);
        }
      }
    }
  }, [selectedResponse]);

  return (
    <div className="flex flex-col pr-2 w-80 gap-2">
      <div role="tablist" className="tabs tabs-lifted">
        <a
          role="tab"
          className={`tab ${view === Views.QNA ? 'bg-primary' : null}`}
          onClick={() => setView(Views.QNA)}
        >
          <ChatBubbleBottomCenterTextIcon className="w-4 h-4"></ChatBubbleBottomCenterTextIcon>
        </a>
        <a
          role="tab"
          className={`tab ${view === Views.SEARCH ? 'bg-primary' : null}`}
          onClick={() => setView(Views.SEARCH)}
        >
          <MagnifyingGlassIcon className="w-4 h-4"></MagnifyingGlassIcon>
        </a>
      </div>

      {view === Views.QNA ? (
        <>
          <label className="input w-full p-2 font-bold placeholder-gray-500 border-neutral-700 rounded-lg hover:border-accent flex items-center gap-2">
            <input
              type="text"
              placeholder="Ask a Question"
              className="grow placeholder-gray-500 border-neutral-700 bg-base-100 pr-10"
              value={question}
              onChange={e => {
                setQuestion(e.target.value);
                setModified(true);
              }}
              onKeyUp={keyEvent => {
                keyEvent.stopPropagation();
                keyEvent.preventDefault();
                if (keyEvent.code === 'Enter') {
                  ask();
                }
              }}
            ></input>
            <div className="-ml-10 md:-ml-12">
              {asking ? (
                <span className="h-8 loading loading-ring loading-md"></span>
              ) : chatResponse && !modified ? (
                <XCircleIcon
                  className="h-8 w-8 hover:cursor-pointer hover:text-accent"
                  onClick={() => {
                    setChatResponse(undefined);
                    setQuestion('');
                  }}
                ></XCircleIcon>
              ) : (
                <MagnifyingGlassCircleIcon
                  className="h-8 w-8 hover:cursor-pointer hover:text-accent"
                  onClick={() => ask()}
                ></MagnifyingGlassCircleIcon>
              )}
            </div>
          </label>
          {chatResponse ? (
            <ChatResponseResult
              response={chatResponse}
              onSelect={citation => {
                if (editor) {
                  selectText(editor, citation.quote);
                }
              }}
            ></ChatResponseResult>
          ) : null}
        </>
      ) : (
        <div className="flex flex-col ">
          <label className="input w-full p-2 font-bold placeholder-gray-500 border-neutral-700 rounded-lg hover:border-accent  flex items-center gap-2">
            <input
              type="text"
              placeholder="Search"
              className="grow placeholder-gray-500 border-neutral-700 bg-base-100 pr-10"
              value={question}
              onChange={e => {
                setQuestion(e.target.value);
                setModified(true);
              }}
              onKeyUp={keyEvent => {
                keyEvent.stopPropagation();
                keyEvent.preventDefault();
                if (keyEvent.code === 'Enter') {
                  search();
                }
              }}
            ></input>
            <div className="-ml-10 md:-ml-12">
              {asking ? (
                <span className="h-8 loading loading-ring loading-md"></span>
              ) : searchResponse ? (
                <XCircleIcon
                  className="h-8 w-8 hover:cursor-pointer hover:text-accent"
                  onClick={() => {
                    setSearchResponse(undefined);
                    setQuestion('');
                  }}
                ></XCircleIcon>
              ) : (
                <MagnifyingGlassCircleIcon
                  className="h-8 w-8 hover:cursor-pointer hover:text-accent"
                  onClick={() => search()}
                ></MagnifyingGlassCircleIcon>
              )}
            </div>
          </label>
          <div className="flex flex-row gap-2 items-center p-2 ">
            {searchResponse !== undefined && selectedResponse >= 0 ? (
              <span>
                {searchResponse?.length} Results {selectedResponse + 1} /{' '}
                {searchResponse.length}
              </span>
            ) : searchResponse !== undefined ? (
              <span>{searchResponse?.length} Results</span>
            ) : (
              <span>No Results</span>
            )}
            <button
              className="btn btn-ghost"
              onClick={() => {
                setSelectedResponse(selected => {
                  if (searchResponse && selected < searchResponse?.length - 1) {
                    return selected + 1;
                  }

                  return selected;
                });
              }}
            >
              <ArrowDownIcon className="w-4 h-4"></ArrowDownIcon>
            </button>
            <button
              className="btn btn-ghost"
              onClick={() => {
                setSelectedResponse(selected => {
                  if (selected > 0) {
                    return selected - 1;
                  }
                  return selected;
                });
              }}
            >
              <ArrowUpIcon className="w-4 h-4"></ArrowUpIcon>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export interface ChatResponseProps {
  response: ChatResponse;
  onSelect: (citation: Citation) => void;
}

export function ChatResponseResult({response, onSelect}: ChatResponseProps) {
  return (
    <div className="flex flex-col gap-2">
      <textarea
        className="grow textarea h-48 w-full"
        value={response.answer}
      ></textarea>
      <div className="flex flex-row gap-1">
        {response.citations.map((citation, index) => {
          return (
            <button
              key={`citation-${index}`}
              className="btn btn-square btn-xs hover:bg-accent hover:text-black"
              onClick={event => {
                event.preventDefault();
                onSelect(citation);
              }}
            >
              {index + 1}
            </button>
          );
        })}
      </div>
    </div>
  );
}

/**
 * Scrolls to the current position/selection of the document. It does the same as scrollIntoView()
 * but without requiring the focus on the editor, thus it can be called from the search box while
 * typing or in shopping mode when the editor is disabled.
 * @param {Editor}  editor - A TipTap editor instance.
 */
function scrollToSelection(editor: Editor): void {
  const {node, offset} = editor.view.domAtPos(editor.state.selection.anchor);
  let childNodes = node;
  if (editor.view.dom === node) {
    childNodes = node.childNodes.item(offset + 1);
  }

  if (childNodes) {
    (childNodes as any).scrollIntoView?.(false);
  }
}

function isWhitespace(character: string): boolean {
  return ' \t\n\r\v'.indexOf(character) > -1;
}

function selectText(editor: Editor, inputSearch: string) {
  const trimmedInput = inputSearch.trim();
  const text = editor.getText();
  const textLength = text.length;
  const inputLength = trimmedInput.length;
  let j = 0;
  let i = 0;
  let start = -1;
  let end = -1;

  for (i = 0; i < textLength && j < inputLength; i++) {
    const textChar = text.charAt(i).toLowerCase();
    const inputChar = trimmedInput.charAt(j).toLowerCase();

    if (textChar === inputChar) {
      j++;
      if (start === -1) {
        start = i;
      }
    } else if (isWhitespace(textChar)) {
      continue;
    } else if (!isWhitespace(textChar) && isWhitespace(inputChar)) {
      for (let k = j; k < inputLength; k++) {
        const nextChar = trimmedInput.charAt(k).toLowerCase();
        if (textChar === nextChar) {
          j = k + 1;
          if (start === -1) {
            start = k;
          }
          break;
        } else if (!isWhitespace(nextChar)) {
          j = 0;
          start = -1;
          break;
        }
      }
    } else {
      j = 0;
      start = -1;
    }
  }

  if (j >= inputLength) {
    end = i;
  }

  if (start !== -1) {
    editor.commands.setTextSelection({from: start, to: end});
    scrollToSelection(editor);
  }
}
