import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {ArrowRightStartOnRectangleIcon as SignoutIcon} from '@heroicons/react/24/solid';

const LogoutButton = () => {
  const {logout} = useAuth0();

  return (
    <a
      onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}
    >
      <SignoutIcon className="w-4 h-4" />
      Sign out
    </a>
  );
};

export default LogoutButton;
