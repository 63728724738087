import {
  ArrowRightIcon,
  DocumentIcon,
  FolderIcon,
  MusicalNoteIcon,
} from '@heroicons/react/20/solid';
import {NodeBodyProps, NodeDefinition} from '../nodes';
import {Step} from '../../../../bindings/Step';
import {VTTIcon} from '../../icons/vtt';

export class BuildVTTNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Generate VTT';
  public nodeLabel = 'Generate VTT';
  public getNodeIcon(className?: string): JSX.Element {
    return <VTTIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <BuildVTT {...baseProps}></BuildVTT>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'BuildVTT',
      templateId: null,
      data: {chapters: null, chaptersFile: null},
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const BUILD_VTT_NODE_DEF = new BuildVTTNodeDefinition();

export function BuildVTT({uuid}: NodeBodyProps) {
  return (
    <div key={uuid} className="flex flex-col gap-4">
      <div className="flex flex-col">
        <label className="uppercase font-bold text-xs mb-1">Description</label>
        <div>
          Scans the selected collection for audio tracks and a chapters.txt
          file. These are used to generate a full WebVTT with subtitles &
          chapter markers.
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-row gap-4 items-center">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2 bg-base-300 p-2 rounded">
              <MusicalNoteIcon className="w-4" />
              <div>Audio Track(s)</div>
            </div>
            <div className="flex flex-row gap-2 bg-base-300 p-2 rounded">
              <DocumentIcon className="w-4" />
              <div>chapters.txt</div>
            </div>
          </div>
          <ArrowRightIcon className="w-6" />
          <div>
            <div className="flex flex-row gap-2 bg-base-300 p-2 rounded">
              <DocumentIcon className="w-4" />
              <div>WebVTT.vtt</div>
            </div>
          </div>
          <ArrowRightIcon className="w-6" />
          <div>
            <div className="flex flex-row gap-2 bg-base-300 p-2 rounded ">
              <FolderIcon className="w-4" />
              <div>Selected Collection</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
