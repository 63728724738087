import {useEffect, useState} from 'react';
import {NodeBodyProps, NodeDefinition} from '../nodes';
import {CollectionSelector} from '../../nodes/CollectionSelector';
import {DocumentSelector} from '../../nodes/DocumentSelector';
import {DataNodeDefData} from '../types/node';
import {Step} from '../../../../bindings/Step';
import {DocumentIcon} from '@heroicons/react/20/solid';

export class CollectionDocNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Document Source';
  public nodeLabel = 'Document Source';
  public getNodeIcon(className?: string): JSX.Element {
    return <DocumentIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <DocumentInputNode {...baseProps}></DocumentInputNode>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'DataSource',
      templateId: null,
      data: {
        type: 'document',
        data: {collection: '', document: '', segments: null},
      },
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const COLLECTION_DOC_NODE_DEF = new CollectionDocNodeDefinition();

export function DocumentInputNode({data, onUpdateData}: NodeBodyProps) {
  const [selectedCollection, setSelectedCollection] = useState<string>();
  const [selectedDocument, setSelectedDocument] = useState<string>();

  useEffect(() => {
    if (data) {
      const docNode = data as DataNodeDefData;
      setSelectedCollection(docNode.collection);
      setSelectedDocument(docNode.document);
    }
  }, [data]);

  return (
    <div className="flex flex-col gap-2">
      <CollectionSelector
        onSelect={uuid => {
          setSelectedCollection(uuid);
          setSelectedDocument(undefined);
        }}
        selectedCollection={selectedCollection}
      ></CollectionSelector>
      <DocumentSelector
        onSelect={(collection, document) => {
          if (onUpdateData) {
            onUpdateData({
              collection: collection,
              document: document,
            } as DataNodeDefData);
          }
        }}
        selectedCollection={selectedCollection}
        selectedDocument={selectedDocument}
      ></DocumentSelector>
    </div>
  );
}
