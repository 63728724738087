import {BoltIcon, ChevronDownIcon} from '@heroicons/react/20/solid';

export interface ButtonAction {
  onClick: () => void;
  icon?: React.ReactNode;
  title?: string;
}

export interface ButtonMenuProps {
  actions: ButtonAction[];
  isLoading: boolean;
  displayName?: string;
  disabled: boolean;
  disabledMessage?: string;
}

export function ButtonMenu({
  actions,
  displayName,
  isLoading,
  disabled,
  disabledMessage,
}: ButtonMenuProps) {
  const style = disabled
    ? 'cursor-default pointer-events-none text-opacity-25'
    : '';
  return (
    <div className="dropdown dropdown-bottom dropdown-end">
      <div className="tooltip" data-tip={disabled ? disabledMessage : null}>
        <div
          tabIndex={0}
          role="button"
          className={`btn btn-outline btn-sm bg-inherit ${style}`}
        >
          {isLoading ? (
            <span className="loading loading-spinner"></span>
          ) : (
            <BoltIcon className="w-4" />
          )}
          {displayName ? displayName : null}
          <ChevronDownIcon className="w-4" />
        </div>
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content z-[1] menu p-2 shadow bg-neutral rounded-box w-64 text-sm text-neutral-content"
      >
        {actions.map((action, _idx) => {
          return (
            <li key={`MenuAction_${_idx}`}>
              <a
                onClick={() => {
                  (document.activeElement as HTMLElement).blur();
                  action.onClick();
                }}
              >
                {action.icon ? action.icon : null}
                {action.title}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
