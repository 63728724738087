import {useContext, useEffect, useState} from 'react';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {
  DataConnectionType,
  DataNodeDefData,
  NodeDataTypes,
} from '../../types/node';
import {CloudIcon, UserCircleIcon} from '@heroicons/react/20/solid';
import {
  DEFAULT_GSHEET_PROPS,
  GoogleSheetConfig,
} from './connectionConfig/googleSheetConfig';
import {
  DEFAULT_HUBSPOT_CONFIG,
  HubspotConfig,
} from './connectionConfig/hubspotConfig';
import {SiGooglesheets, SiHubspot} from '@icons-pack/react-simple-icons';
import {UserContext} from '../../../../context';
import {UserConnection} from '../../../../api/Client';
import {ConnectionData} from '../../../../../bindings/ConnectionData';
import {Step} from '../../../../../bindings/Step';

export class ConnectionNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Connection Source';
  public nodeLabel = 'Pull Content From Connection';
  public getNodeIcon(className?: string): JSX.Element {
    return <CloudIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <ConnectionDataNode {...baseProps}></ConnectionDataNode>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'DataSource',
      templateId: null,
      data: {
        type: 'connection',
        data: {
          connectionData: {
            connectionType: 'GSheets',
            connectionId: '',
            numRows: null,
            sheetId: '',
            spreadsheetId: '',
          },
        },
      },
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const CONNECTION_NODE_DEF = new ConnectionNodeDefinition();

export interface ConnectionConfig {
  data: NodeDataTypes;
  updateNodeData: (newData: Partial<ConnectionData>) => void;
}

export function ConnectionDataNode({
  data,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const {client} = useContext(UserContext);

  const [userConns, setUserConns] = useState<UserConnection[]>([]);
  const [connectionId, setConnectionId] = useState<string | undefined>();
  const [connectionType, setConnectionType] =
    useState<DataConnectionType | null>(null);
  const nodeData = data as DataNodeDefData;

  useEffect(() => {
    if (client) {
      client.listConnections().then(response => {
        const connections = response.result;
        if (connections) {
          setUserConns(
            connections.filter(connection => {
              return !connection.apiId.startsWith('graph.microsoft.com');
            })
          );
        }
      });
    }
  }, [client]);

  useEffect(() => {
    if (nodeData.connectionData) {
      const data = nodeData.connectionData;
      setConnectionType((data.connectionType as DataConnectionType) ?? null);
      setConnectionId(data.connectionId);
    }
  }, [nodeData]);

  const updateNodeData = (newData: Partial<ConnectionData>) => {
    const update: NodeDataTypes = {
      connectionData: {
        ...nodeData.connectionData,
        ...newData,
      },
    };

    if (newData.connectionId) {
      const connection = userConns.find(connection => {
        return connection.id.toString() === newData.connectionId;
      });
      let connectionType;
      if (connection?.apiId === 'hubspot.com') {
        connectionType = DataConnectionType.Hubspot;
      } else if (connection?.apiId === 'sheets.google.com') {
        connectionType = DataConnectionType.GSheets;
      } else if (connection?.apiId === 'graph.microsoft.com+tasks') {
        connectionType = DataConnectionType.MicrosoftTasks;
      } else if (connection?.apiId === 'graph.microsoft.com+outlook') {
        connectionType = DataConnectionType.MicrosoftOutlook;
      } else {
        // Default ATM
        connectionType = DataConnectionType.GSheets;
      }

      if (connectionType === DataConnectionType.GSheets) {
        for (const key in DEFAULT_GSHEET_PROPS) {
          if (!update.connectionData[key]) {
            update.connectionData[key] = DEFAULT_GSHEET_PROPS[key];
          }
        }
      } else if (connectionType === DataConnectionType.Hubspot) {
        for (const key in DEFAULT_HUBSPOT_CONFIG) {
          if (!update.connectionData[key]) {
            update.connectionData[key] = DEFAULT_HUBSPOT_CONFIG[key];
          }
        }
      }

      setConnectionType(connectionType);
      update.connectionData.connectionType = connectionType;
    }

    onUpdateData(update);
  };

  let icon = <UserCircleIcon className="w-4" />;
  if (connectionType === DataConnectionType.GSheets) {
    icon = <SiGooglesheets className="join-item w-8 h-8 text-[#34A853]" />;
  } else if (connectionType === DataConnectionType.Hubspot) {
    icon = <SiHubspot className="join-item w-8 h-8 text-[#FF7A59]" />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="join items-center bg-base-100">
        <div className="join-item pl-4">{icon}</div>
        <select
          className="input join-item w-full placeholder:text-gray-700"
          onChange={event => {
            const connectionId = event.target.value;
            if (connectionId) {
              setConnectionId(connectionId);
              updateNodeData({connectionId});
            } else {
              setConnectionId(undefined);
              setConnectionType(null);
            }
          }}
          value={connectionId}
          defaultValue={connectionId || ''}
        >
          <option>Select an account</option>
          {userConns.map((conn, idx) => (
            <option key={`conn-${idx}`} value={conn.id}>
              {`${conn.apiId} (${conn.account})`}
            </option>
          ))}
        </select>
      </div>
      {connectionType === DataConnectionType.GSheets ? (
        <GoogleSheetConfig data={data} updateNodeData={updateNodeData} />
      ) : null}
      {connectionType === DataConnectionType.Hubspot ? (
        <HubspotConfig data={data} updateNodeData={updateNodeData} />
      ) : null}
    </div>
  );
}
