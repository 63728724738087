export interface GhostRowProps {
  columns: number;
}

export function GhostRow({columns}: GhostRowProps) {
  return (
    <tr>
      {Array(columns)
        .fill(0)
        .map((val, index) => (
          <td key={index}>
            <div className="skeleton w-32 h-4"></div>
          </td>
        ))}
    </tr>
  );
}
