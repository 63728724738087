import {SummaryResult, SightglassClient, GoalItem} from '../../api/Client';
import {DateTime} from 'luxon';
import {TranscriptTextMessage} from '../../api/messages';
import {DataNodeDefData} from '../../components/talos/types/node';
import {ApiResponse} from '../../../bindings/api/ApiResponse';

export interface ActionItem {
  action: string;
  people: string;
  when: string;
  when_iso: string;
}

export async function summarize(
  client: SightglassClient,
  transcript: TranscriptTextMessage[]
): Promise<ApiResponse<SummaryResult> | null> {
  if (transcript.length === 0) {
    console.info('Skipping summary, no transcript');
    return Promise.resolve(null);
  }

  const transcriptBlob = transcript.map(x => `Speaker: ${x.text}`).join('\n');
  return client.summarizeText(transcriptBlob);
}

// Analyze a transcript + list of tasks/goals & check-off any completed tasks
export function updateChecklist(
  client: SightglassClient,
  checklist: GoalItem[],
  transcript: TranscriptTextMessage[]
): Promise<ApiResponse<DataNodeDefData> | null> {
  const incompleteTasks = checklist.flatMap(x => (x.isComplete ? [] : [x]));
  if (incompleteTasks.length === 0) {
    console.info('Skipping checklist analysis: no incomplete tasks');
    return Promise.resolve(null);
  } else if (transcript.length === 0) {
    console.info('Skipping checklist analysis: no transcript to check');
    return Promise.resolve(null);
  }

  const transcriptBlob = transcript.map(x => `Speaker: ${x.text}`).join('\n');
  const checklistBlob = JSON.stringify(
    incompleteTasks.map(x => ({
      uuid: x.uuid,
      title: x.title,
      description: x.description,
    }))
  );

  return client.extractJson(
    'Analyze the transcript and return the "uuid" of goals that are completed within the transcript. ' +
      `Choose only from the following list of goals with a short explanation of how it was completed:\n${checklistBlob}\n` +
      'Return an empty list if nothing has been completed.',
    transcriptBlob,
    {
      $schema: 'https://json-schema.org/draft/2020-12/schema',
      type: 'object',
      properties: {
        completed_goals: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              uuid: {type: 'string'},
              isCompleted: {type: 'boolean'},
              explanation: {type: 'string'},
            },
          },
        },
      },
      required: ['completed_goals'],
    }
  );
}

// Analyze a transcript to identify any specified action items and their associated details
export function buildActionItemList(
  client: SightglassClient,
  transcript: TranscriptTextMessage[]
): Promise<ApiResponse<ActionItem[]> | null> {
  const transcriptBlob = transcript.map(x => `Speaker: ${x.text}`).join('\n');

  const today = DateTime.now().toISO();
  return client
    .extractJson(
      'Analyze the transcript and return a list of unique action items. Each action item must provide as many details as possible including ' +
        'the date the action item is scheduled for and the names of anyone mentioned in relation to the action. Action items are only tasks ' +
        'that have been clearly stated as an action needed to be done with a specific start or end date. Musings or pondering are not considered action items. ' +
        'Action items must be formatted in a short simple complete sentences. Return an empty list if no action items are required.' +
        `Given that today is ${today} provide the "when_iso" in ISO 8601 format when possible`,
      transcriptBlob,
      {
        $schema: 'https://json-schema.org/draft/2020-12/schema',
        type: 'object',
        properties: {
          action_items: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                action: {
                  type: 'string',
                },
                people: {
                  type: 'string',
                },
                when: {
                  type: 'string',
                },
                when_iso: {
                  type: 'string',
                },
              },
            },
          },
        },
        required: ['action_items'],
      }
    )
    .then(result => {
      if (result) {
        const newResult: ApiResponse<ActionItem[]> = {
          status: result.status,
          time: result.time,
          result: null,
        };
        newResult.result = result.result?.content.action_items as ActionItem[];
        return newResult;
      }
      return null;
    });
}
