import {useEffect, useState} from 'react';
import {EditorInfo} from './EditorInfo';
import {ChatBubbleBottomCenterTextIcon} from '@heroicons/react/20/solid';

export type EditorHeaderProps = {
  name: string;
  characters: number;
  words: number;
  sidebarOnChange?: (visible: boolean) => void;
};

export const EditorHeader = ({
  characters,
  words,
  name,
  sidebarOnChange,
}: EditorHeaderProps) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);

  useEffect(() => {
    if (sidebarOnChange) {
      sidebarOnChange(sidebarOpen);
    }
  }, [sidebarOpen]);
  return (
    <div className="flex flex-row items-center justify-between flex-none py-2 pl-6 pr-3 text-black bg-white border-b border-neutral-200 dark:bg-black dark:text-white dark:border-neutral-800 max-w-[960px] ">
      <div className="flex items-center border-r-2 border-base-200 pr-3">
        <ChatBubbleBottomCenterTextIcon
          className="w-6 h-6 hover:cursor-pointer"
          onClick={() => {
            setSidebarOpen(open => {
              return !open;
            });
          }}
        ></ChatBubbleBottomCenterTextIcon>
      </div>
      <div className="flex flex-row gap-x-1.5 items-center">
        <div className="flex items-center gap-x-1.5 text-ellipsis break-words break-all">
          {name}
        </div>
      </div>
      <EditorInfo characters={characters} words={words} />
    </div>
  );
};
