import Ajv, {AnySchema} from 'ajv/dist/2020';
import {NodeBodyProps, NodeDefinition} from '../nodes';
import React, {useContext, useEffect, useState} from 'react';
import {JSONSchema7, JSONSchema7Definition} from 'json-schema';
import {EditableText, EditableTextarea} from '../editable';
import {StepData} from '../../../../bindings/StepData';
import {ExtractNode} from '../../../../bindings/ExtractNode';
import {
  BoltIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from '@heroicons/react/20/solid';
import {Step} from '../../../../bindings/Step';
import {UserContext, WorkflowContext} from '../../../context';
import {StepInputSource} from '../../../../bindings/StepInputSource';
import {getNode} from '../workflows/utils';

export class ExtractNodeDefinition implements NodeDefinition {
  public defaultExtractData: ExtractNode = {
    query: '',
    schema: {},
    imageExtract: null,
    template: null,
  };
  public defaultData: StepData = {
    nodeType: 'Extract',
    data: this.defaultExtractData,
  };
  public nodeSelectionLabel = 'JSON Extract';
  public nodeLabel = 'Extract Structured Data From Text';
  public getNodeIcon(className?: string): JSX.Element {
    return <BoltIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <ExtractNodeView {...baseProps}></ExtractNodeView>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'Extract',
      templateId: null,
      data: this.defaultExtractData,
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const EXTRACT_NODE_DEFINITION = new ExtractNodeDefinition();

interface FieldDef {
  label: string;
  type: string;
}

// Not the smartest way of doing this and makes some assumptions about the schema
// being passed in.
function extractProps(
  schema: boolean | JSONSchema7 | JSONSchema7Definition[]
): Array<FieldDef> {
  if (schema instanceof Object) {
    const keys = Object.keys(schema);
    const values = Object.values(schema);

    return keys.map((key, idx) => {
      const value = values[idx] as JSONSchema7;
      return {
        label: key,
        type: value.type?.toString() ?? 'unknown',
      };
    });
  }

  return [];
}

export default function ExtractNodeView({
  data,
  uuid,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const {client} = useContext(UserContext);
  const {workflowDef} = useContext(WorkflowContext);
  const [schemaError, setSchemaError] = useState<string | null>(null);
  const [fields, setFields] = useState<Array<FieldDef>>([]);
  const [imageExtract, setImageExtract] = useState<boolean>(false);
  const [schemaGen, setSchemaGen] = useState<boolean>(false);
  const [sourceExtract, setSourceExtract] = useState<boolean>(false);

  const actionData = data as ExtractNode;
  const schema = actionData.schema as JSONSchema7;

  useEffect(() => {
    const ajv = new Ajv();
    // todo: figure out a way to validate the schema itself?
    // or maybe a UI way to add/remove fields
    if (actionData.schema) {
      try {
        ajv.compile(actionData.schema as AnySchema);
        setSchemaError(null);
      } catch (err) {
        const error = err as Error;
        setSchemaError(error.toString());
      }

      // parse schema
      if (!schemaError && schema.type) {
        if (schema.type === 'object' && schema.properties) {
          setFields(extractProps(schema.properties));
        } else if (schema.type === 'array' && schema.items) {
          setFields(extractProps(schema.items));
        }
      }
    }

    setImageExtract(actionData.imageExtract ?? false);
  }, [actionData, schema, schemaError]);

  useEffect(() => {
    if (workflowDef?.flow.steps) {
      const node = getNode(uuid, workflowDef?.flow.steps);
      if (node && node.inputSource) {
        setSourceExtract(node.inputSource.useSource ?? false);
      }
    }
  }, [workflowDef, uuid]);

  const updateNodeData = (
    data: Partial<ExtractNode>,
    inputSource?: StepInputSource
  ) => {
    onUpdateData(
      {
        query: data.query ?? actionData.query,
        schema: data.schema ?? actionData.schema,
        imageExtract: data.imageExtract ?? actionData.imageExtract,
      } as ExtractNode,
      inputSource
    );
  };

  const updateQuery = (newQuery: string) => {
    onUpdateData({...actionData, query: newQuery} as ExtractNode);
  };

  const updateSchema = (newSchema: string) => {
    try {
      onUpdateData({
        ...actionData,
        schema: JSON.parse(newSchema),
      } as ExtractNode);
    } catch (err) {
      // todo: report JSON errors
      console.error(err);
    }
  };

  const generateSchema = () => {
    setSchemaGen(true);
    client
      ?.extractJson(
        `Generate a valid json schema that can be used to represent the data extracted from the following query: ${actionData.query}. Do not include the $schema attribute, Do not include date time formats`,
        '',
        {}
      )
      .then(response => {
        if (response && response.result && response.result.content) {
          onUpdateData({
            ...actionData,
            schema: response.result.content,
          });
        }
      })
      .finally(() => {
        setSchemaGen(false);
      });
  };

  const actions = [
    <button
      className="btn btn-sm"
      key={`${uuid}-extract-generate-schema`}
      onClick={() => {
        generateSchema();
      }}
    >
      {schemaGen ? (
        <span className="loading loading-spinner" />
      ) : (
        <ChatBubbleOvalLeftEllipsisIcon className="w-4 h-4" />
      )}
      Generate Schema
    </button>,
  ];

  return (
    <div className="flex flex-col gap-4">
      <EditableText
        label="Query"
        data={actionData.query}
        onChange={newValue => updateQuery(newValue)}
        className="bg-base-100 p-2 rounded text-sm w-full"
      />
      <EditableTextarea
        label="Response Schema"
        isCode
        actions={actions}
        data={JSON.stringify(actionData.schema, null, 2)}
        onChange={newValue => updateSchema(newValue)}
      />
      <div className="flex flex-col">
        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="label-text">Image Extraction</span>
            <div
              className="tooltip"
              data-tip="Extract structured data from an image"
            >
              <input
                type="checkbox"
                className="toggle toggle-primary"
                checked={imageExtract}
                onChange={event => {
                  updateNodeData({
                    imageExtract: event.target.checked,
                  });
                }}
              />
            </div>
          </label>
        </div>
        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="label-text">Extract From Source</span>
            <div
              className="tooltip"
              data-tip="Extract from source data instead of previous nodes data"
            >
              <input
                type="checkbox"
                className="toggle toggle-primary"
                checked={sourceExtract}
                onChange={event => {
                  updateNodeData(
                    {},
                    {
                      useSource: event.target.checked,
                      inputUuid: null,
                    }
                  );
                }}
              />
            </div>
          </label>
        </div>
      </div>
      <div>
        {schemaError ? (
          <div className="text-error p-2">{schemaError}</div>
        ) : null}
        <table className="table table-auto w-full table-zebra table-sm">
          <thead className="text-secondary">
            <tr>
              <th>Field Name</th>
              <th>Field Type</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, idx) => (
              <tr key={idx}>
                <td>{field.label}</td>
                <td>{field.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
