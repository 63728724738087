import {useContext, useEffect, useReducer, useRef, useState} from 'react';
import {
  ArrowDownTrayIcon,
  CloudArrowUpIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';

import {DateTime} from 'luxon';
import {UserContext, WatcherContext} from '../../context';
import {UploadDocument} from '../../components/modals/UploadDocument';
import {GeneralRecordingsViewParams} from '../RecordingsView';
import {GhostRow} from '../../components/GhostRow';
import {
  CollectionDropUpload,
  handleCollectionUpload,
} from '../../components/utils/CollectionDropUpload';
import {EditableText} from '../../components/talos/editable';
import {ButtonAction, ButtonMenu} from '../../components/ButtonMenu';
import {DataNodeType} from '../../components/talos/types/node';
import {DocumentResponse} from '../../../bindings/api/DocumentResponse';
import {UserWorkflowInstanceSummary} from '../../../bindings/api/UserWorkflowInstanceSummary';
import {useNavigate} from 'react-router-dom';
import {EyeIcon} from '@heroicons/react/24/solid';
import {UserWorkflowSummary} from '../../../bindings/api/UserWorkflowSummary';
import {WorkflowRunTable} from '../Workflows/WorkflowRunList';
import CollectionSearch from './CollectionSearch';
import {DocumentSearchResponse} from '../../api/client/collection';

interface CollectionViewProps extends GeneralRecordingsViewParams {
  onUpdate: () => void;
}

interface DocumentAction {
  uuid: string;
  action: 'Delete' | 'Download';
}

interface UploadDocumentStatus {
  file: File;
  progress: number;
}

interface CollectionViewState {
  isLoading: boolean;
  docActions: DocumentAction[];
  uploadingDocs: UploadDocumentStatus[];
}

const initialState: CollectionViewState = {
  isLoading: false,
  docActions: [],
  uploadingDocs: [],
};

enum CVActionType {
  LoadingStarted,
  LoadingFinished,
  DeleteDocStarted,
  DeleteDocFinished,
  DownloadDocStarted,
  DownloadDocFinished,
  UploadDocStarted,
  UploadDocProgressUpdate,
  UploadDocFinished,
}

interface CVAction {
  type: CVActionType;
  payload?: string | File[] | {filename: string; progress: number};
}

function stateReducer(
  state: CollectionViewState,
  action: CVAction
): CollectionViewState {
  switch (action.type) {
    case CVActionType.LoadingStarted:
      return {
        ...state,
        isLoading: true,
      };
    case CVActionType.LoadingFinished:
      return {
        ...state,
        isLoading: false,
      };
    case CVActionType.DeleteDocStarted:
      return {
        ...state,
        docActions: [
          ...state.docActions,
          {uuid: action.payload as string, action: 'Delete'},
        ],
      };
    case CVActionType.DownloadDocStarted:
      return {
        ...state,
        docActions: [
          ...state.docActions,
          {uuid: action.payload as string, action: 'Download'},
        ],
      };
    case CVActionType.DownloadDocFinished | CVActionType.DeleteDocFinished:
      return {
        ...state,
        docActions: state.docActions.flatMap(cur =>
          cur.uuid === action.payload ? [] : [cur]
        ),
      };
    case CVActionType.UploadDocStarted: {
      const files = action.payload as File[];
      return {
        ...state,
        uploadingDocs: [...state.uploadingDocs].concat(
          files.map(f => ({file: f, progress: 0}))
        ),
      };
    }
    case CVActionType.UploadDocProgressUpdate: {
      const progressUpdate = action.payload as {
        filename: string;
        progress: number;
      };
      return {
        ...state,
        uploadingDocs: state.uploadingDocs.map(doc => {
          const update = {...doc};
          if (doc.file.name === progressUpdate.filename) {
            update.progress = progressUpdate.progress;
          }
          return update;
        }),
      };
    }
    case CVActionType.UploadDocFinished: {
      const finished = action.payload as File[];
      return {
        ...state,
        uploadingDocs: state.uploadingDocs.flatMap(doc => {
          const file = finished.find(f => f.name === doc.file.name);
          return file ? [] : [doc];
        }),
      };
    }
    default:
      return state;
  }
}

export function CollectionView({collection, onUpdate}: CollectionViewProps) {
  const {client} = useContext(UserContext);
  const watcher = useContext(WatcherContext);
  const nav = useNavigate();

  const downloadDropdownRef = useRef<HTMLDetailsElement>(null);
  const [documentList, setDocumentList] = useState<DocumentResponse[]>([]);
  const [displayDocuments, setDisplayDocuments] = useState<DocumentResponse[]>(
    []
  );
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);
  const [collectionWorkflows, setCollectionWorkflows] = useState<
    UserWorkflowSummary[]
  >([]);
  const [recentWorkflows, setRecentWorkflows] = useState<
    UserWorkflowInstanceSummary[]
  >([]);
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const [filteredDocs, setFilteredDocs] = useState<
    DocumentSearchResponse[] | undefined
  >(undefined);

  const updateDocuments = async () => {
    dispatch({type: CVActionType.LoadingStarted});
    await client?.collection
      .listDocuments(collection.uuid)
      .then(resp => {
        if (resp.result) {
          setDocumentList(resp.result);
        }
      })
      .finally(() => dispatch({type: CVActionType.LoadingFinished}));
  };

  const updateWorkflows = async () => {
    dispatch({type: CVActionType.LoadingStarted});
    // Find workflows that can run with collections.
    const findWorkflows = client?.workflow.list().then(resp => {
      if (resp.result) {
        setCollectionWorkflows(resp.result);
      }
    });
    // Find recently run workflows
    const listRecents = client?.workflow
      .listRuns({collectionUuid: collection.uuid, limit: 5, status: []})
      .then(resp => {
        if (resp.result) {
          setRecentWorkflows(resp.result);
        }
      });

    Promise.all([findWorkflows, listRecents]).finally(() =>
      dispatch({type: CVActionType.LoadingFinished})
    );
  };

  useEffect(() => {
    console.error('updating');
    if (!filteredDocs) {
      setDisplayDocuments(documentList);
    } else {
      console.error('got filter', filteredDocs);

      const scoreMap: {[key: string]: number} = {};
      for (const filtered of filteredDocs) {
        scoreMap[filtered.uuid] = filtered.score;
      }

      let filteredList = documentList.filter(document => {
        return scoreMap[document.uuid] !== undefined;
      });

      filteredList = filteredList.sort((a, b) => {
        const aScore = scoreMap[a.uuid];
        const bScore = scoreMap[b.uuid];
        return bScore - aScore;
      });

      setDisplayDocuments(filteredList);
    }
  }, [documentList, filteredDocs]);

  useEffect(() => {
    updateDocuments();
    updateWorkflows();
  }, []);

  const downloadDocument = async (
    uuid: string,
    name: string,
    returnProcessed: boolean
  ) => {
    dispatch({type: CVActionType.DownloadDocStarted, payload: uuid});
    if (downloadDropdownRef.current) {
      downloadDropdownRef.current.removeAttribute('open');
    }

    if (collection) {
      const docDetails = await client?.collection.downloadDocumentBlob(
        collection.uuid,
        uuid,
        returnProcessed
      );

      if (docDetails) {
        const url = URL.createObjectURL(docDetails);
        const a = document.createElement('a');
        document.body.appendChild(a);

        a.href = url;
        a.download = returnProcessed ? name + '.txt' : name;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    }

    dispatch({type: CVActionType.DownloadDocFinished, payload: uuid});
  };

  const deleteDocument = async (uuid: string) => {
    dispatch({type: CVActionType.DeleteDocStarted, payload: uuid});
    if (collection) {
      return client?.collection
        .deleteDocument(collection.uuid, uuid)
        .then(() => updateDocuments())
        .finally(() =>
          dispatch({type: CVActionType.DeleteDocFinished, payload: uuid})
        );
    }
  };

  const upload = async (fileList: FileList) => {
    if (client) {
      const files: File[] = [];
      for (let i = 0; i < fileList.length; i++) {
        files.push(fileList[i]);
      }

      dispatch({type: CVActionType.UploadDocStarted, payload: files});
      await handleCollectionUpload(client, collection.uuid, files).finally(
        () => {
          updateDocuments();
          dispatch({type: CVActionType.UploadDocFinished, payload: files});
        }
      );
    }
  };

  const updateDocumentName = (docUUID: string, updatedName: string) => {
    return client?.collection
      .updateDocument(collection.uuid, docUUID, {
        displayName: updatedName,
      })
      .then(() => updateDocuments());
  };

  const openDocumentView = (docUUID: string) => {
    nav(`/collections/${collection.uuid}/documents/${docUUID}`);
  };

  const actions: ButtonAction[] = collectionWorkflows.map(workflow => {
    return {
      onClick: () => {
        if (collection && client) {
          return client.workflow
            .trigger(workflow.uuid, {
              type: DataNodeType.Collection,
              data: {
                collection: collection.uuid,
              },
            })
            .then(response => {
              if (response.result && workflow.name) {
                const instance = response.result;
                watcher.watchWorkflow(workflow.name, instance);
              }
            });
        }
      },
      title: workflow.name ?? 'Untitled',
    };
  });

  return (
    <CollectionDropUpload
      collection={collection.uuid}
      onUploadUpdate={(filename, progress) =>
        dispatch({
          type: CVActionType.UploadDocProgressUpdate,
          payload: {filename, progress},
        })
      }
      onUploadFinished={files => {
        updateDocuments();
        dispatch({type: CVActionType.UploadDocFinished, payload: files});
      }}
      onUploadStart={files =>
        dispatch({type: CVActionType.UploadDocStarted, payload: files})
      }
    >
      <div className="flex flex-col gap-8 w-full mb-32 max-w-[960px] mx-auto">
        <div>
          <div className="flex flex-row justify-between items-end">
            <h2 className="text-xl font-bold">
              <EditableText
                data={collection.name}
                onChange={updatedName =>
                  client?.collection
                    .update(collection.uuid, {name: updatedName})
                    .then(() => onUpdate())
                }
              />
            </h2>
            <div className="flex flex-row gap-2">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => setIsUploadDialogOpen(true)}
              >
                <CloudArrowUpIcon className="w-4 h-4"></CloudArrowUpIcon>
                Upload
              </button>
              <ButtonMenu
                disabled={!state.isLoading && collectionWorkflows.length === 0}
                disabledMessage="No Collection Workflows Enabled"
                displayName="Run a Workflow"
                actions={actions}
                isLoading={state.isLoading}
              ></ButtonMenu>
            </div>
          </div>
          <div className="text-sm">
            🪄 Drop files or a folder on the page to add files to the
            collection.
          </div>
        </div>
        <div>
          <h3 className="pb-2 font-bold text-xs text-gray-500 pl-4">
            Recently Run Workflows
          </h3>
          <WorkflowRunTable
            workflowRuns={recentWorkflows}
            showWorkflowName={true}
          />
        </div>
        <div>
          {/* Document list */}
          <div className="flex flex-row gap-4 items-center">
            <h3 className="pb-2 font-bold text-xs text-gray-500 pl-4">
              Documents
            </h3>
            <CollectionSearch
              collection={collection.uuid}
              onChange={filteredDocs => {
                setFilteredDocs(filteredDocs);
              }}
            ></CollectionSearch>
          </div>
          <table className="table h-fit bg-base-300 w-full">
            <thead>
              <tr>
                <th>Name</th>
                <th>Last Updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {state.uploadingDocs.map(file => (
                <UploadStatusRow key={file.file.name} fileStatus={file} />
              ))}
              {state.isLoading ? (
                <>
                  <GhostRow columns={5}></GhostRow>
                  <GhostRow columns={5}></GhostRow>
                </>
              ) : (
                displayDocuments.map(doc => (
                  <DocumentRow
                    key={doc.uuid}
                    doc={doc}
                    isDeleting={
                      state.docActions.find(
                        f => f.uuid === doc.uuid && f.action === 'Delete'
                      ) !== undefined
                    }
                    isDownloading={
                      state.docActions.find(
                        f => f.uuid === doc.uuid && f.action === 'Download'
                      ) !== undefined
                    }
                    onDelete={() => deleteDocument(doc.uuid)}
                    onDownload={processed =>
                      downloadDocument(doc.uuid, doc.displayName, processed)
                    }
                    onUpdateName={update =>
                      updateDocumentName(doc.uuid, update)
                    }
                    onOpen={() => openDocumentView(doc.uuid)}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
        {isUploadDialogOpen ? (
          <UploadDocument
            header="Upload"
            open={isUploadDialogOpen}
            upload={files => upload(files)}
            onClose={() => setIsUploadDialogOpen(false)}
          />
        ) : null}
      </div>
    </CollectionDropUpload>
  );
}

interface UploadStatusRowProps {
  fileStatus: UploadDocumentStatus;
}

function UploadStatusRow({fileStatus}: UploadStatusRowProps) {
  return (
    <tr className="text-neutral-content">
      <td>{fileStatus.file.name}</td>
      <td>
        <progress
          className="progress w-56"
          value={fileStatus.progress}
          max="1"
        />
      </td>
      <td>&nbsp;</td>
    </tr>
  );
}

interface DocumentRowProps {
  doc: DocumentResponse;
  isDownloading: boolean;
  isDeleting: boolean;
  onDelete: () => void;
  onDownload: (returnProcessed: boolean) => void;
  onUpdateName: (updatedName: string) => void;
  onOpen: () => void;
}

function DocumentRow({
  doc,
  isDownloading,
  isDeleting,
  onDelete,
  onUpdateName,
  onDownload,
  onOpen,
}: DocumentRowProps) {
  return (
    <tr className={`${isDeleting ? 'text-neutral' : 'text-neutral-content'}`}>
      <td>
        <EditableText
          data={doc.displayName}
          onChange={onUpdateName}
          disabled={isDownloading || isDeleting}
        />
      </td>
      <td>
        <div className="text-sm opacity-50">
          {DateTime.fromISO(doc.updatedAt).toLocaleString(
            DateTime.DATETIME_MED_WITH_SECONDS
          )}
        </div>
      </td>
      <td className="flex flex-row gap-2 place-content-end items-center">
        {isDownloading ? (
          <span className="loading loading-spinner loading-md"></span>
        ) : (
          <div className="dropdown tooltip" data-tip="Download">
            <div
              tabIndex={0}
              role="button"
              className={`btn btn-neutral btn-sm ${
                isDeleting ? 'btn-disabled' : ''
              }`}
            >
              <ArrowDownTrayIcon className="w-4 h-4" />
            </div>
            <ul
              tabIndex={0}
              className="p-2 shadow menu dropdown-content z-[1] bg-neutral rounded-box w-64 border-neutral-500 border"
            >
              <li>
                <a onClick={() => onDownload(false)}>Download file</a>
              </li>
              {doc.hasParsedContent ? (
                <li>
                  <a onClick={() => onDownload(true)}>
                    Download transcript/text
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
        )}
        {doc.status === 'Completed' ? (
          <div className="tooltip" data-tip="View Extracted Text">
            <button
              className="btn btn-neutral btn-sm"
              disabled={isDownloading || isDeleting}
              onClick={() => onOpen()}
            >
              {isDeleting ? (
                <span className="loading loading-spinner w-4 h-4"></span>
              ) : (
                <EyeIcon className="w-4" />
              )}
            </button>
          </div>
        ) : null}
        <div className="tooltip" data-tip="Delete">
          <button
            className="btn btn-error btn-outline btn-sm btn-square items-center"
            disabled={isDownloading || isDeleting}
            onClick={() => onDelete()}
          >
            {isDeleting ? (
              <span className="loading loading-spinner w-4 h-4"></span>
            ) : (
              <TrashIcon className="w-4" />
            )}
          </button>
        </div>
      </td>
    </tr>
  );
}
