import {ArrowsPointingInIcon} from '@heroicons/react/20/solid';
import {NodeBodyProps, NodeDefinition} from '../nodes';
import {Step} from '../../../../bindings/Step';

export class AggregateNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Combine Output';
  public nodeLabel = 'Combine Output';
  public getNodeIcon(className?: string): JSX.Element {
    return <ArrowsPointingInIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <AggregateNode {...baseProps}></AggregateNode>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'AggregateOutput',
      templateId: null,
      data: {
        aggregateType: {
          type: 'json',
          data: {
            objectMapping: null,
          },
        },
      },
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const AGGREGATE_NODE_DEF = new AggregateNodeDefinition();

export function AggregateNode({uuid}: NodeBodyProps) {
  return (
    <div key={uuid} className="flex flex-col gap-4">
      <div className="flex flex-col">
        <label className="uppercase font-bold text-xs mb-1">Description</label>
        <div>
          Aggregates the output of each previous node into a single output
        </div>
      </div>
    </div>
  );
}
