import {useRouteError} from 'react-router-dom';

interface ErrorMsg {
  statusText?: string;
  message?: string;
}

export default function ErrorPage() {
  const error = useRouteError() as ErrorMsg;
  console.error(error);

  return (
    <div className="w-screen h-screen flex flex-col place-content-center">
      <div className="w-fit mx-auto text-center flex flex-col gap-2">
        <h1 className="text-xl font-bold text-red-500">Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </div>
  );
}
