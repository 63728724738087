import {useContext, useEffect} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {UserContext} from '../context';

interface CheckoutParams {
  status: string;
}

export function Checkout() {
  const {client, setProfile} = useContext(UserContext);

  const {status} = useLoaderData() as CheckoutParams;
  const navigate = useNavigate();
  useEffect(() => {
    if (status === 'canceled') {
      navigate('/');
    } else if (status === 'success') {
      // Check for a successful payment
      const interval = setInterval(() => {
        console.debug('checking for subscription');
        return client?.auth
          .getUserProfile()
          .then(userData => {
            setProfile(userData);
            if (userData.subscriptionId) {
              navigate('/');
            }
          })
          .catch(err => console.error(err));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [client, navigate, setProfile, status]);

  return (
    <div className="w-full flex flex-col items-center mt-32 gap-8">
      <iframe
        src="https://giphy.com/embed/2uwZ4xi75JhxZYeyQB"
        width="240"
        height="180"
        className="rounded-lg"
        allowFullScreen
      ></iframe>
      <div className="text-2xl">Processing payment...</div>
    </div>
  );
}
