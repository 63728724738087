import {RefObject, useState} from 'react';
import {ModalType} from '.';

export interface TextInputProps {
  title?: string;
  label: string;
  setValue: (value: string) => void;
  dialogRef: RefObject<ModalType>;
  placeholder: string;
}

export function TextInput({
  label,
  title,
  setValue,
  dialogRef,
  placeholder,
}: TextInputProps) {
  const [text, setText] = useState<string>('');
  const handleSubmit = () => {
    if (text && text.trim().length !== 0) {
      setValue(text);
      return true;
    }
    return false;
  };

  return (
    <dialog
      ref={dialogRef as RefObject<HTMLDialogElement>}
      id="inputSelectionDialog"
      className="modal"
    >
      <form
        method="dialog"
        className="modal-box flex flex-col gap-4"
        onSubmit={() => handleSubmit()}
      >
        <h3 className="font-bold text-lg">{title}</h3>
        <label className="input input-bordered flex items-center gap-2">
          {label}
          <input
            type="text"
            className="grow bg-inherit placeholder:text-gray-600"
            placeholder={placeholder}
            value={text}
            onChange={update => {
              if (update.target.value) {
                setText(update.target.value);
              }
            }}
            autoComplete={'off'}
          />
        </label>
        <div className="flex flex-row gap-2 justify-end mt-2">
          <button className="btn btn-neutral btn-sm">Cancel</button>
          <button
            className="btn btn-primary btn-sm"
            disabled={!text || text.trim().length === 0}
          >
            Ok
          </button>
        </div>
      </form>
    </dialog>
  );
}
