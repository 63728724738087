import {EnvelopeIcon} from '@heroicons/react/20/solid';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {EmailContentType, SendEmailNode} from '../../types/node';
import {useContext, useEffect, useState} from 'react';
import {EditableText, EditableTextarea} from '../../editable';
import {WorkflowContext} from '../../../../context';
import {Step} from '../../../../../bindings/Step';

export class EmailNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Send Email';
  public nodeLabel = 'Send Email';
  public getNodeIcon(className?: string): JSX.Element {
    return <EnvelopeIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <SendEmail {...baseProps}></SendEmail>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'SendEmail',
      templateId: null,
      data: {
        attachDocuments: false,
        body: null,
        bodyContentType: null,
        email: {to: [], cc: null, bcc: null},
        reply: null,
        subject: null,
      },
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const SEND_EMAIL_NODE_DEF = new EmailNodeDefinition();

export const DEFAULT_SEND_EMAIL = {
  email: {
    to: [],
  },
  attachDocuments: false,
} as SendEmailNode;

export default function SendEmail({
  data,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const {workflowDef} = useContext(WorkflowContext);
  const [editing, setEditing] = useState<boolean>();
  const [email, setEmail] = useState<string>('');
  const [attach, setAttach] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [inputAsBody, setInputAsBody] = useState<boolean>(true);
  const [lastBodyValue, setLastBodyValue] = useState<string>('');
  const [canReply, setCanReply] = useState<boolean>(false);
  const [reply, setReply] = useState<boolean>(false);
  const [useHtml, setUseHtml] = useState<boolean>(true);

  interface ConfigUpdate {
    attach: boolean;
    subject: string;
    email: string;
    body: string;
    reply: boolean;
    useHtml: boolean;
  }

  useEffect(() => {
    const emailData = data as SendEmailNode;
    if (emailData.email.to.length > 0) {
      setEmail(emailData.email.to[0]);
    }

    setAttach(emailData.attachDocuments);
    setSubject(emailData.subject ?? '');

    setInputAsBody(!emailData.body);
    setBody(emailData.body ?? '');

    setReply(emailData.reply ?? false);
    setUseHtml(
      !emailData.bodyContentType ||
        emailData.bodyContentType === EmailContentType.HTML
    );
  }, [data]);

  useEffect(() => {
    if (workflowDef && workflowDef.on && workflowDef.on.email) {
      setCanReply(true);
    } else {
      setCanReply(false);
    }
  }, [workflowDef]);

  useEffect(() => {
    if (canReply && reply && workflowDef?.input?.type === 'json') {
      const data = workflowDef.input.data.content;
      const currentSubject = (data as any).subject ?? '';
      setSubject(`Re: ${currentSubject}`);
    }
  }, [canReply, reply, workflowDef]);

  const saveChanges = ({
    attach,
    subject,
    email,
    body,
    reply,
    useHtml,
  }: ConfigUpdate) => {
    const update = {
      email: {
        to: [email],
      },
      attachDocuments: attach,
    } as SendEmailNode;

    if (subject.trim().length > 0) {
      update.subject = subject;
    }

    if (body.trim().length > 0) {
      update.body = body;
    }

    if (canReply) {
      update.reply = reply;
    }

    if (useHtml) {
      update.bodyContentType = EmailContentType.HTML;
    } else {
      update.bodyContentType = EmailContentType.TEXT;
    }

    onUpdateData(update);
    setEditing(false);
  };

  let styles = 'rounded-lg';
  if (editing) {
    styles = `${styles} bg-neutral-900`;
  } else {
    styles = `${styles} bg-base-100`;
  }

  return (
    <div className="flex flex-col gap-2">
      {canReply ? (
        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="label-text">Reply to Email</span>
            <input
              type="checkbox"
              className="toggle toggle-primary"
              checked={reply}
              onChange={event => {
                saveChanges({
                  attach,
                  subject,
                  email,
                  body,
                  useHtml,
                  reply: event.target.checked,
                });
              }}
            />
          </label>
        </div>
      ) : null}
      {!reply ? (
        <>
          <label className="input input-bordered flex items-center gap-2">
            <EnvelopeIcon className="w-4 h-4"></EnvelopeIcon>
            <EditableText
              className={`grow ${styles}`}
              data={email}
              onChange={(newVal: string) => {
                saveChanges({
                  attach,
                  subject,
                  email: newVal,
                  body,
                  useHtml,
                  reply,
                });
              }}
            />
          </label>
          <label className="form-control gap-2">
            <div className="label">
              <span className="label-text">Subject</span>
            </div>
            <EditableText
              className={`p-3 grow ${styles}`}
              data={subject}
              onChange={(newVal: string) => {
                saveChanges({
                  attach,
                  subject: newVal,
                  email,
                  body,
                  useHtml,
                  reply,
                });
              }}
            />
          </label>
        </>
      ) : null}
      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">Use Input as Email Body</span>
          <input
            type="checkbox"
            className="toggle toggle-primary"
            checked={inputAsBody}
            onChange={event => {
              setLastBodyValue(body);
              const checked = event.target.checked;
              setInputAsBody(checked);
              if (checked) {
                saveChanges({attach, subject, email, body: '', useHtml, reply});
              } else if (lastBodyValue.length > 0) {
                saveChanges({
                  attach,
                  subject,
                  email,
                  body: lastBodyValue,
                  useHtml,
                  reply,
                });
              }
            }}
          />
        </label>
      </div>
      {!inputAsBody ? (
        <label className="form-control gap-2">
          <div className="label">
            <span className="label-text">Body</span>
          </div>
          <EditableTextarea
            className={`p-3 grow ${styles}`}
            data={body}
            onChange={(newVal: string) => {
              saveChanges({
                attach,
                subject,
                email,
                body: newVal,
                useHtml,
                reply,
              });
              setLastBodyValue(newVal);
            }}
          />
        </label>
      ) : null}

      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">Attach Documents</span>
          <input
            type="checkbox"
            className="toggle toggle-primary"
            checked={attach}
            onChange={event => {
              saveChanges({
                attach: event.target.checked,
                subject,
                email,
                body,
                useHtml,
                reply,
              });
            }}
          />
        </label>
      </div>
      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">Use Html Email Body</span>
          <input
            type="checkbox"
            className="toggle toggle-primary"
            checked={useHtml}
            onChange={event => {
              saveChanges({
                attach,
                subject,
                email,
                body,
                useHtml: event.target.checked,
                reply,
              });
            }}
          />
        </label>
      </div>
    </div>
  );
}
