import {EllipsisHorizontalIcon} from '@heroicons/react/20/solid';
import {PlusCircleIcon} from '@heroicons/react/24/outline';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  LOCAL_TOKEN_STORAGE,
  SightglassClient,
  requestConnection,
} from '../api/Client';

export default function ManualOnboard() {
  const {accountId} = useParams();
  const [userId, setUserId] = useState<string | null>(null);
  const [displayName, setDisplayName] = useState<string | null>(null);

  useEffect(() => {
    // login the user with the accountId
    // pull account details &
    const token = accountId ?? '';
    const client = new SightglassClient(token);
    localStorage.setItem(LOCAL_TOKEN_STORAGE, token);

    client?.auth.getUserProfile().then(data => {
      setUserId(data.id.toString());
      setDisplayName(data.displayName);
    });
  }, [accountId]);

  const onAddConnection = (apiId: string) => {
    const token = localStorage.getItem(LOCAL_TOKEN_STORAGE);
    if (token) {
      requestConnection(token, apiId).then(resp => {
        if (resp.result) {
          window.open(resp.result.url, 'blank');
        }
      });
    }
  };

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content text-center">
        <div className="max-w-md flex flex-col items-center gap-16">
          <div className="flex flex-row items-center gap-4 text-lg font-bold text-accent">
            <img
              src={`${process.env.PUBLIC_URL}/logo@2x.png`}
              className="w-16"
              alt="Sightglass Logo"
            />
            Sightglass Onboarding
          </div>
          <div className="flex flex-row items-center gap-4">
            <div className="rounded-lg border-2 border-slate-700 p-4 w-48 h-48 flex flex-col items-center">
              <div className="h-32 w-32">
                {userId && (
                  <img
                    className="w-32 py-16"
                    src={`https://app.sightglass.ai/public/logos/onboard-logo-${userId}.png`}
                  />
                )}
              </div>
              <div className="text-lg font-bold">{displayName}</div>
            </div>
            <div>
              <EllipsisHorizontalIcon className="w-16" />
            </div>
            <div className="rounded-lg border-2 border-slate-700 p-4 w-48 h-48 flex flex-col items-center">
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="w-32 p-4"
                fill="#FF7A59"
              >
                <path d="M18.164 7.93V5.084a2.198 2.198 0 001.267-1.978v-.067A2.2 2.2 0 0017.238.845h-.067a2.2 2.2 0 00-2.193 2.193v.067a2.196 2.196 0 001.252 1.973l.013.006v2.852a6.22 6.22 0 00-2.969 1.31l.012-.01-7.828-6.095A2.497 2.497 0 104.3 4.656l-.012.006 7.697 5.991a6.176 6.176 0 00-1.038 3.446c0 1.343.425 2.588 1.147 3.607l-.013-.02-2.342 2.343a1.968 1.968 0 00-.58-.095h-.002a2.033 2.033 0 102.033 2.033 1.978 1.978 0 00-.1-.595l.005.014 2.317-2.317a6.247 6.247 0 104.782-11.134l-.036-.005zm-.964 9.378a3.206 3.206 0 113.215-3.207v.002a3.206 3.206 0 01-3.207 3.207z" />
              </svg>
              <div className="text-lg font-bold">Hubspot</div>
            </div>
          </div>
          <div>
            <button
              className="btn btn-primary"
              onClick={() => onAddConnection('hubspot.com')}
            >
              <PlusCircleIcon className="w-8" />
              Add Connection
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
