import {useState, useEffect, useContext} from 'react';
import {useNavigate, useLoaderData} from 'react-router-dom';
import {UserContext} from '../../context';
import {WorkflowResponse} from '../../api/Client';
import JsonView from '@uiw/react-json-view';
import {githubDarkTheme} from '@uiw/react-json-view/githubDark';
import {DateTime} from 'luxon';
import {
  ArrowPathIcon,
  ArrowLeftIcon,
  ArrowDownIcon,
  EyeSlashIcon,
  ClipboardDocumentListIcon,
  EyeIcon,
} from '@heroicons/react/20/solid';
import {DataNode} from '../../../bindings/DataNode';
import SanitizedHTML from '../../components/SanitizedHtml';
import {NodeComponent, getNodeDefinition} from '../../components/talos/nodes';
import {ValidationStatus} from '../../components/talos/utils/workflowValidator';
import {
  LastRunDetails,
  NodeDef,
  NodeResult,
  NodeResultStatus,
  NodeType,
} from '../../components/talos/types/node';
import {InputNodeComponent} from '../../components/talos/nodes/InputNode';
import {UserWorkflowInstanceSummary} from '../../../bindings/api/UserWorkflowInstanceSummary';
import {UserWorkflowInstanceResult} from '../../../bindings/api/UserWorkflowInstanceResult';

export interface WorkflowRunProps {
  workflowUuid: string;
  runUuid: string;
}

export function WorkflowRun() {
  const {client} = useContext(UserContext);
  const nav = useNavigate();
  const {workflowUuid, runUuid} = useLoaderData() as WorkflowRunProps;
  const [workflowInstance, setWorkflowInstance] =
    useState<UserWorkflowInstanceSummary>();
  const [workflow, setWorkflow] = useState<WorkflowResponse>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (workflowUuid && runUuid) {
      setLoading(true);
      Promise.all([
        client
          ?.getWorkflowInstance(runUuid)
          .then(response => {
            if (response.result) {
              setWorkflowInstance(response.result);
            }
          })
          .catch(console.error),
        client?.workflow
          .get(workflowUuid)
          .then(response => {
            if (response.result) {
              setWorkflow(response.result);
            }
          })
          .catch(console.error),
      ]).finally(() => {
        setLoading(false);
      });
    }
  }, [client, workflowUuid, runUuid]);

  return (
    <div className="flex min-h-screen flex-col gap-8 items-center w-full">
      <div className="navbar md:w-fit mx-auto lg:fixed bg-base-200 p-4 rounded-lg z-10 shadow-lg pb-8 md:pb-4">
        <div className="navbar-center flex flex-col lg:flex-row place-content-center items-center w-full">
          <div className="text-2xl">
            {loading ? (
              <div>
                <ArrowPathIcon className="w-6 animate-spin" />
              </div>
            ) : (
              <div className="w-full mr-2 text-2xl">
                {workflow?.workflow.name}
              </div>
            )}
          </div>
          <div className="divider lg:divider-horizontal my-0"></div>

          <div className="flex flex-row gap-2">
            <button
              className="btn btn-primary"
              onClick={() => nav(`/workflows/${workflowUuid}/run`)}
            >
              <ArrowLeftIcon className="w-4" />
              Back
            </button>
          </div>
        </div>
      </div>
      <div className="mt-32 flex flex-col gap-6">
        {workflowInstance?.workflowResults?.map((result, idx) => {
          const lastResult =
            (workflowInstance.workflowResults?.length ?? 0) - 1 === idx;
          return (
            <TaskResult
              key={result.taskUuid}
              result={result}
              lastResult={lastResult}
            ></TaskResult>
          );
        })}
      </div>
    </div>
  );
}

interface TaskResultProps {
  result: UserWorkflowInstanceResult;
  lastResult: boolean;
}

function TaskResult({result, lastResult}: TaskResultProps) {
  const outputNode = (result.output as any)?.data as DataNode;
  let lastRun: LastRunDetails | undefined = undefined;
  if (result.finishedOn) {
    const created = DateTime.fromISO(result.createdAt);
    const finished = DateTime.fromISO(result.finishedOn);
    const status =
      result.taskStatus === 'Failed'
        ? NodeResultStatus.Error
        : NodeResultStatus.Ok;

    lastRun = {
      startTimestamp: created.toJSDate(),
      endTimestamp: finished.toJSDate(),
      nodeResult: {
        status: status,
        data: result.output,
        error: result.errorMsg,
      } as NodeResult,
    } as LastRunDetails;
  }

  let data = (result.taskData as any)?.config;
  let nodeType = data.nodeType;
  let templateId;
  if (
    nodeType === NodeType.DataDestination &&
    data.data?.connectionData?.connectionType === 'MicrosoftTodo'
  ) {
    templateId = NodeType.MicrosoftTodo;
    nodeType = NodeType.MicrosoftTodo;
  } else if (data.taskDefUuid) {
    const customNodeType = NodeType[data.taskDefUuid as keyof typeof NodeType];
    if (customNodeType) {
      templateId = customNodeType as keyof typeof NodeType;
    }
  }

  const definition = getNodeDefinition(nodeType, data?.data?.type);
  const step = definition.createStep() as NodeDef;
  const node = {...step, ...data};

  if (data?.data?.type) {
    data = (result.taskData as any)?.config.data;

    return (
      <>
        <InputNodeComponent
          workflowInput={data}
          currentNodeRunning={null}
          onDelete={() => {}}
          onUpdate={() => {}}
        />
        {!lastResult ? <ArrowDownIcon className="w-4 mx-auto" /> : null}
      </>
    );
  } else {
    return (
      <>
        <NodeComponent
          {...node}
          templateId={templateId}
          key={result.taskUuid}
          workflowValidation={{result: ValidationStatus.Success}}
          lastRun={lastRun}
          nodeState={{expanded: false}}
        />
        {outputNode ? (
          <DataNodeView dataNode={outputNode}></DataNodeView>
        ) : null}
        {!lastResult ? <ArrowDownIcon className="w-4 mx-auto" /> : null}
      </>
    );
  }
}

interface DataNodeProps {
  dataNode: DataNode;
}

function DataNodeView({dataNode}: DataNodeProps) {
  const [showResult, setShowResult] = useState<boolean>(true);
  const [isCopying, setIsCopying] = useState(false);

  const nav = useNavigate();

  const handleCopy = () => {
    setIsCopying(true);
    setTimeout(() => setIsCopying(false), 512);

    switch (dataNode.type) {
      case 'collection':
        navigator.clipboard.writeText(dataNode.data.collection);
        break;
      case 'connection':
        navigator.clipboard.writeText(
          JSON.stringify(dataNode.data.connectionData, null, 2)
        );
        break;
      case 'document':
      case 'documentList':
      case 'textList':
      case 'text':
        navigator.clipboard.writeText(JSON.stringify(dataNode.data, null, 2));
        break;
      case 'json':
        navigator.clipboard.writeText(
          JSON.stringify(dataNode.data.content, null, 2)
        );
        break;
      case 'jsonList':
        navigator.clipboard.writeText(
          JSON.stringify(dataNode.data.items, null, 2)
        );
        break;
      case 'url':
        navigator.clipboard.writeText(dataNode.data.url);
        break;
      case 'rssFeed':
        navigator.clipboard.writeText(dataNode.data.url);
        break;
    }
  };

  let dataView;
  switch (dataNode.type) {
    case 'collection':
      dataView = (
        <div className="flex flex-row gap-2 items-center">
          <span>Collection:</span>
          <button
            className="btn btn-sm"
            onClick={() => nav(`/collections/${dataNode.data.collection}`)}
          >
            {dataNode.data.collection}
          </button>
        </div>
      );
      break;
    case 'connection':
      dataView = <></>;
      break;
    case 'document':
      dataView = (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2 items-center">
            <span>Collection:</span>
            <button
              className="btn btn-sm"
              onClick={() => nav(`/collections/${dataNode.data.collection}`)}
            >
              {dataNode.data.collection}
            </button>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <span>Document:</span>
            <button
              className="btn btn-sm"
              onClick={() => nav(`/collections/${dataNode.data.collection}`)}
            >
              {dataNode.data.document}
            </button>
          </div>
        </div>
      );
      break;
    case 'documentList':
      dataView = (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2 items-center">
            <span>Collection:</span>
            <button
              className="btn btn-sm"
              onClick={() => nav(`/collections/${dataNode.data.collection}`)}
            >
              {dataNode.data.collection}
            </button>
          </div>
          {dataNode.data.selected_documents.map(document => {
            return (
              <div className="flex flex-row gap-2 items-center">
                <span>Document:</span>
                <button
                  className="btn btn-sm"
                  onClick={() =>
                    nav(`/collections/${dataNode.data.collection}`)
                  }
                >
                  {document}
                </button>
              </div>
            );
          })}
        </div>
      );
      break;
    case 'json':
      dataView = (
        <JsonView
          value={dataNode.data.content as object}
          style={githubDarkTheme}
          collapsed={1}
        />
      );
      break;
    case 'jsonList':
      dataView = (
        <JsonView
          value={dataNode.data.items as object[]}
          style={githubDarkTheme}
          collapsed={1}
        />
      );
      break;
    case 'rssFeed':
      dataView = <></>;
      break;
    case 'text':
      dataView = (
        <span className="break-all md:break-normal overflow-auto">
          <SanitizedHTML dirty={dataNode.data}></SanitizedHTML>
        </span>
      );
      break;
    case 'textList':
      dataView = (
        <span className="break-all md:break-normal overflow-auto">
          {dataNode.data.map(txt => {
            return <SanitizedHTML dirty={txt}></SanitizedHTML>;
          })}
        </span>
      );
      break;
    case 'url':
      dataView = (
        <span className="break-all md:break-normal overflow-auto">
          {dataNode.data.url}
        </span>
      );
      break;
  }
  return (
    <div
      className={`card shadow-xl w-full lg:w-[640px] md:mx-auto md:max-w-[640px] bg-neutral border-2 border-neutral ${
        showResult ? 'p-6' : 'p-2'
      }`}
    >
      {showResult ? (
        <div className="card-body p-2 max-h-[512px] overflow-y-auto">
          {dataView}
        </div>
      ) : null}
      <div className="card-actions p-2 place-content-center flex flex-row items-center">
        {showResult ? (
          <button className="btn btn-sm" onClick={() => setShowResult(false)}>
            <EyeSlashIcon className="w-4 h-4" />
            Hide
          </button>
        ) : (
          <button className="btn btn-sm" onClick={() => setShowResult(true)}>
            <EyeIcon className="w-4 h-4" />
            Show
          </button>
        )}

        <button
          className="btn btn-sm"
          disabled={isCopying}
          onClick={() => handleCopy()}
        >
          {isCopying ? (
            <span className="loading loading-spinner loading-sm"></span>
          ) : (
            <ClipboardDocumentListIcon className="w-4 h-4" />
          )}
          Copy
        </button>
      </div>
    </div>
  );
}
