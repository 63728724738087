import {NodeBodyProps} from '../nodes';

export interface GeneralDescriptionNodeProps extends NodeBodyProps {
  description: string;
}

export function GeneralDescriptionNode({
  uuid,
  description,
}: GeneralDescriptionNodeProps) {
  return (
    <div key={uuid} className="flex flex-col gap-4">
      <div className="flex flex-col">
        <label className="uppercase font-bold text-xs mb-1">Description</label>
        <div>{description}</div>
      </div>
    </div>
  );
}
