import {CurrentUser} from '../../bindings/api/CurrentUser';

export function isFeatureEnabled(
  profile: CurrentUser,
  feature: string
): boolean {
  const enabledFeature = profile.features?.enabledFeatures.find(
    featureDef => featureDef.feature === feature
  );
  return enabledFeature !== undefined;
}

export function downloadBlob(name: string, blob: Blob) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
}
