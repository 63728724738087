import React from 'react';
import ReactDOM from 'react-dom/client';
import {AppState, Auth0Provider} from '@auth0/auth0-react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigate,
  useNavigationType,
  Navigate,
  LoaderFunctionArgs,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './ErrorPage';
import {Assistant} from './pages/Assistant';
import {Checkout} from './pages/Checkout';
import {CollectionListView} from './pages/CollectionListView';
import {RecordingsView} from './pages/RecordingsView';
import {WorkflowDashboard} from './pages/Workflows';
import ApiKeys from './pages/ApiKeys';
import Connections, {ConnectionCallback} from './pages/Connections';
import ManualOnboard from './pages/ManualOnboard';
import ScrollToTop from './components/buttons/ScrollToTop';
import WorkflowEditor from './pages/Workflows/WorkflowEditor';
import {
  WorkflowInstance,
  WorkflowInstanceViews,
} from './pages/Workflows/WorkflowInstance';
import {WorkflowRun} from './pages/Workflows/WorkflowRun';
import {Editor} from './pages/Editor';

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_MIXPANEL_ID
) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, {
    track_pageview: true,
    persistence: 'localStorage',
  });
}

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  release: process.env.REACT_APP_GIT_SHA,
  dsn: 'https://ebd88934d05cacba779d6f9d8aa72197@o1334159.ingest.sentry.io/4505999134883840',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/app\.sightglass\.ai\//,
      ],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const Auth0ProviderLayout = () => {
  const navigate = useNavigate();
  const redirectOnLogin = (appState?: AppState): void => {
    if (appState?.inviteCode) {
      console.debug('handling user invite');
      return navigate(`/invite/${appState.inviteCode}`);
    } else {
      return navigate(
        (appState && appState.returnTo) || window.location.pathname
      );
    }
  };

  return (
    <Auth0Provider
      cacheLocation="localstorage"
      useRefreshTokens={true}
      onRedirectCallback={redirectOnLogin}
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      authorizationParams={{
        redirect_uri: window.location.origin + '/authorize',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
      skipRedirectCallback={window.location.pathname !== '/authorize'}
    >
      <div className="min-h-screen">
        <ScrollToTop />
        <App />
      </div>
      <div className="text-sm text-neutral-500 flex flex-col gap-1 text-center -mt-32">
        <div className="divider text-xs">🔭</div>
        <div>
          <div className="text-neutral-600 font-semibold">
            v: {process.env.REACT_APP_GIT_SHA ?? 'dev'}
          </div>
          <div>Made with ☕️ in SF/SD.</div>
        </div>
      </div>
    </Auth0Provider>
  );
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  // Easy 1-click nboarding links for clients
  {
    path: '/admin/onboard/:accountId',
    element: <ManualOnboard />,
    loader: ({params}: LoaderFunctionArgs) => params,
  },
  // Open endpoint for OAuth authorization requests.
  {
    path: '/connections/authorize/:apiId',
    element: <ConnectionCallback />,
    loader: ({params}: LoaderFunctionArgs) => params,
  },
  {
    path: '/',
    element: <Auth0ProviderLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/invite/:inviteCode',
        element: <Navigate to="/collections" />,
        loader: ({params}: LoaderFunctionArgs) => params,
      },
      {
        path: '/',
        element: <Navigate to="/workflows" />,
      },
      {
        path: '/authorize',
        element: <Navigate to="/workflows" />,
      },
      {
        path: '/checkout/:status',
        element: <Checkout />,
        loader: ({params}: LoaderFunctionArgs) => params,
      },
      {
        path: '/noted',
        element: <Assistant />,
      },
      {
        path: '/collections',
        element: <CollectionListView />,
      },
      {
        path: '/collections/:collectionUuid',
        element: <RecordingsView />,
        loader: ({params}: LoaderFunctionArgs) => params,
      },
      {
        path: '/collections/:collectionUuid/documents/:documentUuid',
        element: <Editor />,
        loader: ({params}: LoaderFunctionArgs) => params,
      },
      {
        path: '/workflows',
        element: <WorkflowDashboard />,
      },
      {
        path: '/workflows/:workflowUuid',
        element: <WorkflowInstance />,
        loader: ({params}: LoaderFunctionArgs) => params,
      },
      {
        path: '/workflows/:workflowUuid/run/:runUuid',
        element: <WorkflowRun />,
        loader: ({params}: LoaderFunctionArgs) => params,
      },
      {
        path: '/workflows/:workflowUuid/run',
        element: <WorkflowInstance defaultView={WorkflowInstanceViews.Runs} />,
        loader: ({params}: LoaderFunctionArgs) => params,
      },
      {
        path: '/workflows/examples/:workflowUuid',
        element: <WorkflowEditor loadExample />,
        loader: ({params}: LoaderFunctionArgs) => params,
      },
      {
        path: '/keys',
        element: <ApiKeys />,
      },
      {
        path: '/connections',
        element: <Connections />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
