import {useEffect, useState} from 'react';
import {NodeBodyProps, NodeDefinition} from '../nodes';
import {CollectionSelector} from '../../nodes/CollectionSelector';
import {DataNodeDefData} from '../types/node';
import {BuildingLibraryIcon} from '@heroicons/react/20/solid';
import {Step} from '../../../../bindings/Step';

export class CollectionNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Collection Source';
  public nodeLabel = 'Collection Source';
  public getNodeIcon(className?: string): JSX.Element {
    return <BuildingLibraryIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <CollectionInputNode {...baseProps}></CollectionInputNode>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'DataSource',
      templateId: null,
      data: {type: 'collection', data: {collection: ''}},
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const COLLECTION_NODE_DEFINITION = new CollectionNodeDefinition();

export function CollectionInputNode({data, onUpdateData}: NodeBodyProps) {
  const [selectedCollection, setSelectedCollection] = useState<string>();

  useEffect(() => {
    if (data) {
      setSelectedCollection((data as DataNodeDefData).collection);
    }
  }, [data]);

  return (
    <CollectionSelector
      onSelect={uuid => {
        if (onUpdateData) {
          onUpdateData({
            collection: uuid,
          } as DataNodeDefData);
        }
      }}
      selectedCollection={selectedCollection}
    ></CollectionSelector>
  );
}
