export interface TranscriptMessage {
  messageType: TranscriptMessageTypes;
}

export interface TranscriptTextMessage extends TranscriptMessage {
  audio_start: number;
  audio_end: number;
  confidence: number;
  text: string;
  words: TranscriptWords[];
  created: string;
}

export interface SessionBegins extends TranscriptMessage {
  session_id: string;
  expires_at: string;
}

export interface SessionTerminated extends TranscriptMessage {
  messageType: TranscriptMessageTypes;
}

export interface TranscriptWords {
  start: number;
  end: number;
  confidence: number;
  text: string;
}

export enum TranscriptMessageTypes {
  SessionBegins = 'SessionBegins',
  PartialTranscript = 'PartialTranscript',
  FinalTranscript = 'FinalTranscript',
  SessionTerminated = 'SessionTerminated',
  Error = 'Error',
}

export function isPartialTranscript(data: any): data is TranscriptTextMessage {
  return data.messageType === TranscriptMessageTypes.PartialTranscript;
}

export function isFinalTranscript(data: any): data is TranscriptTextMessage {
  return data.messageType === TranscriptMessageTypes.FinalTranscript;
}

export function isSessionBegins(data: any): data is SessionBegins {
  return data.messageType === TranscriptMessageTypes.SessionBegins;
}

export function isSessionTerminated(data: any): data is SessionTerminated {
  return data.messageType === TranscriptMessageTypes.SessionTerminated;
}
