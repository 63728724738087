import {ClipboardIcon} from '@heroicons/react/20/solid';
import {MouseEvent, useState} from 'react';

interface CopyToClipboardProps {
  className?: String;
  disabled: boolean;
  getText: () => any;
}

export function CopyToClipboard({
  className,
  disabled,
  getText,
  children,
}: React.PropsWithChildren<CopyToClipboardProps>) {
  const [isCopying, setIsCopying] = useState<boolean>(false);

  const copyTranscript = async (e: MouseEvent) => {
    e.preventDefault();
    if (isCopying) {
      return;
    }

    setIsCopying(true);
    if ('clipboard' in navigator) {
      const transcriptBlob = getText();
      return await navigator.clipboard
        .writeText(transcriptBlob ?? '')
        .then(() => setTimeout(() => setIsCopying(false), 256));
    }
  };
  let classes = 'btn btn-neutral';
  if (className) {
    classes += ' ' + className;
  }

  return (
    <button
      className={classes}
      onClick={copyTranscript}
      disabled={isCopying || disabled}
    >
      {isCopying ? (
        <span className="loading loading-spinner" />
      ) : (
        <ClipboardIcon className="h-4 w-4" />
      )}
      {children}
    </button>
  );
}
