import {useContext, useEffect, useState} from 'react';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {DataNodeType, NodeType, SaveToCollectionNode} from '../../types/node';
import {CollectionSelector} from '../../../nodes/CollectionSelector';
import {EditableText} from '../../editable';
import {WorkflowContext} from '../../../../context';
import {getPreviousNode} from '../../workflows/utils';
import {CloudArrowUpIcon} from '@heroicons/react/20/solid';
import {Step} from '../../../../../bindings/Step';

export class SaveToCollectionNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Save to Collection';
  public nodeLabel = 'Save to Collection';
  public getNodeIcon(className?: string): JSX.Element {
    return <CloudArrowUpIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <SaveToCollection {...baseProps}></SaveToCollection>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'SaveToCollection',
      templateId: null,
      data: {
        collection: '',
        metadata: null,
        name: '',
        useInputCollection: false,
      },
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const SAVE_TO_COLLECTION_NODE_DEF = new SaveToCollectionNodeDefinition();

export const DEFAULT_SAVE_TO_COLLECTION = {
  collection: '',
  name: '',
  useInputCollection: false,
} as SaveToCollectionNode;

export default function SaveToCollection({
  data,
  uuid,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const {workflowDef} = useContext(WorkflowContext);
  const [collection, setCollection] = useState<string>();
  const [name, setName] = useState<string>('');
  const [useInputCollection, setUseInputCollection] = useState<boolean>(false);
  const [hasCollectionSource, setHasCollectionSource] =
    useState<boolean>(false);

  useEffect(() => {
    const collectionData = data as SaveToCollectionNode;
    setCollection(collectionData.collection);
    setName(collectionData.name);
    setUseInputCollection(collectionData.useInputCollection);
  }, [data]);

  useEffect(() => {
    if (workflowDef) {
      if (
        workflowDef.input &&
        (workflowDef.input.type === DataNodeType.Collection ||
          workflowDef.input.type === DataNodeType.CollectionDocument)
      ) {
        setHasCollectionSource(true);
      } else {
        const prevNode = getPreviousNode(uuid, workflowDef.flow.steps);
        console.error(prevNode);
        if (prevNode && prevNode.nodeType === NodeType.PullDocument) {
          setHasCollectionSource(true);
        } else {
          setHasCollectionSource(false);
        }
      }
    }
  }, [workflowDef, uuid]);

  const saveChanges = (update: Partial<SaveToCollectionNode>) => {
    const collectionData = data as SaveToCollectionNode;
    const newUpdate = {
      collection: update.collection ?? collectionData.collection,
      name: update.name ?? collectionData.name,
      metadata: update.metadata ?? collectionData.metadata,
      useInputCollection:
        update.useInputCollection ??
        (hasCollectionSource && collectionData.useInputCollection),
    } as SaveToCollectionNode;
    onUpdateData(newUpdate);
  };
  return (
    <div className="flex flex-col gap-2">
      {hasCollectionSource ? (
        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="label-text font-bold text-base">
              Save to Input Collection
            </span>
            <input
              type="checkbox"
              className="toggle toggle-primary"
              checked={useInputCollection}
              onChange={event => {
                saveChanges({useInputCollection: event.target.checked});
              }}
            />
          </label>
        </div>
      ) : null}
      {!hasCollectionSource || !useInputCollection ? (
        <label className="form-control gap-2 flex flex-row justify-between">
          <div className="label">
            <span className="label-text font-bold text-base">Collection</span>
          </div>
          <CollectionSelector
            onSelect={collection => {
              saveChanges({collection: collection});
            }}
            key="SaveToCollectionSelector"
            selectedCollection={collection}
            showTitle={false}
          ></CollectionSelector>
        </label>
      ) : null}
      <label className="form-control gap-2">
        <div className="label">
          <span className="label-text font-bold text-base">File Name</span>
        </div>
        <EditableText
          data={name}
          className={'p-3 grow rounded-lg bg-base-100'}
          onChange={newVal => {
            saveChanges({name: newVal});
          }}
        ></EditableText>
        <div className="text-xs text-neutral-500">
          If multiple files are being saved, this will be added to end of the
          filename.
        </div>
      </label>
    </div>
  );
}
