import {useState} from 'react';
import {
  DataNodeType,
  LastRunDetails,
  NodeDataTypes,
  NodeState,
  NodeType,
  NodeUpdates,
} from '../types/node';
import {getLocalUUID} from '../utils/nodeUtils';
import {BASE_CARD_STYLE, NodeHeader, getNodeDefinition} from '../nodes';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import {DataNode} from '../../../../bindings/DataNode';

export interface InputNodeProps {
  workflowInput: DataNode;
  nodeState?: NodeState;
  currentNodeRunning?: string | null;
  nodeResults?: Map<string, LastRunDetails>;
  onDelete?: () => void;
  // Request node update
  onUpdate?: (nodeUpdates: NodeUpdates) => void;
  onStateChange?: (nodeSate: NodeState) => void;
}

export function InputNodeComponent({
  workflowInput,
  currentNodeRunning,
  nodeResults,
  onUpdate = () => {},
  onDelete = () => {},
}: InputNodeProps) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const baseProps = {
    data: workflowInput.data,
    uuid: 'InputNode',
    onUpdateData: (data: NodeDataTypes) => {
      onUpdate({
        data: data,
      });
    },
  };

  let isRunning = false;
  if (currentNodeRunning) {
    isRunning = getLocalUUID(currentNodeRunning) === 'INPUT';
  }

  let lastRun;
  if (nodeResults) {
    const iter = nodeResults.entries();
    let value = iter.next();
    while (!value.done) {
      if (getLocalUUID(value.value[0]) === 'INPUT') {
        lastRun = value.value[1];
        break;
      }
      value = iter.next();
    }
  }

  let borderColor = isRunning ? 'border-info' : 'border-neutral';
  if (!isRunning && lastRun) {
    if (lastRun.nodeResult.status.toLowerCase() === 'ok') {
      borderColor = 'border-success';
    } else {
      borderColor = 'border-error';
    }
  }

  const {type: inputType} = workflowInput;
  const nodeType = NodeType.DataSource;
  let dataNodeType = inputType as DataNodeType;
  if (
    workflowInput.type === 'json' &&
    workflowInput.data.content_type === 'UserEmailWebhookEvent'
  ) {
    dataNodeType = DataNodeType.EmailContent;
  } else if (
    workflowInput.type === 'json' &&
    workflowInput.data.content_type === 'OutlookEmailMessage'
  ) {
    dataNodeType = DataNodeType.Outlook365Content;
  } else if (
    workflowInput.type === 'json' &&
    workflowInput.data.content_type === 'RingCentralWebHookEvent'
  ) {
    dataNodeType = DataNodeType.RingCentralCall;
  }

  const definition = getNodeDefinition(nodeType, dataNodeType);
  const nodeBody = definition.renderNode(baseProps);

  const toggleCollapse = () => {
    setIsCollapsed(state => !state);
  };

  return (
    <div className={`${BASE_CARD_STYLE} bg-neutral border-2 ${borderColor}`}>
      <figure
        className="bg-base-100 p-2 border-inherit"
        onDoubleClick={() => toggleCollapse()}
      >
        <div className="flex flex-row w-full items-center gap-4">
          <NodeHeader
            isEditable={false}
            label={definition?.nodeLabel ?? ''}
            nodeType={nodeType}
            subType={inputType as DataNodeType}
            onUpdate={value => onUpdate({label: value})}
          />
          <div className="flex flex-row gap-2">
            <button
              className="btn btn-circle btn-xs btn-neutral btn-outline"
              onClick={() => toggleCollapse()}
            >
              {isCollapsed ? (
                <ChevronDownIcon className="w-4 text-gray" />
              ) : (
                <ChevronUpIcon className="w-4 text-gray" />
              )}
            </button>
            <button
              className="btn btn-circle btn-xs btn-error btn-outline"
              onClick={() => onDelete()}
            >
              <XMarkIcon className="w-4 text-gray" />
            </button>
          </div>
        </div>
      </figure>
      {!isCollapsed ? (
        <div className="card-body px-6 py-4">
          <div className="flex flex-col gap-4">{nodeBody}</div>
        </div>
      ) : null}
      <figure className="card-actions bg-base-100 py-2 px-4 text-xs">
        &nbsp;
      </figure>
    </div>
  );
}
