import {useContext, useEffect, useRef} from 'react';
import axios from 'axios';
import {
  ArrowDownIcon,
  ArrowPathIcon,
  CloudArrowUpIcon,
  PlayIcon,
  PlusCircleIcon,
  StopIcon,
} from '@heroicons/react/20/solid';
import {
  NodeDef,
  NodeResult,
  LastRunDetails,
  NodeUpdates,
  NodeType,
  DataNodeType,
  ParentDataDef,
  OutputDataType,
  NodeState,
} from '../../components/talos/types/node';
import {
  NodeComponent,
  WorkflowResult,
  getNodeDefinition,
  getTriggerDefinition,
} from '../../components/talos/nodes';
import {useState} from 'react';
import {
  getPreviousUuid,
  insertNode,
  nodeComesAfter,
  removeNode,
} from '../../components/talos/workflows/utils';
import {cancelExecution} from '../../components/talos/workflows/executor';
import {ModalType} from '../../components/modals';
import AddNodeModal from './modals/AddNodeModal';
import {runWorkflow} from '../../components/talos/workflows';
import {ConfigureMappingModal} from './modals/ConfigureMappingModal';
import {
  InputOutputDefinition,
  canConfigureMappings,
  generateInputOutputTypes,
} from '../../components/talos/types/typeutils';
import {DropArea} from '../../components/talos/nodes/DropArea';
import {NodeDivider} from '../../components/talos/nodes/NodeDivider';
import {WorkflowValidationResult} from '../../components/talos/utils/workflowValidator';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {UserWorkflow, UserWorkflowState} from '../../api/Client';
import {useAuth0} from '@auth0/auth0-react';
// import {WorkflowConfiguration} from '../../components/nodes/workflowConfig';
// import {convertSuggestionToNode} from '../../utils/workflowUtils';
import {UserContext, WorkflowContext} from '../../context';
import AddSourceModal from './modals/AddSourceModal';
import {Subject} from 'rxjs';
import {EditableText} from '../../components/talos/editable';
import {InputNodeComponent} from '../../components/talos/nodes/InputNode';
import {DataNode} from '../../../bindings/DataNode';
import {WorkflowTriggerType} from '../../../bindings/WorkflowTriggerType';
import {WorkflowTrigger} from '../../../bindings/WorkflowTrigger';
import {TriggerConfig} from '../../components/talos/nodes/TriggerConfig';
import {JSONContentType} from '../../../bindings/JSONContentType';

export interface WorkflowInstanceProps {
  workflowUuid: string;
}

interface WorkflowEditorProps {
  loadExample?: boolean;
}

const EMPTY_WORKFLOW: UserWorkflow = {
  uuid: 'empty',
  flow: {steps: []},
  version: 'empty',
};

const cancelListener = new Subject<boolean>();

export default function WorkflowEditor({
  loadExample = false,
}: WorkflowEditorProps) {
  const {workflowUuid} = useLoaderData() as WorkflowInstanceProps;
  const {getAccessTokenSilently} = useAuth0();
  const navigate = useNavigate();
  const [workflowContextData, setWorkflowContextData] =
    useState<WorkflowContext>({});
  const {client} = useContext(UserContext);

  const [isLoadingWorkflow, setIsLoadingWorkflow] = useState<boolean>(true);
  const [workflow, setWorkflow] = useState<UserWorkflow>(EMPTY_WORKFLOW);
  const [workflowDataTypes, setWorkflowDataTypes] = useState<
    InputOutputDefinition[]
  >([]);
  const [validationResult, setValidationResult] = useState<
    WorkflowValidationResult | undefined
  >(undefined);
  const [nodeResults, setNodeResults] = useState<Map<string, LastRunDetails>>(
    new Map()
  );
  const [nodeStates, setNodeStates] = useState<Map<string, NodeState>>(
    new Map()
  );
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [currentNodeRunning, setCurrentNodeRunning] = useState<string | null>(
    null
  );
  const [endResult, setEndResult] = useState<NodeResult | null>(null);
  const [dragOverUuid, setDragOverUuid] = useState<string | null>(null);
  const [draggedNode, setDraggedNode] = useState<string | null>(null);
  const [dragNDropAfter, setDragNDropAfter] = useState<boolean>(true);
  const [inputNode, setInputNode] = useState<NodeDef | null>(null);
  const [outputNode, setOutputNode] = useState<NodeDef | null>(null);
  const [cachedNodeTypes, setCachedNodeTypes] = useState<{
    [key: string]: InputOutputDefinition;
  }>({});

  const configureMappingModal = useRef<HTMLDialogElement>(null);
  const addNodeModal = useRef<ModalType>(null);
  const addInputNodeModal = useRef<ModalType>(null);
  const [workflowEnabled, setWorkflowEnabled] = useState<boolean>(false);

  // Initial load
  useEffect(() => {
    if (loadExample) {
      axios
        .get<UserWorkflow>(
          `${process.env.PUBLIC_URL}/workflow-examples/${workflowUuid}.json`
        )
        .then(resp => resp.data)
        .then(workflow => setWorkflow(workflow))
        .finally(() => setIsLoadingWorkflow(false));
    } else {
      client?.workflow
        .get(workflowUuid)
        .then(workflow => {
          if (workflow.result) {
            setWorkflow(workflow.result.workflow);
          }
          setWorkflowEnabled(
            workflow.result?.workflowState === UserWorkflowState.Active
          );
        })
        .finally(() => setIsLoadingWorkflow(false));
    }
  }, [client, workflowUuid, loadExample]);

  // Update context whenever the workflow changes.
  useEffect(() => {
    setWorkflowContextData(ctx => ({
      ...ctx,
      workflowDef: workflow,
    }));
  }, [workflow]);

  const saveAs = () => {
    setIsLoadingWorkflow(true);
    client?.workflow
      .create(workflow)
      .then(response => {
        if (response.result) {
          navigate(`/workflows/${response.result.workflow.uuid}`, {
            replace: true,
          });
        }
      })
      .finally(() => setIsLoadingWorkflow(false));
  };

  const updateNodeDataTypes = (
    newWorkflow: NodeDef[],
    cache: {[key: string]: InputOutputDefinition},
    getAuthToken: () => Promise<string>
  ) => {
    generateInputOutputTypes(newWorkflow, cache, getAuthToken).then(result => {
      const newCache = {...cachedNodeTypes};
      for (const node of result) {
        if (
          node.outputType === OutputDataType.TableResult &&
          node.outputSchema
        ) {
          newCache[node.uuid] = node;
        }
      }
      setCachedNodeTypes(newCache);
      setWorkflowDataTypes(result);
    });
  };

  const handleRunWorkflow = async () => {
    if (!workflow.input) {
      return;
    }

    setIsRunning(true);
    setEndResult(null);

    if (client) {
      try {
        const endResult = await runWorkflow(
          client,
          workflow,
          cancelListener,
          uuid => {
            setCurrentNodeRunning(uuid);
          },
          currentResults => {
            setNodeResults(currentResults);
          }
        );
        setEndResult(endResult);
      } catch (err) {
        console.error(err);
      }

      setCurrentNodeRunning(null);
    }
    setIsRunning(false);
  };

  const updateInputDef = (update: NodeUpdates) => {
    const updatedWorkflow = {
      ...workflow,
    };

    if (update.trigger) {
      updatedWorkflow.on = update.trigger;
    }

    if (update.data && workflow.input) {
      updatedWorkflow.input = {
        ...workflow.input,
        data: update.data as any,
      };
    }

    saveWorkflow(updatedWorkflow);
  };

  const updateWorkflowDef = (uuid: string, updates: NodeUpdates) => {
    const newCache = {...cachedNodeTypes};
    delete newCache[uuid];
    setCachedNodeTypes(newCache);

    const applyUpdate = (nodes: NodeDef[]) => {
      return nodes.map(node => {
        if (node.parentNode) {
          (node.data as ParentDataDef).actions = applyUpdate(
            (node.data as ParentDataDef).actions
          );
        }

        if (node.uuid === uuid) {
          return {
            ...node,
            label: updates.label ?? node.label,
            data: updates.data ?? node.data,
            inputSource: updates.inputSource ?? node.inputSource,
          };
        } else {
          return node;
        }
      });
    };

    // Apply the update.
    const updatedWorkflow = {
      ...workflow,
      flow: {
        steps: applyUpdate(workflow.flow.steps),
      },
    };

    // Save the update.
    saveWorkflow(updatedWorkflow);

    // Want to avoid making the same external request over and over
    if (
      (updates.data &&
        !((updates.data as DataNode).type === DataNodeType.Connection)) ||
      updates.mapping
    ) {
      updateNodeDataTypes(
        updatedWorkflow.flow.steps,
        cachedNodeTypes,
        getAccessTokenSilently
      );
    }
  };

  const updateNodeState = (uuid: string, update: NodeState) => {
    const newStates = new Map(nodeStates);
    newStates.set(uuid, update);
    setNodeStates(newStates);
  };

  const deleteWorkflowNode = (uuid: string) => {
    const newCache = {...cachedNodeTypes};
    delete newCache[uuid];
    setCachedNodeTypes(newCache);

    nodeStates.delete(uuid);
    setNodeStates(nodeStates);

    const previousUUID = getPreviousUuid(uuid, workflow.flow.steps);

    const findAndDelete = (nodes: NodeDef[]) => {
      return nodes.flatMap(node => {
        if (node.parentNode) {
          (node.data as ParentDataDef).actions = findAndDelete(
            (node.data as ParentDataDef).actions
          );
        }

        if (node.uuid === uuid) {
          return [];
        } else {
          return node;
        }
      });
    };

    const updatedWorkflow = {
      ...workflow,
      flow: {steps: findAndDelete(workflow.flow.steps)},
    };
    if (previousUUID) {
      clearPreviousMapping(previousUUID, updatedWorkflow.flow.steps);
    }
    saveWorkflow(updatedWorkflow);
    updateNodeDataTypes(
      updatedWorkflow.flow.steps,
      cachedNodeTypes,
      getAccessTokenSilently
    );
  };

  const clearWorkflow = () => {
    setNodeResults(new Map());
    setEndResult(null);
    const clearedWorkflow = {...workflow};
    clearedWorkflow.flow.steps = clearedWorkflow.flow.steps.map(node => {
      return {...node, lastRun: undefined};
    });
    setWorkflow(clearedWorkflow);
  };

  const cancelWorkflow = () => {
    setIsRunning(false);
    setCurrentNodeRunning(null);
    cancelExecution();
    // Currently only cancels listening for updates, does not actually
    // cancel the workflow run
    cancelListener.next(true);
  };

  const saveWorkflow = (updated: UserWorkflow) => {
    // Dont try to save examples
    if (!loadExample) {
      setWorkflow(updated);
      return client?.workflow.update(updated.uuid, updated).then(response => {
        if (response.result) {
          setWorkflow(response.result.workflow);
        }
      });
    }
  };

  const onAddSourceNode = (
    dataType: DataNodeType,
    contentType: JSONContentType | null,
    triggerType: WorkflowTriggerType
  ) => {
    addInputNodeModal.current?.close();

    const triggerDefinition = getTriggerDefinition(triggerType);
    const trigger: WorkflowTrigger = triggerDefinition.defaultTriggerConfig;

    const definition = getNodeDefinition(NodeType.DataSource, dataType);
    const step = definition.createStep();
    const inputNode = step.data as DataNode;
    if (
      (inputNode.type === 'json' || inputNode.type === 'jsonList') &&
      contentType
    ) {
      inputNode.data.content_type = contentType;
    }

    saveWorkflow({
      ...workflow,
      on: trigger,
      input: inputNode,
    });
  };

  const onAddNode = (nodeType: NodeType, subType: DataNodeType | null) => {
    const definition = getNodeDefinition(nodeType, subType);

    const newNode = definition?.createStep() as NodeDef;

    if (newNode) {
      const newWorkflow = {...workflow};
      newWorkflow.flow.steps = [...workflow.flow.steps, newNode];
      saveWorkflow(newWorkflow);
      updateNodeDataTypes(
        newWorkflow.flow.steps,
        cachedNodeTypes,
        getAccessTokenSilently
      );
    }
  };

  const nodeDropped = (after: boolean, dropUUID: string) => {
    if (!draggedNode) {
      return;
    }

    const newWorkflow = {...workflow};
    const dragNode = removeNode(newWorkflow.flow.steps, draggedNode);
    if (dragNode) {
      insertNode(newWorkflow.flow.steps, after, dropUUID, dragNode);
    }

    setValidationResult(undefined);
    setDragOverUuid(null);
    setDraggedNode(null);
    setNodeResults(new Map());
    setEndResult(null);
    updateNodeDataTypes(
      newWorkflow.flow.steps,
      cachedNodeTypes,
      getAccessTokenSilently
    );
    newWorkflow.flow.steps = newWorkflow.flow.steps.map(node => {
      return {...node, lastRun: undefined};
    });
    saveWorkflow(newWorkflow);
  };

  const isValidDropSpot = (dropAfter: boolean, spotUUID: string) => {
    if (!draggedNode) {
      return false;
    }

    return (
      spotUUID === dragOverUuid &&
      dropAfter === dragNDropAfter &&
      spotUUID !== draggedNode &&
      (!dropAfter ||
        (dropAfter &&
          !nodeComesAfter(workflow.flow.steps, spotUUID, draggedNode)))
    );
  };

  const configureMappings = (inputNode: NodeDef, outputNode: NodeDef) => {
    setInputNode(inputNode);
    setOutputNode(outputNode);
    if (configureMappingModal.current) {
      configureMappingModal.current.showModal();
    }
  };

  const updateWorkflowState = (currentState: boolean) => {
    if (client && !loadExample) {
      client
        .updateWorkflowSate(
          workflowUuid,
          currentState ? UserWorkflowState.Disabled : UserWorkflowState.Active
        )
        .then(response => {
          if (response?.result) {
            setWorkflowEnabled(
              response.result.workflowState === UserWorkflowState.Active
            );
          }
        });
    }
  };

  return (
    <WorkflowContext.Provider value={workflowContextData}>
      <div className="flex min-h-screen flex-col gap-8 items-center w-full">
        <div className="navbar sm:w-full md:w-[640px] mx-auto lg:fixed bg-black p-4 rounded-lg z-10 shadow-lg">
          <div className="navbar-center flex flex-col items-center w-full gap-4">
            <div className="text-2xl">
              {isLoadingWorkflow ? (
                <div>
                  <ArrowPathIcon className="w-6 animate-spin" />
                </div>
              ) : (
                <EditableText
                  className="text-2xl"
                  data={workflow.name ?? ''}
                  onChange={newValue => {
                    const updated = {...workflow, name: newValue};
                    saveWorkflow(updated);
                  }}
                />
              )}
            </div>
            <div className="flex flex-row items-center">
              {loadExample ? (
                <button
                  className="btn btn-primary"
                  disabled={isRunning || isLoadingWorkflow}
                  onClick={() => saveAs()}
                >
                  <CloudArrowUpIcon className="w-4" />
                  Save To My Workflows
                </button>
              ) : (
                <>
                  <div className="flex flex-row gap-2">
                    {isRunning ? (
                      <button
                        className="btn btn-error"
                        onClick={() => cancelWorkflow()}
                      >
                        <StopIcon className="w-4" />
                        Stop
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        disabled={isLoadingWorkflow}
                        onClick={() => handleRunWorkflow()}
                      >
                        <PlayIcon className="w-4" />
                        Run
                      </button>
                    )}
                    <button
                      className="btn btn-neutral"
                      disabled={isRunning}
                      onClick={() => clearWorkflow()}
                    >
                      🧹 Clear
                    </button>
                  </div>
                  <div className="divider divider-horizontal my-0"></div>

                  <div className="join gap-2">
                    <div className="join-item font-bold text-neutral-500">
                      Auto-Trigger:
                    </div>
                    <div className="join-item">OFF</div>
                    <div
                      className="tooltip"
                      data-tip={
                        workflowEnabled
                          ? 'Deactivate Workflow'
                          : 'Activate Workflow'
                      }
                    >
                      <input
                        type="checkbox"
                        className="toggle toggle-primary"
                        checked={workflowEnabled}
                        onChange={() => {
                          updateWorkflowState(workflowEnabled);
                        }}
                      />
                    </div>
                    <div className="join-item">ON</div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full lg:mt-40 mb-32">
          <div className="items-center flex flex-col gap-4 z-0 mb-4">
            {workflow.on ? (
              <TriggerConfig
                trigger={workflow.on}
                onUpdate={updates => updateInputDef(updates)}
                onRequestChange={() => addInputNodeModal.current?.showModal()}
              />
            ) : null}
            {workflow.input ? (
              <>
                <InputNodeComponent
                  workflowInput={workflow.input}
                  currentNodeRunning={currentNodeRunning}
                  nodeResults={nodeResults}
                  onDelete={() => {
                    const updated = {...workflow};
                    updated.input = undefined;
                    updated.on = undefined;
                    return saveWorkflow(updated);
                  }}
                  onUpdate={updates => updateInputDef(updates)}
                />
                <ArrowDownIcon className="w-4 mx-auto" />
              </>
            ) : (
              <button
                className="btn"
                onClick={() => addInputNodeModal.current?.showModal()}
              >
                <PlusCircleIcon className="w-8 h-auto" />
                Add Source
              </button>
            )}
            {workflow.flow.steps.length > 0 ? (
              <DropArea
                uuid={workflow.flow.steps[0].uuid}
                dropAfter={false}
                isValidDropSpot={isValidDropSpot}
                setDragOverUuid={setDragOverUuid}
                setDragNDropAfter={setDragNDropAfter}
                nodeDropped={nodeDropped}
              ></DropArea>
            ) : null}
          </div>
          <div className="items-center flex flex-col gap-4 z-0">
            {workflow.flow.steps.map((node, idx) => {
              return (
                <div key={`node-${idx}`} className="flex flex-col gap-4 w-full">
                  <NodeComponent
                    {...node}
                    currentNodeRunning={currentNodeRunning}
                    workflowValidation={validationResult}
                    lastRun={nodeResults.get(node.uuid)}
                    nodeState={nodeStates.get(node.uuid)}
                    onStateChange={state => updateNodeState(node.uuid, state)}
                    onDelete={() => deleteWorkflowNode(node.uuid)}
                    onUpdate={updates => updateWorkflowDef(node.uuid, updates)}
                    onDragUpdate={uuid => setDraggedNode(uuid)}
                  />
                  <DropArea
                    uuid={node.uuid}
                    dropAfter={true}
                    isValidDropSpot={isValidDropSpot}
                    setDragOverUuid={setDragOverUuid}
                    setDragNDropAfter={setDragNDropAfter}
                    nodeDropped={nodeDropped}
                  >
                    <NodeDivider
                      steps={workflow.flow.steps}
                      childNode={node}
                      dataTypes={workflowDataTypes}
                      canConfigureMappings={canConfigureMappings}
                      configureMappings={configureMappings}
                      currentIndex={idx}
                      nodeResults={nodeResults}
                    ></NodeDivider>
                  </DropArea>
                </div>
              );
            })}
            {workflow.input ? (
              <button
                className="btn"
                onClick={() => addNodeModal.current?.showModal()}
              >
                <PlusCircleIcon className="w-8 h-auto" />
                Add Action
              </button>
            ) : null}
          </div>
          {endResult ? (
            <div className="flex flex-col gap-4 items-center mt-4">
              <ArrowDownIcon className="w-4 mx-auto" />
              <WorkflowResult
                result={endResult}
                className="bg-info text-info-content"
              />
            </div>
          ) : null}
        </div>
        <AddNodeModal
          modalRef={addNodeModal}
          lastNode={
            workflow.flow.steps.length > 0
              ? workflow.flow.steps[workflow.flow.steps.length]
              : null
          }
          onClick={onAddNode}
          inLoop={false}
        />
        <AddSourceModal
          modalRef={addInputNodeModal}
          onClick={onAddSourceNode}
        />
        <ConfigureMappingModal
          modalRef={configureMappingModal}
          dataTypes={workflowDataTypes}
          fromNode={inputNode}
          toNode={outputNode}
          updateWorkflow={updateWorkflowDef}
        />
      </div>
    </WorkflowContext.Provider>
  );
}

export async function loadWorkflowFromFile(
  fileInput: HTMLInputElement
): Promise<UserWorkflow> {
  if (fileInput.files && fileInput.files.length > 0) {
    const file = fileInput.files[0];
    fileInput.value = '';
    const jsonObject = JSON.parse(await file.text());

    if (jsonObject.name) {
      return jsonObject as UserWorkflow;
    } else {
      return {
        uuid: file.name,
        name: file.name,
        flow: {
          steps: jsonObject,
        },
      };
    }
  }

  return EMPTY_WORKFLOW;
}

export function exportFullWorkflow(workflow: UserWorkflow) {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(
    new Blob([JSON.stringify(workflow, null, 2)], {type: 'text/plain'})
  );
  a.setAttribute('download', 'workflow.json');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function clearPreviousMapping(uuid: string, workflow: NodeDef[]) {
  const node = workflow.find(node => node.uuid === uuid);
  if (node) {
    node.mapping = [];
  }
}
