import {RefObject, useState} from 'react';

export interface InputSelectionProps {
  inputs: MediaDeviceInfo[];
  selectedInput?: MediaDeviceInfo;
  setSelectedInput(info: MediaDeviceInfo): void;
  dialogRef: RefObject<HTMLDialogElement>;
}

export interface InputLabel {
  name: string;
  details?: string;
}

export function InputSelection({
  inputs,
  selectedInput,
  setSelectedInput,
  dialogRef,
}: InputSelectionProps) {
  const [updatedInput, setUpdatedInput] = useState<MediaDeviceInfo | null>(
    null
  );

  const getSelectedLabel = (rawLabel: string | undefined): InputLabel => {
    if (!rawLabel) {
      return {
        name: 'Default',
      };
    }
    const labelList = rawLabel.split('(');

    if (labelList.length > 1) {
      const details = labelList[1].substring(0, labelList[1].length - 1).trim();
      return {
        name: labelList[0],
        details: details,
      };
    }

    return {
      name: labelList[0],
    };
  };

  return (
    <dialog ref={dialogRef} id="inputSelectionDialog" className="modal">
      <form method="dialog" className="modal-box">
        <div>
          {inputs.map(input => {
            const label = getSelectedLabel(input.label);
            return (
              <div className="form-control" key={input.deviceId}>
                <label className="label cursor-pointer">
                  <div className="label-text flex flex-col">
                    {label.name}
                    <span className="text-xs">{label.details}</span>
                  </div>

                  <input
                    type="radio"
                    name="deviceInput"
                    className="radio checked:bg-red-500"
                    onChange={() => {
                      setUpdatedInput(input);
                    }}
                    checked={
                      (selectedInput?.deviceId === input.deviceId &&
                        !updatedInput) ||
                      (updatedInput !== null &&
                        updatedInput.deviceId === input.deviceId)
                    }
                  ></input>
                </label>
              </div>
            );
          })}
        </div>
        <div className="flex flex-row gap-2 justify-end">
          <button
            className="btn btn-primary"
            disabled={!updatedInput}
            onClick={() => {
              if (updatedInput) {
                setUpdatedInput(null);
                setSelectedInput(updatedInput);
                dialogRef.current?.close();
              }
            }}
          >
            Ok
          </button>
          <button
            className="btn btn-error"
            onClick={() => {
              setUpdatedInput(null);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </dialog>
  );
}
