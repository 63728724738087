import {DataNode} from '../../../../bindings/DataNode';
import {ConnectionData} from '../../../../bindings/ConnectionData';
import {WorkflowTrigger} from '../../../../bindings/WorkflowTrigger';
import {EmailTrigger} from '../../../../bindings/EmailTrigger';
import {JSONContentType} from '../../../../bindings/JSONContentType';
import {StepInputSource} from '../../../../bindings/StepInputSource';
import {ExtractNode} from '../../../../bindings/ExtractNode';
import {AudioTranscriptionNode} from '../../../../bindings/AudioTranscriptionNode';
import {AssistantNode} from '../../../../bindings/AssistantNode';

export enum NodeType {
  // Take a generated output and save it somewhere
  DataDestination = 'DataDestination',
  // Data that is being pulled from:
  // - An integration
  // - A URL
  // - A file
  // - or just a static text blob.
  DataSource = 'DataSource',
  // Extract node & extract node variants
  Extract = 'Extract',
  ExtractEmailResponse = 'ExtractEmailResponse',
  ExtractTasksAndDecisions = 'ExtractTasksAndDecisions',
  // --------------------
  Template = 'Template',
  Summarize = 'Summarize',
  Loop = 'Loop',
  BuildVtt = 'BuildVTT',
  AudioTranscription = 'AudioTranscription',
  PullDocument = 'PullDocuments',
  SendEmail = 'SendEmail',
  SaveToCollection = 'SaveToCollection',
  MicrosoftTodo = 'MicrosoftTodo',
  Ocr = 'Ocr',
  AggregateOutput = 'AggregateOutput',
  Assistant = 'Assistant',
}

export enum TaskResultNodeType {
  DataNode = 'DataNode',
}

export interface SaveToCollectionNode {
  collection: string;
  name: string;
  metadata?: any;
  useInputCollection: boolean;
}

export interface SendEmailNode {
  email: SendEmailNodeOptions;
  subject?: string;
  body?: string;
  reply?: boolean;
  attachDocuments: boolean;
  bodyContentType?: EmailContentType;
}

export enum EmailContentType {
  TEXT = 'text/plain',
  HTML = 'text/html',
}

export interface SendEmailNodeOptions {
  to: string[];
  cc?: string[];
  bcc?: string[];
}

export interface AudioDataSource {
  // new to make
  data: URLDataSource | SourceDocumentConfig | SourceCollectionConfig;
  sourceType: AudioDataSourceType;
}

export enum AudioDataSourceType {
  S3 = 'S3',
  URL = 'URL',
  CollectionDocument = 'CollectionDocument',
  Collection = 'Collection',
}

export interface SourceCollectionConfig {
  collection: string;
}

export interface SourceDocumentConfig {
  collection: string;
  document: string;
}

export interface URLDataSource {
  url: string;
  connection_id?: string;
}

export interface DataNodeDefData {
  content?: any;
  url?: string;
  file?: File;
  connectionData?: ConnectionData;
  contentType?: JSONContentType;
  collection?: string;
  document?: string;
  items?: any[];
}

export enum DataNodeType {
  Collection = 'collection',
  CollectionDocument = 'document',
  CollectionDocumentList = 'documentList',
  JsonList = 'jsonList',
  TextList = 'textList',
  Connection = 'connection',
  Json = 'json',
  Rss = 'rssFeed',
  Text = 'text',
  Url = 'url',
  EmailContent = 'emailContent',
  Outlook365Content = 'outlook365Content',
  RingCentralCall = 'ringCentralContent',
}

export enum DataConnectionType {
  GSheets = 'GSheets',
  GDocs = 'GDocs',
  GSlides = 'GSlides',
  GForms = 'GForms',
  Hubspot = 'Hubspot',
  MicrosoftTasks = 'MicrosoftTasks',
  MicrosoftOutlook = 'MicrosoftOutlook',
}

export enum HubSpotActions {
  GetOne = 'singleObject',
  GetRelatedObjects = 'relatedObjects',
  GetAll = 'all',
  GetMatching = 'matching',
}

export interface TemplateNodeDef {
  template: string;
  varMapping: object;
}

export interface SummaryDataDef {
  summary: string;
  bulletSummary: string;
}

export interface SummaryResponse {
  paragraph: string;
  bullets: string;
}

export interface TranscriptionResult {
  transcription: TranscriptionResponse;
  vtt: string;
  documentUuid?: string;
}

export interface CollectionResult {
  collection: string;
}

export interface CollectionDocumentResult {
  collection: string;
  document: string;
}

export interface TranscriptionResponse {
  status: string;
  utterances: Utterance[];
  error: string;
}

export interface Utterance {
  speaker: string;
  text: string;
}

export interface ParentDataDef {
  actions: NodeDef[];
}

export interface BuildVTTNodeDef {
  config?: string;
}

export interface BuildVTTResult {
  vtt: string;
  documentUuid?: string;
}

export interface SightglassEmailTriggerResult {
  emailData: object;
}

export interface PullDocumentNodeDef {
  data?: any;
}

export interface TriggerNodeUpdate {
  trigger: EmailTrigger;
  input: DataNode;
}

export type NodeDataTypes =
  | ExtractNode
  | DataNodeDefData
  | string
  | TemplateNodeDef
  | SummaryDataDef
  | ParentDataDef
  | DataDestinationDef
  | AudioTranscriptionNode
  | BuildVTTNodeDef
  | TriggerNodeUpdate
  | PullDocumentNodeDef
  | SendEmailNode
  | SaveToCollectionNode
  | AssistantNode;

export type NodeDataResultTypes =
  | StringContentResult
  | LoopNodeDataResult
  | ExtractResponse
  | MultiNodeDataResult
  | SummaryDataDef
  | ObjectResult
  | TranscriptionResult
  | CollectionResult
  | CollectionDocumentResult
  | BuildVTTResult
  | SightglassEmailTriggerResult
  | NodeResultType
  | any[];

export interface NodeResultType {
  nodeType: TaskResultNodeType;
  data: any;
}

export enum OutputDataType {
  StringContent = 'StringContent',
  LoopResult = 'LoopResult',
  ExtractResult = 'ExtractResult',
  MultiNodeResult = 'MultiNodeResult',
  SummaryResult = 'SummaryResult',
  TableResult = 'TableResult',
  CollectionId = 'CollectionId',
  CollectionDocumentId = 'CollectionDocumentId',
  SightglassEmailTrigger = 'SightglassEmailTrigger',
}

export enum InputDataType {
  StringContent = 'StringContent',
  Iterable = 'Iterable',
  Object = 'Object',
  None = 'None',
  Collection = 'Collection',
}

export interface DataDestinationDef {
  [key: string]: any;
}

export type MultiNodeDataResult = NodeResult[];

export interface LoopNodeDataResult {
  loopResults: MultiNodeDataResult[];
}

export interface ExtractResponse {
  extractedData: any;
  schema?: object;
}

export interface StringContentResult {
  text: string;
  type: 'string';
}

export interface ObjectResult {
  [key: string]: any;
}

export interface NodeDef {
  uuid: string;
  label: string;
  nodeType: NodeType;
  data: NodeDataTypes;
  parentNode: boolean;
  templateId?: string;
  mapping?: NodeInputMapping[];
  inputSource?: StepInputSource;
}

export interface NodeInputMapping {
  from: string;
  to?: string;
  skip?: boolean;
  extract?: boolean;
  conversion: NodeInputConversion;
}

export interface NodeInputConversion {
  type: string;
}

export interface StringToListConversion extends NodeInputConversion {
  type: 'StringToListConversion';
  delimiter: string;
}

export interface RenameInput extends NodeInputConversion {
  type: 'Rename';
}

export interface NodeUpdates {
  label?: string;
  data?: NodeDataTypes;
  trigger?: WorkflowTrigger;
  mapping?: NodeInputMapping[];
  inputSource?: StepInputSource;
}

export interface LastRunDetails {
  startTimestamp: Date;
  endTimestamp: Date;
  nodeResult: NodeResult;
}

export enum NodeResultStatus {
  Ok = 'ok',
  Error = 'error',
}

export interface NodeState {
  expanded: boolean;
}

export interface NodeResult {
  status: NodeResultStatus;
  data?: NodeDataResultTypes;
  error?: string;
}

export interface ObjectTypeDefinition {
  [key: string]: NodePropertyDefinition;
}

export interface NodePropertyDefinition {
  type: PropertyType;
  properties?: ObjectTypeDefinition;
  enum?: string[];
  items?: NodePropertyDefinition;
}

export enum PropertyType {
  Array = 'array',
  Object = 'object',
  Number = 'number',
  String = 'string',
  Enum = 'enum',
  None = 'none',
  Boolean = 'boolean',
}

export interface SummarizeRequest {
  systemOverride?: string;
  summaryType?: SummarizeRequestSummaryType;
  fastSummary?: boolean;
  text?: string;
  document?: string;
}

export enum SummarizeRequestSummaryType {
  BulletPoint = 'BulletPoint',
  Paragraph = 'Paragraph',
}

export interface SingleQuestionRequest {
  textSource: TextSource;
  query: string;
  jsonSample?: any;
  jsonSchema?: any;
}

export interface TextSource {
  sourceType: TextSourceType;
  data: string;
}

export enum TextSourceType {
  Text = 'text',
  Document = 'document',
}
