export interface PaginatorProps {
  currentPage: number;
  numPages: number;
  preventDefault?: boolean;
  onSelectPage?: (page: number) => void;
}

export function Paginator({
  currentPage,
  numPages,
  preventDefault,
  onSelectPage = () => {},
}: PaginatorProps) {
  const page_items = [];
  const pages_begin = Math.max(currentPage - 5, 0);
  const pages_end = Math.min(currentPage + 5, numPages + 1);

  if (pages_begin > 0) {
    page_items.push(
      <button
        key="0"
        className="join-item btn"
        onClick={() => checkPageSelect(0)}
      >
        1
      </button>
    );
  }

  if (pages_begin > 10) {
    page_items.push(
      <button key="ellipsis" className="join-item btn btn-disabled">
        ...
      </button>
    );
  }

  const checkPageSelect = (page: number) => {
    if (page >= 0 && page <= numPages && page !== currentPage) {
      onSelectPage(page);
    }
  };

  for (let i = pages_begin; i < pages_end; i++) {
    const classNames = ['join-item', 'btn'];
    if (currentPage === i) {
      classNames.push('btn-accent');
    }

    page_items.push(
      <button
        key={i}
        className={classNames.join(' ')}
        onClick={event => {
          if (preventDefault) {
            event.preventDefault();
          }
          checkPageSelect(i);
        }}
      >
        {i + 1}
      </button>
    );
  }

  if (pages_end < numPages) {
    page_items.push(
      <button key="start" className="join-item btn btn-disabled">
        ...
      </button>
    );
    page_items.push(
      <button
        key={`${numPages}`}
        className="join-item btn"
        onClick={event => {
          if (preventDefault) {
            event.preventDefault();
          }
          checkPageSelect(numPages);
        }}
      >
        {numPages + 1}
      </button>
    );
  }

  return (
    <div className="join place-content-center w-full">
      {currentPage > 0 ? (
        <button
          key="previous"
          className="join-item btn"
          onClick={event => {
            if (preventDefault) {
              event.preventDefault();
            }
            checkPageSelect(currentPage - 1);
          }}
        >
          «
        </button>
      ) : (
        <></>
      )}
      {page_items}
      {currentPage < numPages ? (
        <button
          key="next"
          className="join-item btn"
          onClick={event => {
            if (preventDefault) {
              event.preventDefault();
            }
            checkPageSelect(currentPage + 1);
          }}
        >
          »
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
