import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context';
import {CollectionListing} from '../../../bindings/api/CollectionListing';

export interface CollectionSelectorProps {
  selectedCollection?: string;
  onSelect: (collectionUuid: string) => void;
  showTitle?: boolean;
}

export function CollectionSelector({
  selectedCollection,
  onSelect,
  showTitle = true,
}: CollectionSelectorProps) {
  const {client} = useContext(UserContext);
  const [collections, setCollections] = useState<CollectionListing[]>([]);
  const [loadingCollections, setLoadingCollections] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('select');

  useEffect(() => {
    setLoadingCollections(true);
    client?.collection
      .list()
      .then(result => {
        if (result.result) {
          setCollections(result.result);
        }
      })
      .catch(err => {
        console.error('Error accessing collections', err);
      })
      .finally(() => {
        setLoadingCollections(false);
      });
  }, [client]);

  useEffect(() => {
    if (selectedCollection) {
      setSelected(selectedCollection);
    }
  }, [selectedCollection]);

  return (
    <div className="flex flex-row gap-2 items-center">
      {showTitle ? <span className="font-semibold">Collection</span> : null}
      <select
        value={selected}
        defaultValue="select"
        className="select select-bordered w-full max-w-xs"
        onChange={event => {
          onSelect(event.target.value);
        }}
      >
        <option key="input-collection-header" value="select" disabled>
          Select Collection
        </option>
        {collections.map(collection => {
          return (
            <option
              key={`input-collection-${collection.uuid}`}
              value={collection.uuid}
            >
              {collection.name}
            </option>
          );
        })}
      </select>
      {loadingCollections ? <span className="loading loading-spinner" /> : null}
    </div>
  );
}
