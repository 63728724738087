import {AxiosProgressEvent} from 'axios';
import {useEffect, useState} from 'react';

export interface UploadProgressProps {
  progress?: AxiosProgressEvent;
}

export function UploadProgress({progress}: UploadProgressProps) {
  const [progressValue, setProgressValue] = useState<number>(0);
  const [progressBytes, setProgressBytes] = useState<string>();

  useEffect(() => {
    if (progress) {
      if (progress.progress) {
        setProgressValue(progress.progress * 100);
      } else {
        setProgressValue(0);
      }

      if (progress.total) {
        let suffix;
        let divisor;
        if (progress.total < 100000) {
          (suffix = 'KB'), (divisor = 1000);
        } else {
          (suffix = 'MB'), (divisor = 1000 * 1000);
        }
        const loaded = (progress.loaded / divisor).toFixed(2);
        const total = (progress.total / divisor).toFixed(2);
        setProgressBytes(`${loaded} ${suffix} / ${total} ${suffix}`);
      }
    } else {
      setProgressValue(0);
      setProgressBytes(undefined);
    }
  }, [progress]);

  return (
    <div className="items-center justify-end gap-2">
      <progress
        className="progress progress-primary w-32"
        value={progressValue}
        max="105"
      ></progress>
      <span className="text-sm ml-2">{progressBytes}</span>
    </div>
  );
}
