import {WaveFile} from 'wavefile';

class BufferSupport {
  private offset: number;
  constructor(private buffer: Uint8Array) {
    this.offset = 0;
  }

  writeString(str: String) {
    for (let i = 0; i < str.length; ++i) {
      this.buffer[this.offset] = str.charCodeAt(i);
      this.offset += 1;
    }
  }

  writeInt16LE(int: number) {
    int = Math.floor(int);
    this.buffer[this.offset] = int & 0xff;
    this.buffer[this.offset + 1] = (int >> 8) & 0xff;
    this.offset += 2;
  }

  writeInt16(int: number) {
    int = Math.floor(int);
    this.buffer[this.offset] = (int >> 8) & 0xff;
    this.buffer[this.offset + 1] = int & 0xff;
    this.offset += 2;
  }

  writeInt32LE(int: number) {
    int = Math.floor(int);
    this.buffer[this.offset] = int & 255;
    this.buffer[this.offset + 1] = (int >> 8) & 255;
    this.buffer[this.offset + 2] = (int >> 16) & 255;
    this.buffer[this.offset + 3] = (int >> 24) & 255;
    this.offset += 4;
  }
}

// Return the bits of the float as a 32-bit integer value.  This
// produces the raw bits; no intepretation of the value is done.
function floatBits(float: number): number {
  const buf = new ArrayBuffer(4);
  new Float32Array(buf)[0] = float;
  const bits = new Uint32Array(buf)[0];
  // Return as a signed integer.
  return bits | 0;
}

function writeAudioBufferToArray(
  buffer: BufferSupport,
  audioBuffer: Uint16Array
) {
  const len = audioBuffer.length;
  for (let i = 0; i < len; i++) {
    buffer.writeInt16LE(audioBuffer[i]);
  }
}

export function i16PCMToLittleEndian(dataArray: ArrayBufferLike): Uint8Array {
  const audioData = new Uint16Array(dataArray);

  const wavDataByteLength = audioData.length * 2;
  const totalLength = wavDataByteLength;
  const waveAudioOnly = new Uint8Array(totalLength);
  const buffer = new BufferSupport(waveAudioOnly);

  writeAudioBufferToArray(buffer, audioData);

  return waveAudioOnly;
}

export function getWaveFileData(
  dataArray: ArrayBufferLike,
  use32BitFloat: boolean
): Uint8Array {
  const audioData = new Uint16Array(dataArray);
  // // Encoding setup.
  const numberOfChannels = 1;
  const sampleRate = 16000;
  const bitsPerSample = use32BitFloat ? 32 : 16;
  const byteRate = (sampleRate * numberOfChannels * bitsPerSample) / 8;
  const blockAlign = (numberOfChannels * bitsPerSample) / 8;
  const wavDataByteLength = audioData.length * 2;
  const headerByteLength = 44;
  const totalLength = headerByteLength + wavDataByteLength;
  const waveFileData = new Uint8Array(totalLength);
  const buffer = new BufferSupport(waveFileData);

  const subChunk1Size = 16;
  const subChunk2Size = wavDataByteLength;
  const chunkSize = 4 + (8 + subChunk1Size) + (8 + subChunk2Size);

  buffer.writeString('RIFF');
  buffer.writeInt32LE(chunkSize);
  buffer.writeString('WAVE');
  buffer.writeString('fmt ');

  // SubChunk1Size (4)
  buffer.writeInt32LE(subChunk1Size);
  // AudioFormat (2): 3 means 32-bit float, 1 means integer PCM.
  buffer.writeInt16LE(use32BitFloat ? 3 : 1);
  // NumChannels (2)
  buffer.writeInt16LE(numberOfChannels);
  // SampleRate (4)
  buffer.writeInt32LE(sampleRate);
  // ByteRate (4)
  buffer.writeInt32LE(byteRate);
  // BlockAlign (2)
  buffer.writeInt16LE(blockAlign);
  // BitsPerSample (4)
  buffer.writeInt16LE(bitsPerSample);
  buffer.writeString('data');
  // SubChunk2Size (4)
  buffer.writeInt32LE(subChunk2Size);

  writeAudioBufferToArray(buffer, audioData);

  return waveFileData;
}

export function rawAudioToWav(audio: Float32Array[]): WaveFile {
  const wav = new WaveFile();
  wav.fromScratch(audio.length, 48000, '32f', audio);
  wav.toBitDepth('16');
  return wav;
}
