import {useContext, useEffect, useState} from 'react';
import {EnvelopeIcon} from '@heroicons/react/20/solid';
import {WorkflowContext} from '../../../../context';
import {WorkflowTrigger} from '../../../../../bindings/WorkflowTrigger';
import {TriggerDefinition, TriggerProps} from '../../nodes';

export class EmailTriggerDefinition implements TriggerDefinition {
  public defaultTriggerConfig: WorkflowTrigger = {
    collection: null,
    email: {
      documentOnly: false,
      exampleData: null,
      email: null,
    },
    hubspot: null,
    microsoft: null,
    schedule: null,
    ringcentral: null,
  };
  public renderTrigger(baseProps: TriggerProps): JSX.Element {
    return <EmailTriggerConfig {...baseProps}></EmailTriggerConfig>;
  }
}

export const SIGHTGLASS_EMAIL_TRIGGER_DEF = new EmailTriggerDefinition();

export function EmailTriggerConfig({
  config,
  onUpdate = () => {},
}: TriggerProps) {
  const {workflowDef} = useContext(WorkflowContext);
  const [isAttachmentTrigger, setIsAttachmentTrigger] = useState<boolean>(
    config.email?.documentOnly || false
  );
  const triggerEmail = `${workflowDef?.uuid}@docs.sightglass.ai`;

  useEffect(() => {
    if (isAttachmentTrigger !== config.email?.documentOnly) {
      onUpdate({
        trigger: {
          email: {
            ...config.email,
            documentOnly: isAttachmentTrigger,
          },
        } as WorkflowTrigger,
      });
    }
  }, [isAttachmentTrigger, config, onUpdate]);

  return (
    <div className="flex flex-col gap-4">
      <div>
        <label className="input input-bordered flex items-center gap-2 mb-2">
          <EnvelopeIcon className="w-4 h-4 opacity-70" />
          <input
            type="text"
            className="grow bg-inherit"
            value={triggerEmail}
            readOnly
          />
        </label>
        <div className="text-xs text-neutral-500">
          Send emails to this address to trigger a workflow
        </div>
      </div>
      <div className="form-control">
        <label className="cursor-pointer label">
          <span className="label-text text-base">
            Trigger on attachments only
          </span>
          <div className="join gap-2">
            <div className="uppercase">off</div>
            <input
              type="checkbox"
              className="toggle toggle-success"
              checked={isAttachmentTrigger}
              onChange={() => setIsAttachmentTrigger(status => !status)}
            />
            <div className="uppercase">on</div>
          </div>
        </label>
        <div className="text-xs text-neutral-500">
          If enabled, it ignores emails that don't have an attachment.
        </div>
      </div>
    </div>
  );
}
