import {WorkflowTrigger} from '../../../../bindings/WorkflowTrigger';
import {
  TriggerDefinition,
  getTriggerDefinition,
  getTriggerType,
} from '../nodes';
import {NodeUpdates} from '../types/node';

interface TriggerConfigProps {
  trigger: WorkflowTrigger;
  onUpdate?: (update: NodeUpdates) => void;
  onRequestChange?: () => void;
}

export class ManualTriggerDefinition implements TriggerDefinition {
  defaultTriggerConfig = {
    collection: null,
    email: null,
    hubspot: null,
    microsoft: null,
    schedule: null,
    ringcentral: null,
  };
  renderTrigger(): JSX.Element {
    return <span>Manually run by user</span>;
  }
}
export const MANUAL_TRIGGER_DEF = new ManualTriggerDefinition();

export function TriggerConfig({
  trigger,
  onUpdate = () => {},
  onRequestChange = () => {},
}: TriggerConfigProps) {
  let body = null;
  const triggerDefinition = getTriggerDefinition(getTriggerType(trigger));
  body = triggerDefinition.renderTrigger({config: trigger, onUpdate});

  return (
    <div className="card bg-base-300 shadow-xl w-full">
      <div className="card-body">
        <div className="flex flex-row justify-between items-center">
          <div className="text-xl font-semibold">Trigger Configuration</div>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => onRequestChange()}
          >
            Change
          </button>
        </div>
        <hr className="border-neutral my-2" />
        {body}
      </div>
    </div>
  );
}
