import {useEffect, useState} from 'react';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {
  Bars3BottomLeftIcon,
  CheckIcon,
  PencilSquareIcon,
} from '@heroicons/react/20/solid';
import {Step} from '../../../../../bindings/Step';

import JsonView from '@uiw/react-json-view';
import {githubDarkTheme} from '@uiw/react-json-view/githubDark';

export class JsonNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'JSON Source';
  public nodeLabel = 'JSON Input';
  public getNodeIcon(className?: string): JSX.Element {
    return <Bars3BottomLeftIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <JsonDataNode {...baseProps}></JsonDataNode>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'DataSource',
      templateId: null,
      data: {
        type: 'json',
        data: {
          content: {},
          content_type: null,
        },
      },
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const JSON_NODE_DEF = new JsonNodeDefinition();

export function JsonDataNode({data, onUpdateData = () => {}}: NodeBodyProps) {
  const [dataValue, setDataValue] = useState<any>({});
  const [tempValue, setTempValue] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    const content = data as any;
    setDataValue(content.content);
  }, [data]);

  const updateData = (newValue: any) => {
    onUpdateData({
      content: newValue,
      contentType: (data as any).contentType,
    });
  };

  const saveEdit = () => {
    try {
      const val = JSON.parse(tempValue);
      updateData(val);
      setError(null);
    } catch (err) {
      setError('Invalid Json ' + err);
    }

    setIsEditing(false);
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        <div className="flex justify-end py-2 w-full">
          {isEditing ? (
            <button
              className="btn btn-xs btn-success"
              onClick={() => saveEdit()}
            >
              <CheckIcon className="w-4" />
              save
            </button>
          ) : (
            <button
              className="btn btn-xs"
              onClick={() => {
                setIsEditing(true);
                setTempValue(JSON.stringify(dataValue, undefined, 2));
              }}
            >
              <PencilSquareIcon className="w-4" />
              edit
            </button>
          )}
        </div>
        {isEditing ? (
          <textarea
            className="textarea textarea-primary w-full h-48"
            defaultValue={JSON.stringify(dataValue, undefined, 2)}
            onChange={event => {
              setTempValue(event.target.value);
            }}
          ></textarea>
        ) : (
          <JsonView value={dataValue} style={githubDarkTheme} collapsed={1} />
        )}
      </div>
      <span className="text-error text-xs">{error}</span>
    </div>
  );
}
