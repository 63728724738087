import axios, {AxiosRequestConfig} from 'axios';
import {
  ApiError,
  ApiResponse,
  FetchResponse,
  ParseResponse,
  TaskResponse,
} from '../types/spyglassApi';
import {
  AudioDataSourceType,
  BuildVTTNodeDef,
  DataConnectionType,
  NodeResult,
  NodeResultStatus,
  ObjectResult,
  SourceCollectionConfig,
  SourceDocumentConfig,
  StringContentResult,
  SummarizeRequest,
  TranscriptionResult,
} from '../types/node';
import {
  interval,
  mergeMap,
  from,
  lastValueFrom,
  tap,
  Observable,
  of,
  takeUntil,
  Subject,
  merge,
} from 'rxjs';
import {WorkflowContext} from './workflowinstance';
import {
  getValue,
  isCollectionDocumentResult,
  isCollectionResult,
} from '../types/typeutils';
import {
  CollectionTaskDefinition,
  CollectionTaskType,
} from '../../../api/Client';
import {ConnectionData} from '../../../../bindings/ConnectionData';
import {AudioTranscriptionNode} from '../../../../bindings/AudioTranscriptionNode';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const API_TOKEN = process.env.REACT_APP_API_TOKEN;
const API_CONFIG: AxiosRequestConfig = {
  headers: {Authorization: `Bearer ${API_TOKEN}`},
};

export async function executeConnectionRequest(
  data: ConnectionData,
  request: ObjectResult,
  token?: string
): Promise<NodeResult> {
  if (data.connectionType === DataConnectionType.GSheets) {
    return executeGSheetsConnectionRequest(data, request, token);
  } else if (data.connectionType === DataConnectionType.Hubspot) {
    return executeHubSpotConnectionRequest(data, request, token);
  } else {
    console.error('Unknown connection type ', data.connectionType);
    return {
      status: NodeResultStatus.Error,
      error: 'Unknown connection type',
    };
  }
}

export async function executeHubSpotConnectionRequest(
  data: ConnectionData,
  request: ObjectResult,
  token?: string
): Promise<NodeResult> {
  console.debug('HubSpot request: ', data);
  // Do some light data validation
  if (!data.connectionId) {
    return {
      status: NodeResultStatus.Error,
      error: 'Please choose a valid connection.',
    };
  } else if (data.connectionType === 'Hubspot' && !data.objectType) {
    return {
      status: NodeResultStatus.Error,
      error: 'Please select a HubSpot object type',
    };
  }

  if (
    data.connectionType === 'Hubspot' &&
    (request.HubSpot.action === 'ReadObject' ||
      request.HubSpot.action === 'GetRelated')
  ) {
    if (!data.objectId) {
      return {
        status: NodeResultStatus.Error,
        error: 'Please enter in the HubSpot Record Id',
      };
    }
  }

  if (
    data.connectionType === 'Hubspot' &&
    request.HubSpot.action === 'GetRelated'
  ) {
    if (!data.relatedObjectType) {
      return {
        status: NodeResultStatus.Error,
        error: 'Please select a related HubSpot object type',
      };
    }
  }

  // Setup the data request
  let config: AxiosRequestConfig = {
    ...API_CONFIG,
  };

  if (token) {
    config = {
      headers: {Authorization: `Bearer ${token}`},
    };
  }

  return await axios
    .post<ApiResponse<any>>(
      `${API_ENDPOINT}/connections/${data.connectionId}`,
      request,
      config
    )
    .then(resp => {
      const result = resp.data.result;
      return {
        status: NodeResultStatus.Ok,
        data: result,
      };
    })
    .catch(err => {
      return {
        status: NodeResultStatus.Error,
        error: err.toString(),
      };
    });
}

export async function executeGSheetsConnectionRequest(
  data: ConnectionData,
  request: ObjectResult,
  token?: string
): Promise<NodeResult> {
  console.debug('Google Sheets request: ', data);
  // Do some light data validation
  if (!data.connectionId) {
    return {
      status: NodeResultStatus.Error,
      error: 'Please choose a valid connection.',
    };
  } else if (data.connectionType === 'GSheets' && !data.spreadsheetId) {
    return {
      status: NodeResultStatus.Error,
      error: 'Please set a valid spreadsheet id.',
    };
  }

  // Setup the data request
  let config: AxiosRequestConfig = {
    ...API_CONFIG,
  };

  if (token) {
    config = {
      headers: {Authorization: `Bearer ${token}`},
    };
  }

  return await axios
    .post<ApiResponse<any[]>>(
      `${API_ENDPOINT}/connections/${data.connectionId}`,
      request,
      config
    )
    .then(resp => {
      const result = resp.data.result;
      return {
        status: NodeResultStatus.Ok,
        data: result,
      };
    })
    .catch(err => {
      return {
        status: NodeResultStatus.Error,
        error: err.toString(),
      };
    });
}

export async function executeGSheetsHeaderRequest(
  data: ConnectionData,
  token?: string
): Promise<NodeResult> {
  console.debug('connection request', data);
  // Do some light data validation
  if (!data.connectionId) {
    return {
      status: NodeResultStatus.Error,
      error: 'Please choose a valid connection.',
    };
  } else if (data.connectionType === 'GSheets' && !data.spreadsheetId) {
    return {
      status: NodeResultStatus.Error,
      error: 'Please set a valid spreadsheet id.',
    };
  }

  // Setup the data request
  let config: AxiosRequestConfig = {
    ...API_CONFIG,
  };

  if (token) {
    config = {
      headers: {Authorization: `Bearer ${token}`},
    };
  }

  // todo: refactor to support other integrations
  const request = {
    Sheets: {
      action: 'ReadRows',
      request: {
        spreadsheetId:
          data.connectionType === 'GSheets' ? data.spreadsheetId : '',
        sheetId: data.connectionType === 'GSheets' ? data.sheetId : '',
        range: {
          start: 1,
          numRows: 1,
        },
      },
    },
  };

  return await axios
    .post<ApiResponse<any[]>>(
      `${API_ENDPOINT}/connections/${data.connectionId}`,
      request,
      config
    )
    .then(resp => {
      const result = resp.data.result;
      return {
        status: NodeResultStatus.Ok,
        data: result,
      };
    })
    .catch(err => {
      return {
        status: NodeResultStatus.Error,
        error: err.toString(),
      };
    });
}

export async function executeFetchUrl(
  url: string | undefined
): Promise<NodeResult> {
  if (!url || url.length === 0) {
    return {
      status: NodeResultStatus.Error,
      error: 'No URL inputed',
    };
  } else if (!url.startsWith('http')) {
    return {
      status: NodeResultStatus.Error,
      error: 'Only http and https URLs are supported.',
    };
  }

  console.debug(`fetching url: ${url}`);
  const config: AxiosRequestConfig = {
    params: {
      url,
    },
    ...API_CONFIG,
  };
  return await axios
    .get<ApiResponse<FetchResponse>>(`${API_ENDPOINT}/fetch`, config)
    .then(resp => {
      const {content} = resp.data.result;
      return {
        status: NodeResultStatus.Ok,
        data: {text: content, type: 'string'} as StringContentResult,
      } as NodeResult;
    })
    .catch(err => {
      return {
        status: NodeResultStatus.Error,
        error: err.toString(),
      };
    });
}

export async function executeParseFile(
  file: File | undefined
): Promise<NodeResult> {
  if (!file) {
    return {
      status: NodeResultStatus.Error,
      error: 'No file selected',
    };
  }

  const formData = new FormData();
  formData.append('file', file);
  return await axios
    .post<ApiResponse<ParseResponse>>(
      `${API_ENDPOINT}/fetch/parse`,
      formData,
      API_CONFIG
    )
    .then(resp => {
      const {parsed} = resp.data.result;
      return {
        status: NodeResultStatus.Ok,
        data: {text: parsed, type: 'string'} as StringContentResult,
      } as NodeResult;
    })
    .catch(err => {
      return {
        status: NodeResultStatus.Error,
        error: err.toString(),
      } as NodeResult;
    });
}

export async function executeSummarizeTask(
  input: NodeResult | null,
  controller: AbortController,
  cancelListener: Observable<boolean>,
  executeContext: WorkflowContext
): Promise<NodeResult> {
  const config: AxiosRequestConfig = {
    signal: controller.signal,
    ...API_CONFIG,
  };

  const token = await executeContext.getAuthToken();
  config.headers = {Authorization: `Bearer ${token}`};

  let text;
  let document;
  if (input && input.data) {
    if (isCollectionDocumentResult(input.data)) {
      document = input.data.document;
    } else {
      const rawInput = getValue(input.data);
      if (typeof rawInput === 'string') {
        text = rawInput;
      } else {
        text = JSON.stringify(rawInput);
      }
    }
  }

  const response: ApiResponse<string> | ApiError = await axios
    .post<ApiResponse<string>>(
      `${API_ENDPOINT}/action/summarize/task`,
      {text: text, document: document} as SummarizeRequest,
      config
    )
    .then(resp => resp.data)
    .catch(err => {
      return {
        status: NodeResultStatus.Error,
        error: err.toString(),
      };
    });

  if (response.status.toLowerCase() === 'ok' && 'result' in response) {
    let taskId: any = response.result;
    if (taskId instanceof Object) {
      taskId = taskId.taskId;
    }

    console.log(`waiting for task "${taskId}" to finish`);
    const taskResponse = await waitForTaskCompletion<String>(
      taskId,
      cancelListener,
      token
    );

    if (!taskResponse.result) {
      return {
        status: NodeResultStatus.Error,
        error: 'Invalid response',
      };
    }

    return {
      status: taskResponse.status,
      data: taskResponse.result.result,
    } as NodeResult;
  } else {
    const res = await lastValueFrom(of(response));
    return {
      status: NodeResultStatus.Error,
      error: 'error' in res ? res.error : JSON.stringify(res),
    };
  }
}

export async function executeBuildVTT(
  input: NodeResult | null,
  audioConfig: BuildVTTNodeDef,
  controller: AbortController,
  cancelListener: Observable<boolean>,
  executeContext: WorkflowContext
): Promise<NodeResult> {
  const config: AxiosRequestConfig = {
    signal: controller.signal,
    ...API_CONFIG,
  };

  const token = await executeContext.getAuthToken();
  config.headers = {Authorization: `Bearer ${token}`};

  const request: CollectionTaskDefinition = {
    task: CollectionTaskType.BuildFullVTT,
  };

  const collection = executeContext.getCurrentCollection();
  if (!collection) {
    return {
      status: NodeResultStatus.Error,
      error: 'Build VTT requires a collection to be specified',
    };
  }

  const response: ApiResponse<string> | ApiError = await axios
    .post<ApiResponse<string>>(
      `${API_ENDPOINT}/collections/${collection}/task/trigger`,
      request,
      config
    )
    .then(resp => resp.data)
    .catch(err => {
      return {
        status: NodeResultStatus.Error,
        error: err.toString(),
      };
    });

  if (response.status.toLowerCase() === 'ok' && 'result' in response) {
    let taskId: any = response.result;
    if (taskId instanceof Object) {
      taskId = taskId.taskId;
    }

    console.log(`waiting for task "${taskId}" to finish`);
    const taskResponse = await waitForTaskCompletion<TranscriptionResult>(
      taskId,
      cancelListener,
      token
    );

    if (!taskResponse.result) {
      return {
        status: NodeResultStatus.Error,
        error: 'Invalid response',
      };
    } else if (taskResponse.result.status === 'Failed') {
      return {
        status: NodeResultStatus.Error,
        error: taskResponse.result.error,
      };
    }

    return {
      status: taskResponse.status,
      data: taskResponse.result.result,
    } as NodeResult;
  } else {
    const res = await lastValueFrom(of(response));
    return {
      status: NodeResultStatus.Error,
      error: 'error' in res ? res.error : JSON.stringify(res),
    };
  }
}

export async function executeAudioTask(
  input: NodeResult | null,
  audioConfig: AudioTranscriptionNode,
  controller: AbortController,
  cancelListener: Observable<boolean>,
  executeContext: WorkflowContext
): Promise<NodeResult> {
  const config: AxiosRequestConfig = {
    signal: controller.signal,
    ...API_CONFIG,
  };

  if (input && input.data) {
    if (isCollectionDocumentResult(input.data)) {
      audioConfig.audioSource = {
        data: {
          collection: input.data.collection,
          document: input.data.document,
        } as SourceDocumentConfig,
        sourceType: AudioDataSourceType.CollectionDocument,
      };
    } else if (isCollectionResult(input.data)) {
      audioConfig.audioSource = {
        data: {
          collection: input.data.collection,
        } as SourceCollectionConfig,
        sourceType: AudioDataSourceType.Collection,
      };
    }
  }

  const token = await executeContext.getAuthToken();
  config.headers = {Authorization: `Bearer ${token}`};

  const response: ApiResponse<string> | ApiError = await axios
    .post<ApiResponse<string>>(
      `${API_ENDPOINT}/action/transcribe`,
      audioConfig,
      config
    )
    .then(resp => resp.data)
    .catch(err => {
      return {
        status: NodeResultStatus.Error,
        error: err.toString(),
      };
    });

  if (response.status.toLowerCase() === 'ok' && 'result' in response) {
    let taskId: any = response.result;
    if (taskId instanceof Object) {
      taskId = taskId.taskId;
    }

    console.log(`waiting for task "${taskId}" to finish`);
    const taskResponse = await waitForTaskCompletion<TranscriptionResult>(
      taskId,
      cancelListener,
      token
    );

    if (!taskResponse.result) {
      return {
        status: NodeResultStatus.Error,
        error: 'Invalid response',
      };
    } else if (taskResponse.result.status === 'Failed') {
      return {
        status: NodeResultStatus.Error,
        error: taskResponse.result.error,
      };
    }

    return {
      status: taskResponse.status,
      data: taskResponse.result.result,
    } as NodeResult;
  } else {
    const res = await lastValueFrom(of(response));
    console.error('last value from', res);
    return {
      status: NodeResultStatus.Error,
      error: 'error' in res ? res.error : JSON.stringify(res),
    };
  }
}

export function waitForTaskCompletion<T>(
  taskUUID: string,
  cancelListener: Observable<boolean>,
  token: string
): Promise<ApiResponse<TaskResponse<T>>> {
  const finished = new Subject<boolean>();
  return lastValueFrom(
    interval(3000)
      .pipe(
        takeUntil(merge(cancelListener, finished)),
        mergeMap(() => from(getTaskResult<T>(taskUUID, token)))
      )
      .pipe(
        tap(val => {
          if (
            val.result.status.startsWith('Complete') ||
            val.result.status === 'Failed'
          ) {
            finished.next(true);
            finished.complete();
          }
        })
      )
  );
}

function getTaskResult<T>(
  task_uuid: string,
  token: string
): Promise<ApiResponse<TaskResponse<T>>> {
  const config: AxiosRequestConfig = {
    ...API_CONFIG,
  };

  config.headers = {Authorization: `Bearer ${token}`};

  return axios
    .get<ApiResponse<TaskResponse<any>>>(
      `${API_ENDPOINT}/tasks/${task_uuid}`,
      config
    )
    .then(resp => resp.data);
}
