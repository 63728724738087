import React, {useEffect, useRef, useState} from 'react';
import {DateTime} from 'luxon';
import {
  LastRunDetails,
  NodeType,
  NodeUpdates,
  NodeResult,
  NodeDataTypes,
  DataNodeType,
  NodeDef,
  NodeState,
  DataNodeDefData,
} from './types/node';
import {
  ArrowDownIcon,
  Bars3Icon,
  BeakerIcon,
  CheckBadgeIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
  EyeSlashIcon,
  NoSymbolIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import {SUMMARIZE_NODE_DEFINITION} from './nodes/summarize';
import {SEND_EMAIL_NODE_DEF} from './nodes/destinations/email';
import {EditableText} from './editable';
import {ClipboardDocumentListIcon} from '@heroicons/react/24/outline';
import Loop, {LOOP_NODE_DEF} from './nodes/loop';
import {getValue, isNodeResultType, isStringResult} from './types/typeutils';
import {DATA_DESTINATION_NODE_DEF} from './nodes/destinations/connection';
import {TEMPLATE_NODE_DEFINITION} from './nodes/destinations/template';
import {
  ValidationError,
  WorkflowValidationResult,
} from './utils/workflowValidator';
import {PULL_DOCS_NODE_DEF} from './nodes/PullDocument';
import SanitizedHTML from '../SanitizedHtml';
import {TRANSCRIPTION_NODE_DEF} from './nodes/AudioTranscription';
import {SAVE_TO_COLLECTION_NODE_DEF} from './nodes/destinations/collection';
import {BUILD_VTT_NODE_DEF} from './nodes/BuildVTT';
import {EXTRACT_NODE_DEFINITION} from './nodes/ExtractNode';
import {Step} from '../../../bindings/Step';
import {COLLECTION_NODE_DEFINITION} from './nodes/CollectionInputNode';
import {COLLECTION_DOC_NODE_DEF} from './nodes/DocumentInputNode';
import {CONNECTION_NODE_DEF} from './nodes/sources/connection';
import {RSS_NODE_DEF} from './nodes/sources/rss';
import {URL_NODE_DEF} from './nodes/sources/url';
import {TEXT_NODE_DEF} from './nodes/sources/static';
import {SIGHTGLASS_EMAIL_CONTENT_NODE_DEF} from './nodes/sources/SightglassEmailContent';
import {WorkflowTrigger} from '../../../bindings/WorkflowTrigger';
import {EXTRACT_TASKS_NODE_DEFINITION} from './nodes/extract/ExtractTasksAndDecisions';
import {JSON_NODE_DEF} from './nodes/sources/json';
import {MICROSOFT_TODO_NODE_DEF} from './nodes/destinations/MicrosoftTasks';
import {WorkflowTriggerType} from '../../../bindings/WorkflowTriggerType';
import {MANUAL_TRIGGER_DEF} from './nodes/TriggerConfig';
import {SCHEDULED_TRIGGER_DEF} from './nodes/triggers/ScheduleTriggerConfig';
import {SIGHTGLASS_EMAIL_TRIGGER_DEF} from './nodes/triggers/EmailTriggerConfig';
import {OCR_NODE_DEF} from './nodes/OcrNode';
import {AGGREGATE_NODE_DEF} from './nodes/AggregateNode';
import {StepInputSource} from '../../../bindings/StepInputSource';
import {OUTLOOK365_TRIGGER_DEF} from './nodes/triggers/Outlook365TriggerConfig';
import {OUTLOOK_EMAIL_CONTENT_NODE_DEF} from './nodes/sources/Outlook365EmailContent';
import {EXTRACT_EMAIL_RESPONSE_NODE_DEFINITION} from './nodes/extract/ExtractEmailResponse';
import {RINGCENTRAL_TRIGGER_DEF} from './nodes/triggers/RingCentralTriggerConfig';
import {RINGCENTRAL_CONTENT_NODE_DEF} from './nodes/sources/RingCentralCallContent';
import {ASSISTANT_NODE_DEF} from './nodes/AssistantNode';

export interface BaseNodeProps {
  uuid: string;
  label: string;
  data: NodeDataTypes;
  nodeType: NodeType;
  templateId?: string;
  lastRun?: LastRunDetails;
  nodeState?: NodeState;
  workflowValidation?: WorkflowValidationResult;
  onDelete?: (uuid: string) => void;
  // Request node update
  onUpdate?: (nodeUpdates: NodeUpdates) => void;
  onStateChange?: (nodeSate: NodeState) => void;
  onDragUpdate?: (uuid: string | null) => void;
  currentNodeRunning?: string | null;
}

export interface NodeBodyProps {
  data: NodeDataTypes;
  uuid: string;
  onUpdateData?: (
    dataUpdates: NodeDataTypes,
    inputSource?: StepInputSource
  ) => void;
  currentNodeRunning?: string | null;
}

export interface NodeHeaderProps {
  label: string;
  nodeType: NodeType;
  subType?: DataNodeType;
  isEditable: boolean;
  onUpdate?: (newValue: string, oldValue: string) => void;
}

export interface NodeIconProps {
  nodeType: NodeType;
  subType?: DataNodeType | null;
  className?: string;
}

export const BASE_CARD_STYLE =
  'card shadow-xl w-full mx-auto md:w-[640px] xs:w-[320px]';

const EMPTY_DEF: NodeDefinition = {
  nodeSelectionLabel: 'Unknown',
  nodeLabel: 'Unknown',
  getNodeIcon: () => <div></div>,
  createStep: () => {
    return {
      nodeType: 'DataSource',
      data: {
        type: 'text',
        data: '',
      },
      label: 'Unknown',
      parentNode: null,
      templateId: null,
      uuid: 'abc123',
      inputSource: null,
    } as Step;
  },
  renderNode: () => <div></div>,
};

export interface NodeDefinition {
  nodeSelectionLabel: string;
  nodeLabel: string;
  getNodeIcon(className?: string): JSX.Element;
  renderNode(baseProps: NodeBodyProps): JSX.Element;
  createStep(): Step;
}

export interface TriggerProps {
  config: WorkflowTrigger;
  onUpdate?: (update: NodeUpdates) => void;
}

export interface TriggerDefinition {
  defaultTriggerConfig: WorkflowTrigger;
  renderTrigger(baseProps: TriggerProps): JSX.Element;
}

export function getTriggerDefinition(
  triggerType: WorkflowTriggerType
): TriggerDefinition {
  switch (triggerType) {
    case 'Email':
      return SIGHTGLASS_EMAIL_TRIGGER_DEF;
    case 'Manual':
      return MANUAL_TRIGGER_DEF;
    case 'Schedule':
      return SCHEDULED_TRIGGER_DEF;
    case 'Microsoft':
      return OUTLOOK365_TRIGGER_DEF;
    case 'RingCentral':
      return RINGCENTRAL_TRIGGER_DEF;
  }
}

export function getTriggerType(trigger: WorkflowTrigger): WorkflowTriggerType {
  if (trigger.email) {
    return 'Email';
  } else if (trigger.schedule) {
    return 'Schedule';
  } else if (trigger.microsoft) {
    return 'Microsoft';
  } else if (trigger.ringcentral) {
    return 'RingCentral';
  } else {
    return 'Manual';
  }
}

export function getNodeDefinition(
  nodeType: NodeType,
  subType?: DataNodeType | null
): NodeDefinition {
  switch (nodeType) {
    case NodeType.Extract:
      return EXTRACT_NODE_DEFINITION;
    case NodeType.ExtractEmailResponse:
      return EXTRACT_EMAIL_RESPONSE_NODE_DEFINITION;
    case NodeType.ExtractTasksAndDecisions:
      return EXTRACT_TASKS_NODE_DEFINITION;
    case NodeType.Template:
      return TEMPLATE_NODE_DEFINITION;
    case NodeType.Summarize:
      return SUMMARIZE_NODE_DEFINITION;
    case NodeType.DataSource:
      switch (subType) {
        case DataNodeType.Collection:
          return COLLECTION_NODE_DEFINITION;
        case DataNodeType.CollectionDocument:
          return COLLECTION_DOC_NODE_DEF;
        case DataNodeType.Connection:
          return CONNECTION_NODE_DEF;
        case DataNodeType.Rss:
          return RSS_NODE_DEF;
        case DataNodeType.Url:
          return URL_NODE_DEF;
        case DataNodeType.Text:
          return TEXT_NODE_DEF;
        case DataNodeType.Json:
          return JSON_NODE_DEF;
        case DataNodeType.EmailContent:
          return SIGHTGLASS_EMAIL_CONTENT_NODE_DEF;
        case DataNodeType.Outlook365Content:
          return OUTLOOK_EMAIL_CONTENT_NODE_DEF;
        case DataNodeType.RingCentralCall:
          return RINGCENTRAL_CONTENT_NODE_DEF;
      }
      return EMPTY_DEF;
    case NodeType.DataDestination:
      return DATA_DESTINATION_NODE_DEF;
    case NodeType.Loop:
      return LOOP_NODE_DEF;
    case NodeType.AudioTranscription:
      return TRANSCRIPTION_NODE_DEF;
    case NodeType.BuildVtt:
      return BUILD_VTT_NODE_DEF;
    case NodeType.PullDocument:
      return PULL_DOCS_NODE_DEF;
    case NodeType.SendEmail:
      return SEND_EMAIL_NODE_DEF;
    case NodeType.SaveToCollection:
      return SAVE_TO_COLLECTION_NODE_DEF;
    case NodeType.MicrosoftTodo:
      return MICROSOFT_TODO_NODE_DEF;
    case NodeType.Ocr:
      return OCR_NODE_DEF;
    case NodeType.AggregateOutput:
      return AGGREGATE_NODE_DEF;
    case NodeType.Assistant:
      return ASSISTANT_NODE_DEF;
  }
}

export function NodeIcon({nodeType, subType, className}: NodeIconProps) {
  const definition = getNodeDefinition(nodeType, subType);
  return definition.getNodeIcon(className);
}

export function NodeHeader({
  nodeType,
  subType,
  label,
  isEditable,
  onUpdate = () => {},
}: NodeHeaderProps) {
  return (
    <h2 className="flex flex-row gap-2 flex-grow font-bold text-lg text-primary p-0 items-center">
      <NodeIcon nodeType={nodeType} subType={subType} className="w-5 h-5" />
      {isEditable ? (
        <EditableText
          data={label}
          onChange={newValue => onUpdate(newValue, label)}
        />
      ) : (
        <div>{label}</div>
      )}
    </h2>
  );
}

function ValidationErrorStatus({
  validationError,
}: {
  validationError: ValidationError;
}) {
  return (
    <div className="flex flex-row gap-2 text-xs text-error">
      <ExclamationCircleIcon className="w-4" />
      {validationError.error}
    </div>
  );
}

function LastRunSummary({
  lastRun,
  validationError,
}: {
  lastRun: LastRunDetails;
  validationError?: ValidationError;
}) {
  const scrollToRef = useRef(null);

  useEffect(() => {
    if (lastRun.nodeResult.status.toLowerCase() === 'ok') {
      if (scrollToRef.current) {
        (scrollToRef.current as HTMLElement).scrollIntoView();
      }
    }
  }, [lastRun]);

  if (lastRun.nodeResult.status.toLowerCase() === 'ok') {
    const duration = DateTime.fromJSDate(lastRun.endTimestamp).diff(
      DateTime.fromJSDate(lastRun.startTimestamp),
      'seconds'
    );
    return (
      <div ref={scrollToRef} className="flex flex-row gap-2 text-xs w-full">
        <CheckBadgeIcon className="w-4 text-success" />
        <div className="text-neutral-500">{duration.seconds.toFixed(3)}s</div>
        <div className="text-neutral-500 ml-auto">
          {DateTime.fromJSDate(lastRun.endTimestamp).toLocaleString(
            DateTime.DATETIME_FULL
          )}
        </div>
      </div>
    );
  } else if (validationError) {
    return (
      <div className="flex flex-row gap-2 text-xs text-error">
        <ExclamationCircleIcon className="w-4" />
        {validationError.error}
      </div>
    );
  } else {
    return (
      <div className="flex flex-row gap-2 text-xs text-error">
        <ExclamationCircleIcon className="w-4" />
        {lastRun.nodeResult.error}
      </div>
    );
  }
}

interface WorkflowResultProps {
  result: NodeResult;
  hideButton?: boolean;
  onHide?: () => void;
  className?: string;
}

export function WorkflowResult({
  result,
  className = 'bg-base-200',
  hideButton = false,
  onHide = () => {},
}: WorkflowResultProps) {
  const [isCopying, setIsCopying] = useState(false);

  // Pull out text based content to display by itself, otherwise
  // render data as a pretty printed JSON blob.
  let content: string | null | undefined = null;
  if (result.data && isStringResult(result.data)) {
    content = result.data.text;
  } else if (result.data && isNodeResultType(result.data)) {
    const nodeData = result.data.data;
    if (nodeData.type === DataNodeType.Text) {
      content = nodeData.data as string;
    } else if (nodeData.type === DataNodeType.Collection) {
      content = `Collection: ${(nodeData.data as DataNodeDefData).collection}`;
    } else if (nodeData.type === DataNodeType.CollectionDocument) {
      const data = nodeData.data as DataNodeDefData;
      content = `Collection: ${data.collection}</br>Document: ${data.document}`;
    } else if (nodeData.type === DataNodeType.Json) {
      content = JSON.stringify(
        (nodeData.data as DataNodeDefData).content,
        null,
        2
      );
    } else if (nodeData.type === DataNodeType.JsonList) {
      content = JSON.stringify(
        (nodeData.data as DataNodeDefData).items,
        null,
        2
      );
    } else if (nodeData.type === DataNodeType.TextList) {
      content = JSON.stringify(nodeData.data, null, 2);
    }
  } else if (result.data) {
    content = JSON.stringify(getValue(result.data), null, 2);
  } else {
    content = null;
  }

  const handleCopy = () => {
    setIsCopying(true);
    setTimeout(() => setIsCopying(false), 512);
    if (content) {
      navigator.clipboard.writeText(content);
    }
  };

  return (
    <div className={`${BASE_CARD_STYLE} ${className}`}>
      <div className="card-body p-2 max-h-[512px] overflow-y-auto">
        <pre className="text-xs p-4 rounded-lg overflow-auto">
          {content ? (
            <SanitizedHTML dirty={content}></SanitizedHTML>
          ) : (
            <span className="italic">Result is empty</span>
          )}
        </pre>
      </div>
      <div className="card-actions p-2 place-content-center flex flex-row items-center">
        {hideButton && (
          <button className="btn btn-neutral btn-sm" onClick={() => onHide()}>
            <EyeSlashIcon className="w-4 h-4" />
            Hide
          </button>
        )}
        <button
          className="btn btn-neutral btn-sm"
          disabled={isCopying}
          onClick={() => handleCopy()}
        >
          {isCopying ? (
            <span className="loading loading-spinner loading-sm"></span>
          ) : (
            <ClipboardDocumentListIcon className="w-4 h-4" />
          )}
          Copy
        </button>
      </div>
    </div>
  );
}

export function NodeComponent({
  uuid,
  label,
  nodeType,
  templateId,
  data,
  lastRun,
  workflowValidation,
  nodeState,
  currentNodeRunning,
  onUpdate = () => {},
  onDelete = () => {},
  onStateChange = () => {},
  onDragUpdate = () => {},
}: BaseNodeProps) {
  const scrollToRef = useRef(null);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    nodeState?.expanded ?? false
  );
  const [error, setError] = useState<string | null>(null);
  const [canDrag, setCanDrag] = useState<boolean>(false);
  const [validationResult, setValidationResult] = useState<
    ValidationError | undefined
  >(undefined);

  let isRunning = false;
  if (currentNodeRunning) {
    isRunning = uuid.startsWith(currentNodeRunning);
  }

  useEffect(() => {
    if (lastRun?.nodeResult.status === 'error') {
      setError(lastRun.nodeResult.error ?? null);
    } else {
      setError(null);
    }
  }, [lastRun]);

  useEffect(() => {
    const result = workflowValidation?.validationErrors?.find(
      errors => errors.uuid === uuid
    );
    setValidationResult(result);
  }, [workflowValidation, uuid]);

  useEffect(() => {
    if (isRunning && scrollToRef.current) {
      // Get the top position of this element
      const el = scrollToRef.current as HTMLElement;
      const topPost = el.getBoundingClientRect().top;
      // Get height of the top bar and add some padding.
      const headerEl = document.getElementById('#header');
      const headerBottom =
        (headerEl?.getBoundingClientRect().bottom ?? 96) + 32;
      // Scroll to the offset
      const offset = topPost + (window.scrollY - headerBottom);
      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  }, [isRunning]);

  const baseProps: NodeBodyProps = {
    data: data,
    uuid,
    onUpdateData: (data: any, inputSource?: StepInputSource) =>
      onUpdate({data, inputSource}),
  };

  let customNodeType = nodeType;
  if (templateId) {
    const templateNodeType = NodeType[templateId as keyof typeof NodeType];
    if (templateNodeType) {
      customNodeType = templateNodeType;
    } else {
      console.warn(
        'Template ID does not match a node type that can be rendered, using default'
      );
    }
  }

  const nodeDefinition = getNodeDefinition(customNodeType, null);
  const renderNodeBody = () => {
    if (customNodeType === NodeType.Loop) {
      return (
        <Loop
          parentUUID={uuid}
          label={label}
          onDelete={() => onDelete(uuid)}
          onUpdateLabel={label => onUpdate({label})}
          currentNodeRunning={currentNodeRunning}
          onDragUpdate={uuid => onDragUpdate(uuid)}
          {...baseProps}
        />
      );
    } else {
      return nodeDefinition.renderNode(baseProps);
    }
  };

  let borderColor = isRunning ? 'border-info' : 'border-neutral';
  if (!isRunning && lastRun) {
    if (lastRun.nodeResult.status.toLowerCase() === 'ok') {
      borderColor = 'border-success';
    } else {
      borderColor = 'border-error';
    }
  }

  // Handle loop nodes specially
  if (customNodeType === NodeType.Loop) {
    return renderNodeBody();
  }

  const toggleCollapse = () => {
    setIsCollapsed(state => !state);
    onStateChange({expanded: !isCollapsed});
  };

  return (
    <div
      ref={scrollToRef}
      className={`${BASE_CARD_STYLE} bg-neutral border-2 ${borderColor}`}
      draggable={canDrag}
      onDragStart={() => onDragUpdate(uuid)}
      onDragEnd={() => {
        setCanDrag(false);
        onDragUpdate(null);
      }}
    >
      <figure
        className="bg-base-100 p-2 border-inherit"
        onDoubleClick={() => toggleCollapse()}
      >
        <div className="flex flex-row w-full items-center gap-4">
          <Bars3Icon
            className="w-4 h-4 cursor-pointer"
            onMouseDown={() => setCanDrag(true)}
            onMouseUp={() => setCanDrag(false)}
          />
          <NodeHeader
            isEditable={true}
            label={label}
            nodeType={customNodeType}
            onUpdate={value => onUpdate({label: value})}
          />
          <div className="flex flex-row gap-2">
            <button
              className="btn btn-circle btn-xs btn-neutral btn-outline"
              onClick={() => toggleCollapse()}
            >
              {isCollapsed ? (
                <ChevronDownIcon className="w-3 text-gray" />
              ) : (
                <ChevronUpIcon className="w-3 text-gray" />
              )}
            </button>
            <button
              className="btn btn-circle btn-xs btn-error btn-outline"
              onClick={() => onDelete(uuid)}
            >
              <XMarkIcon className="w-3 text-gray" />
            </button>
          </div>
        </div>
      </figure>
      {!isCollapsed ? (
        <div className="card-body px-6 py-4">
          <div className="flex flex-col gap-4 text-sm">{renderNodeBody()}</div>
        </div>
      ) : null}
      <figure className="card-actions bg-base-100 py-2 px-4">
        {error && <div className="alert alert-error text-sm">{error}</div>}
        {isRunning ? (
          <div className="text-sm text-neutral-content flex flex-row items-center gap-2">
            <span className="loading loading-spinner text-info loading-sm"></span>
            Executing...
          </div>
        ) : null}
        {validationResult && !lastRun && !isRunning ? (
          <ValidationErrorStatus
            validationError={validationResult}
          ></ValidationErrorStatus>
        ) : null}
        {lastRun && !isRunning ? (
          <LastRunSummary
            lastRun={lastRun}
            validationError={validationResult}
          />
        ) : !isRunning && !validationResult ? (
          <div className="text-xs text-neutral-content flex flex-row items-center gap-2">
            <NoSymbolIcon className="w-3" />
            Has not been run.
          </div>
        ) : null}
      </figure>
    </div>
  );
}

export function ShowNodeResult({
  node,
  result,
  onMappingConfigure,
  canShowMapping,
}: {
  node: NodeDef;
  result: LastRunDetails | undefined;
  onMappingConfigure: () => void;
  canShowMapping: boolean;
}) {
  const [showResult, setShowResult] = useState<boolean>(false);

  const hasMapping = node.mapping !== undefined && node.mapping.length > 0;

  if (!result) {
    return (
      <div className="w-full flex justify-center">
        <MappingButton
          onMappingConfigure={onMappingConfigure}
          showMapping={canShowMapping}
          hasMapping={hasMapping}
        ></MappingButton>
      </div>
    );
  } else if (showResult && result) {
    return (
      <WorkflowResult
        result={result.nodeResult}
        hideButton={true}
        onHide={() => setShowResult(false)}
      />
    );
  } else {
    return (
      <div className="w-fit mx-auto">
        {result ? (
          <div className="btn" onClick={() => setShowResult(true)}>
            View Results
          </div>
        ) : (
          <MappingButton
            onMappingConfigure={onMappingConfigure}
            showMapping={canShowMapping}
            hasMapping={hasMapping}
          ></MappingButton>
        )}
      </div>
    );
  }
}

function MappingButton({
  onMappingConfigure,
  showMapping,
  hasMapping,
}: {
  onMappingConfigure: () => void;
  showMapping: boolean;
  hasMapping: boolean;
}) {
  if (showMapping) {
    const badgeColor = hasMapping ? 'badge-primary' : '';

    return (
      <button
        className="btn btn-neutral indicator"
        onClick={() => onMappingConfigure()}
      >
        <span className={`indicator-item indicator-end badge ${badgeColor}`}>
          <BeakerIcon className="h-4 w-4"></BeakerIcon>
        </span>
        <ArrowDownIcon className="h-4 w-4"></ArrowDownIcon>
      </button>
    );
  } else {
    return <ArrowDownIcon className="h-4 w-4"></ArrowDownIcon>;
  }
}
