import sanitizeHtml from 'sanitize-html';

export interface SanitizedHTMLProps {
  className?: string;
  dirty: string;
  options?: sanitizeHtml.IOptions;
}

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h2', 'h1'],
  allowedAttributes: {
    a: ['href'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
};

const sanitize = (dirty: string, options?: sanitizeHtml.IOptions) => ({
  __html: sanitizeHtml(dirty, {...defaultOptions, ...options}),
});

function SanitizedHTML({dirty, options, className}: SanitizedHTMLProps) {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={sanitize(dirty, options)}
    />
  );
}

export default SanitizedHTML;
