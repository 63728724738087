import WorkflowEditor, {WorkflowInstanceProps} from './WorkflowEditor';

import {useState, useEffect} from 'react';
import {useNavigate, useLoaderData} from 'react-router-dom';
import WorkflowRunList from './WorkflowRunList';

export enum WorkflowInstanceViews {
  Editor,
  Runs,
}

export interface WorkflowInstanceViewProps {
  defaultView?: WorkflowInstanceViews;
}

export function WorkflowInstance({
  defaultView = WorkflowInstanceViews.Editor,
}: WorkflowInstanceViewProps) {
  const {workflowUuid} = useLoaderData() as WorkflowInstanceProps;
  const [view, setView] = useState<WorkflowInstanceViews>(defaultView);
  const nav = useNavigate();

  useEffect(() => {
    setView(defaultView);
  }, [defaultView]);

  return (
    <div>
      <div className="flex flex-row gap-2 justify-center mb-4">
        <button
          className={`btn btn-sm ${
            view === WorkflowInstanceViews.Editor ? 'btn-primary' : ''
          }`}
          onClick={() => {
            nav(`/workflows/${workflowUuid}`);
          }}
        >
          Workflow Editor
        </button>
        <button
          className={`btn btn-sm ${
            view === WorkflowInstanceViews.Runs ? 'btn-primary' : ''
          }`}
          onClick={() => {
            nav(`/workflows/${workflowUuid}/run`);
          }}
        >
          Workflow Runs
        </button>
      </div>
      {view === WorkflowInstanceViews.Editor ? (
        <WorkflowEditor></WorkflowEditor>
      ) : view === WorkflowInstanceViews.Runs ? (
        <WorkflowRunList workflowUuid={workflowUuid} />
      ) : null}
    </div>
  );
}
