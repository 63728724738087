import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useLocation} from 'react-router-dom';

interface LoginButtonProps {
  className?: string;
}

const LoginButton = ({className}: LoginButtonProps) => {
  const {loginWithRedirect} = useAuth0();
  const loc = useLocation();
  return (
    <button
      onClick={() =>
        loginWithRedirect({
          appState: {returnTo: loc.pathname + loc.search},
        })
      }
      className={className ?? 'btn btn-primary'}
    >
      Login / Sign-Up
    </button>
  );
};

export default LoginButton;
