import {RefObject, useEffect, useState} from 'react';
import {ModalType} from '../../../components/modals';
import {DataNodeType, NodeType} from '../../../components/talos/types/node';
import * as solid from '@heroicons/react/20/solid';
import {WorkflowTriggerType} from '../../../../bindings/WorkflowTriggerType';
import {JSONContentType} from '../../../../bindings/JSONContentType';
import {SiMicrosoftoutlook} from '@icons-pack/react-simple-icons';
import {RingCentralIcon} from '../../../components/icons/ringcentral';

interface AddSourceModalProps {
  modalRef: RefObject<ModalType>;
  onClick: (
    nodeDataType: DataNodeType,
    contentType: JSONContentType | null,
    triggerType: WorkflowTriggerType
  ) => void;
}

interface Node {
  label: string;
  nodeType?: NodeType;
  Icon: React.ComponentType;
  tooltip?: string;
}

interface SourceNode extends Node {
  supportedTriggers?: WorkflowTriggerType[];
  type: DataNodeType;
  // Only used for DataNodeType.Json & DataNodeType.JsonList
  contentType?: JSONContentType;
}

interface TriggerNode extends Node {
  type: WorkflowTriggerType;
}

interface SelectedInput {
  nodeDataType: DataNodeType;
  contentType: JSONContentType | null;
}

export default function AddSourceModal({
  modalRef,
  onClick,
}: AddSourceModalProps) {
  const [selectedInput, setSelectedInput] = useState<SelectedInput>({
    nodeDataType: DataNodeType.Text,
    contentType: null,
  });
  const [selectedTrigger, setSelectedTrigger] =
    useState<WorkflowTriggerType>('Manual');
  const [supportedTriggers, setSupportedTriggers] = useState<
    WorkflowTriggerType[]
  >([]);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    // Empty list means everything is supported.
    const validTrigger =
      supportedTriggers.length === 0 ||
      // Otherwise check to see if the selected trigger is in the list.
      (supportedTriggers.length > 0 &&
        supportedTriggers.includes(selectedTrigger));
    setIsValid(
      selectedInput !== null && selectedTrigger !== null && validTrigger
    );
  }, [selectedInput, selectedTrigger, supportedTriggers]);

  const nodeList: SourceNode[] = [
    {
      type: DataNodeType.Text,
      label: 'Raw Text',
      Icon: solid.Bars3BottomLeftIcon,
    },
    {
      type: DataNodeType.Json,
      label: 'JSON',
      Icon: solid.Bars3BottomLeftIcon,
    },
    {
      type: DataNodeType.Url,
      label: 'Webpage',
      Icon: solid.GlobeAltIcon,
    },
    {
      type: DataNodeType.Rss,
      label: 'RSS Feed',
      Icon: solid.GlobeAltIcon,
    },
    {
      type: DataNodeType.Collection,
      label: 'Collection',
      Icon: solid.BuildingLibraryIcon,
      supportedTriggers: [],
    },
    {
      type: DataNodeType.CollectionDocument,
      label: 'Single Document',
      Icon: solid.DocumentIcon,
    },
    {
      type: DataNodeType.Connection,
      label: 'Connection',
      Icon: solid.CloudIcon,
      supportedTriggers: ['Manual', 'Schedule'],
    },
    {
      type: DataNodeType.EmailContent,
      label: 'Email Contents',
      Icon: solid.EnvelopeIcon,
      supportedTriggers: ['Email'],
    },
    {
      type: DataNodeType.Outlook365Content,
      label: 'Outlook 365 Source',
      Icon: SiMicrosoftoutlook,
      supportedTriggers: ['Microsoft'],
    },
    {
      type: DataNodeType.RingCentralCall,
      label: 'RingCentral Call',
      Icon: RingCentralIcon,
      supportedTriggers: ['RingCentral'],
    },
  ];

  const triggerList: TriggerNode[] = [
    {type: 'Manual', label: 'Manually', Icon: solid.BoltIcon},
    {
      type: 'Schedule',
      label: 'On a Schedule',
      Icon: solid.ClockIcon,
      tooltip: 'Automatically run daily, weekly, or monthly',
    },
    {
      type: 'Email',
      label: 'Email',
      Icon: solid.EnvelopeIcon,
      tooltip: 'Run when an email is sent to a special Sightglass email',
    },
    {
      type: 'Microsoft',
      label: 'Outlook 365',
      Icon: SiMicrosoftoutlook,
      tooltip:
        'Run when an new flagged email is received in the Outlook365 inbox',
    },
    {
      type: 'RingCentral',
      label: 'RingCentral',
      Icon: RingCentralIcon,
      tooltip: 'Run when a new RingCentral call has finished',
    },
  ];

  return (
    <dialog ref={modalRef as RefObject<HTMLDialogElement>} className="modal">
      <div className="modal-box border-2 border-primary lg:min-w-[960px]">
        <div className="mb-8">
          <div className="mb-4">
            <div className="font-semibold text-xl flex flex-row items-center gap-2">
              <solid.InboxArrowDownIcon className="w-5" />
              Data Sources
            </div>
            <div className="text-neutral-500">
              Where is the data coming from?
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            {nodeList.map((item, idx) => (
              <InfoButton
                key={`nodetype-${idx}`}
                isSelected={selectedInput.nodeDataType === item.type}
                onClick={() => {
                  setSelectedInput({
                    nodeDataType: item.type,
                    contentType: item.contentType ?? null,
                  });
                  setSupportedTriggers(item.supportedTriggers ?? []);
                }}
                label={item.label}
                Icon={item.Icon}
                tooltip={item.tooltip}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="mb-4">
            <div className="font-semibold text-lg flex flex-row gap-2">
              <solid.ClockIcon className="w-5" />
              Workflow Triggers
            </div>
            <div className="text-neutral-500">
              When should this workflow run?
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            {triggerList.map(({type, label, Icon, tooltip}, idx) => (
              <InfoButton
                key={`nodetype-${idx}`}
                isSelected={selectedTrigger === type}
                onClick={() => setSelectedTrigger(type)}
                label={label}
                Icon={Icon}
                tooltip={tooltip}
                disabled={
                  !(
                    supportedTriggers.length === 0 ||
                    supportedTriggers.includes(type)
                  )
                }
              />
            ))}
          </div>
        </div>
        <div className="modal-action">
          <button
            className="btn btn-sm btn-outline"
            onClick={() => modalRef.current?.close()}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-success"
            disabled={!isValid}
            onClick={() =>
              onClick(
                selectedInput.nodeDataType,
                selectedInput.contentType,
                selectedTrigger
              )
            }
          >
            Add Source
          </button>
        </div>
      </div>
    </dialog>
  );
}

interface InfoButtonProps {
  Icon: React.ComponentType;
  label: string;
  isSelected: boolean;
  tooltip?: string;
  onClick: () => void;
  disabled?: boolean;
}

function InfoButton({
  Icon,
  label,
  isSelected,
  onClick,
  tooltip,
  disabled = false,
}: InfoButtonProps) {
  const highlight = isSelected
    ? 'bg-primary text-primary-content hover:bg-primary'
    : 'bg-neutral';

  return (
    <div className="tooltip w-full" data-tip={tooltip}>
      <button
        className={`btn btn-neutral flex flex-row items-center gap-2 ${highlight} w-full`}
        onClick={() => onClick()}
        disabled={disabled}
      >
        <div className="w-6">
          <Icon />
        </div>
        <div>{label}</div>
      </button>
    </div>
  );
}
