import {useEffect, useState} from 'react';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {EditableTextarea} from '../../editable';
import {Bars3BottomLeftIcon} from '@heroicons/react/20/solid';
import {Step} from '../../../../../bindings/Step';

export class TextNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Text Source';
  public nodeLabel = 'Text Input';
  public getNodeIcon(className?: string): JSX.Element {
    return <Bars3BottomLeftIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <StaticDataNode {...baseProps}></StaticDataNode>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'DataSource',
      templateId: null,
      data: {
        type: 'text',
        data: '',
      },
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const TEXT_NODE_DEF = new TextNodeDefinition();

export function StaticDataNode({data, onUpdateData = () => {}}: NodeBodyProps) {
  const [dataValue, setDataValue] = useState<string>('');

  useEffect(() => {
    const content = data as string;
    if (typeof content === 'string') {
      setDataValue(content);
    } else if (content) {
      try {
        setDataValue(JSON.stringify(content));
      } catch {
        setDataValue('');
      }
    } else {
      setDataValue('');
    }
  }, [data]);

  const updateData = (newValue: string) => {
    onUpdateData(newValue);
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        <EditableTextarea
          data={dataValue}
          label="Data"
          onChange={value => updateData(value)}
        />
      </div>
    </div>
  );
}
