import {useContext, useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import {
  ArrowPathIcon,
  CalendarDaysIcon,
  TrashIcon,
  UserIcon,
} from '@heroicons/react/20/solid';
import {TranscriptTextMessage} from '../../api/messages';
import {UserContext} from '../../context';
import {ActionItem, buildActionItemList} from './actions';
import {CopyToClipboard} from '../../components/copyText';
import {Feature} from '../../components/feature';
import {MicrosoftTodoIcon} from '../../components/icons/microsofttodo';
import {
  MicrosoftTodoAction,
  MicrosoftTodoConnectionRequest,
  MicrosoftTodoTaskData,
  UserConnection,
} from '../../api/Client';

const GOOGLE_CAL_DATE_FORMAT = "yyyyMMdd'T'HHmmss";

interface ActionItemsProps {
  transcript: TranscriptTextMessage[];
  enabled: boolean;
  transcriptionRunning: boolean;
  currentActionItems: ActionItem[];
  updateActionItems(items: ActionItem[]): void;
}

export function ActionItems({
  enabled,
  transcript,
  transcriptionRunning,
  currentActionItems,
  updateActionItems,
}: ActionItemsProps) {
  const {client} = useContext(UserContext);
  const [isBuilding, setIsBuilding] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [microsoftConnection, setMicrosoftConnection] =
    useState<UserConnection>();

  useEffect(() => {
    if (client) {
      client.listConnections().then(connections => {
        if (connections.result) {
          const connection = connections.result.find(connection => {
            return connection.apiId === 'graph.microsoft.com+tasks';
          });
          if (connection) {
            setMicrosoftConnection(connection);
          }
        }
      });
    }
  }, [client]);

  useEffect(() => {
    if (
      enabled &&
      transcript &&
      transcript.length > 0 &&
      !transcriptionRunning
    ) {
      if (client && !isBuilding) {
        setIsBuilding(true);
        buildActionItemList(client, transcript)
          .then(actionItemsResult => {
            if (actionItemsResult?.result) {
              updateActionItems(actionItemsResult.result);
            }
            setIsBuilding(false);
          })
          .catch(error => {
            console.error('Error building action list ', error);
            setIsBuilding(false);
          });
      }
    }
  }, [
    client,
    enabled,
    isBuilding,
    transcript,
    transcriptionRunning,
    updateActionItems,
  ]);

  const saveActionsAsTodoTasks = () => {
    setIsSaving(true);

    if (microsoftConnection && client) {
      const actions = currentActionItems.map(actionItem => {
        const newAction: MicrosoftTodoTaskData = {
          title: actionItem.action,
        };
        if (actionItem.when_iso) {
          newAction.dueDate = actionItem.when_iso;
        }
        return newAction;
      });

      const connectionRequest: MicrosoftTodoConnectionRequest = {
        MicrosoftTodo: {
          action: MicrosoftTodoAction.AddTasks,
          request: {
            task: actions,
          },
        },
      };

      client
        .executeConnectionRequest(
          microsoftConnection.id + '',
          connectionRequest
        )
        .catch(error => {
          console.error('Error saving tasks', error);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  return (
    <div className="flex flex-col gap-2 mx-auto w-full ">
      <div className="text-gray-500 text-sm text-center">
        Tasks detected in your conversation. Updated when transcription is
        paused.
      </div>
      <div className="p-4 bg-neutral rounded">
        {isBuilding ? (
          <ArrowPathIcon className="w-6 animate-spin mx-auto" />
        ) : null}
        {!transcriptionRunning && currentActionItems.length > 0 ? (
          currentActionItems.map((item, index) => (
            <ActionItemBox
              item={item}
              onDelete={() => {
                const newActions = [...currentActionItems];
                newActions.splice(index, 1);
                updateActionItems(newActions);
              }}
            />
          ))
        ) : isBuilding ? null : (
          <pre className="text-sm overflow-auto">...</pre>
        )}
      </div>
      <div className="flex flex-row">
        <CopyToClipboard
          className="grow"
          disabled={currentActionItems.length === 0}
          getText={() => {
            return currentActionItems
              .map(x => {
                const people = x.people ? x.people : '';
                const when = x.when_iso ? x.when + formatDate(x.when_iso) : '';
                return `${x.action}\t${when}\t${people}`;
              })
              .join('\n');
          }}
        >
          Copy Action Items
        </CopyToClipboard>
        {microsoftConnection ? (
          <Feature feature="microsoft.todo">
            <button
              className="btn btn-neutral ml-2"
              onClick={() => {
                saveActionsAsTodoTasks();
              }}
              disabled={isSaving || currentActionItems.length === 0}
            >
              {isSaving ? (
                <span className="loading loading-spinner" />
              ) : (
                <MicrosoftTodoIcon className="join-item w-4 h-4"></MicrosoftTodoIcon>
              )}
              Save As Tasks
            </button>
          </Feature>
        ) : null}
      </div>
    </div>
  );
}

interface ActionItemBoxProps {
  item: ActionItem;
  onDelete?: () => void;
}

function formatDate(dateTimeStr: string): string {
  const datetime = DateTime.fromISO(dateTimeStr);
  return datetime.isValid
    ? ' (' + datetime.toLocaleString(DateTime.DATE_SHORT) + ')'
    : '';
}

function buildGoogleCalUrl(item: ActionItem): string {
  const datetime = DateTime.fromISO(item.when_iso);
  const txt = encodeURIComponent(item.action);
  let details = '';
  if (item.people) {
    details = encodeURIComponent(
      `Sightglass Action Item: \n${item.action}\n People: ${item.people}`
    );
  } else {
    details = encodeURIComponent(`Sightglass Action Item: \n${item.action}`);
  }

  if (datetime.isValid) {
    const dateString = datetime.toFormat(GOOGLE_CAL_DATE_FORMAT);
    const endDateString = datetime
      .plus({minutes: 30})
      .toFormat(GOOGLE_CAL_DATE_FORMAT);

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${txt}&details=${details}&dates=${dateString}/${endDateString}`;
  } else {
    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${txt}&details=${details}`;
  }
}

function ActionItemBox({item, onDelete = () => {}}: ActionItemBoxProps) {
  return (
    <div className="card bg-base-100 shadow-md mt-2">
      <div className="card-body p-2">
        <div className="flex flex-row justify-normal gap-4">
          <div className="flex flex-col">
            <div className="text-lg p-2">{item.action}</div>
            <div className="flex flex-row text-sm">
              {item.when ? (
                <details className="dropdown">
                  <summary className="btn btn-sm">
                    <CalendarDaysIcon className="w-4"></CalendarDaysIcon>
                    <span>{item.when + formatDate(item.when_iso)}</span>
                  </summary>
                  <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52">
                    <li>
                      <a href={buildGoogleCalUrl(item)} target="_blank">
                        Create Google Calendar Event...
                      </a>
                    </li>
                  </ul>
                </details>
              ) : null}
              {item.people ? (
                <div className="flex flex-row text-center">
                  <UserIcon className="w-4 mx-2"></UserIcon>
                  <span className="m-auto">{item.people}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="ml-auto flex flex-row">
            <button
              className="btn btn-circle hover:btn-error btn-sm"
              onClick={() => onDelete()}
            >
              <TrashIcon className="w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
