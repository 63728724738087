import {useContext, useEffect, useState} from 'react';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {DataNodeDefData} from '../../types/node';
import JsonView from '@uiw/react-json-view';
import {githubDarkTheme} from '@uiw/react-json-view/githubDark';
import {
  CheckIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import {UploadDocument, UploadType} from '../../../modals/UploadDocument';
import {UserContext} from '../../../../context';
import {AxiosProgressEvent} from 'axios';
import {UploadProgress} from '../../../UploadProgress';
import {MAX_FILE_SIZE} from '../../../../api/Client';
import {JsonValue} from '../../../../../bindings/serde_json/JsonValue';
import {Step} from '../../../../../bindings/Step';
import {JSONContentType} from '../../../../../bindings/JSONContentType';
import {toast} from 'react-toastify';
import {DocumentResponse} from '../../../../../bindings/api/DocumentResponse';
import {ApiResponse} from '../../../../../bindings/api/ApiResponse';
export class EmailContentNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Email Contents';
  public nodeLabel = 'Email Contents';
  public getNodeIcon(className?: string): JSX.Element {
    return <EnvelopeIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <SightglassEmailContent {...baseProps}></SightglassEmailContent>;
  }
  public createStep(): Step {
    return {
      nodeType: 'DataSource',
      data: {
        type: 'json',
        data: {
          content_type: 'UserEmailWebhookEvent',
          content: EXAMPLE as any as JsonValue,
        },
      },
      label: this.nodeLabel,
      parentNode: null,
      templateId: null,
      uuid: crypto.randomUUID(),
      inputSource: null,
    };
  }
}
export const SIGHTGLASS_EMAIL_CONTENT_NODE_DEF =
  new EmailContentNodeDefinition();

interface EmailExampleType {
  html: string;
  to: string;
  subject: string;
  attachments: number;
  text: string;
  from: string;
  attached_files: any[];
}

export const EXAMPLE: EmailExampleType = {
  html: '<div dir="ltr">Here you go<br><br><div class="gmail_quote"><div dir="ltr" class="gmail_attr">---------- Forwarded message ---------<br>From: <strong class="gmail_sendername" dir="auto">John Doe</strong> <span dir="auto">&lt;<a href="mailto:jdoe@example.com">jdoe@example.com</a>&gt;</span><br>Date: Fri, Mar 15, 2024 at 11:16 AM<br>Subject: Company Document<br>To:  &lt;<a href="mailto:test@docs.sightglass.ai">test@docs.sightglass.ai</a>&gt;<br></div><br><br><div dir="ltr">Please process the document</div>\n</div></div>\n',
  to: 'test@docs.sightglass.ai',
  subject: 'Fwd: Company Document',
  attachments: 0,
  text: 'Here you go\n\n---------- Forwarded message ---------\nFrom: John Doe <jdoe@example.com>\nDate: Fri, Mar 15, 2024 at 11:16 AM\nSubject: Company Document\nTo: <test@docs.sightglass.ai>\n\nPlease process the document\n',
  from: 'John Doe <jdoe@example.com>',
  attached_files: [],
};

enum ViewType {
  Email,
  Raw,
}

export function SightglassEmailContent({
  data,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const {client} = useContext(UserContext);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [example, setExample] = useState<EmailExampleType>(EXAMPLE);
  const [tempValue, setTempValue] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [component, setComponent] = useState<ViewType>(ViewType.Email);
  const [attachedDocument, setAttachedDocument] = useState<string>();
  const [uploadOpen, setUploadOpen] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<AxiosProgressEvent>();

  useEffect(() => {
    if (data) {
      const jsonData = data as DataNodeDefData;
      if (jsonData.content && Object.keys(jsonData.content).length > 0) {
        setExample(jsonData.content);
      }
    }
  }, [data]);

  useEffect(() => {
    if (
      example.attached_files &&
      Array.isArray(example.attached_files) &&
      example.attached_files.length > 0
    ) {
      setAttachedDocument(example.attached_files[0].doc_uuid);
    } else {
      setAttachedDocument(undefined);
    }
  }, [example]);

  const sendUpdate = (example: EmailExampleType | undefined) => {
    onUpdateData({
      content: example || {},
      content_type: 'UserEmailWebhookEvent' as JSONContentType,
    });
  };

  const saveEdit = () => {
    if (component === ViewType.Email) {
      const update = {...example};
      sendUpdate(update);
      setError(null);
    } else {
      try {
        const val = JSON.parse(tempValue);
        sendUpdate(val);
        setError(null);
      } catch (err) {
        setError('Invalid Json ' + err);
      }
    }

    setIsEditing(false);
  };

  const removeAttachment = (doc_uuid: string) => {
    client?.collection
      .deleteDocument('emailattachments', doc_uuid)
      .catch(console.error);
    const update = {...example};
    update.attachments = 0;
    update.attached_files = [];
    setAttachedDocument(undefined);
    sendUpdate(update);
  };

  const upload = (files: FileList) => {
    if (files.length > 0 && client) {
      const file = files.item(0);
      if (file) {
        if (file.size > MAX_FILE_SIZE) {
          toast.error(
            `File too large to upload, max 500 MB. Skipping ${file.name}`
          );
        } else {
          setIsUploading(true);
          client?.collection
            .uploadDocument(
              'emailattachments',
              file.name,
              file.size,
              file,
              (progressEvent: AxiosProgressEvent) => {
                setUploadProgress(progressEvent);
              }
            )
            .then((result: ApiResponse<DocumentResponse[]>) => {
              if (result.result && result.result.length > 0) {
                const doc = result.result[0];

                const updated = {...example};
                updated.attachments = 1;
                updated.attached_files = [
                  {
                    name: 'attachment1',
                    filename: doc.displayName,
                    doc_uuid: doc.uuid,
                    collection_uuid: 'emailattachments',
                    content_type: doc.contentType ?? 'example',
                  },
                ];

                sendUpdate(updated);
              }
            })
            .catch(err => {
              console.error(err);
              toast.error(`Network Error uploading ${file.name}!`);
            })
            .finally(() => {
              setIsUploading(false);
            });
        }
      }
    }
  };

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="text-base">
        Add an example email below to test your workflow manually.
      </div>
      <div className="tabs tabs-boxed" role="tablist">
        <a
          className={`tab ${component === ViewType.Email ? 'tab-active' : ''}`}
          onClick={event => {
            event.preventDefault();
            setComponent(ViewType.Email);
          }}
        >
          <EnvelopeIcon className="w-4 mr-2" />
          Email View
        </a>
        <a
          className={`tab ${component === ViewType.Raw ? 'tab-active' : ''}`}
          onClick={event => {
            event.preventDefault();
            setComponent(ViewType.Raw);
          }}
        >
          <DocumentTextIcon className="w-4 mr-2" />
          Raw View
        </a>
      </div>
      <div>
        <div className="flex justify-end py-2 w-full">
          {isEditing ? (
            <button
              className="btn btn-xs btn-success"
              onClick={() => saveEdit()}
            >
              <CheckIcon className="w-4" />
              save
            </button>
          ) : (
            <button
              className="btn btn-xs"
              onClick={() => {
                setIsEditing(true);
                setTempValue(JSON.stringify(example, undefined, 2));
              }}
            >
              <PencilSquareIcon className="w-4" />
              edit
            </button>
          )}
        </div>
      </div>
      {component === ViewType.Email ? (
        <div className="flex flex-col w-full">
          <div className="flex flex-row gap-2">
            <label className="form-control w-full max-w-xs">
              <div className="label">
                <span className="label-text">To</span>
              </div>
              <input
                type="text"
                placeholder="To"
                disabled={!isEditing}
                value={example.to}
                onChange={event =>
                  setExample({...example, to: event.target.value})
                }
                className="input input-bordered w-full max-w-xs"
              />
            </label>
            <label className="form-control w-full max-w-xs">
              <div className="label">
                <span className="label-text">From</span>
              </div>
              <input
                type="text"
                placeholder="From"
                disabled={!isEditing}
                value={example.from}
                onChange={event =>
                  setExample(ex => ({...ex, from: event.target.value}))
                }
                className="input input-bordered w-full max-w-xs"
              />
            </label>
          </div>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Subject</span>
            </div>
            <input
              type="text"
              placeholder="Subject"
              disabled={!isEditing}
              value={example.subject}
              onChange={event =>
                setExample(ex => ({...ex, subject: event.target.value}))
              }
              className="input input-bordered w-full "
            />
          </label>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Email Body</span>
            </div>
            <textarea
              className="textarea textarea-bordered h-32 w-full"
              disabled={!isEditing}
              placeholder="Email Body"
              onChange={event =>
                setExample(ex => ({...ex, text: event.target.value}))
              }
              value={example.text}
            ></textarea>
          </label>
        </div>
      ) : (
        <>
          {isEditing ? (
            <textarea
              className="textarea textarea-primary w-full h-48"
              defaultValue={JSON.stringify(example, undefined, 2)}
              onChange={event => {
                setTempValue(event.target.value);
              }}
            ></textarea>
          ) : (
            <JsonView value={example} style={githubDarkTheme} collapsed={1} />
          )}
          <span className="text-error text-xs">{error}</span>
        </>
      )}
      <label className="form-control w-full ">
        <div className="label">
          <span className="label-text">Attachments</span>
        </div>
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body p-4">
            {attachedDocument ? (
              <div className="flex flex-row justify-between items-center">
                <span>{example.attached_files[0].filename}</span>
                <div className="flex flex-row gap-2">
                  <button
                    className="btn btn-error btn-outline"
                    onClick={event => {
                      // Called to event from bubbling to newly created
                      // Upload button (after delete)
                      event.preventDefault();
                      removeAttachment(attachedDocument);
                    }}
                  >
                    <TrashIcon className="w-4 h-4"></TrashIcon>
                  </button>
                </div>
              </div>
            ) : isUploading ? (
              <div className="flex flex-row justify-center item-center gap-4">
                <span>Uploading</span>
                <UploadProgress progress={uploadProgress}></UploadProgress>
              </div>
            ) : (
              <div className="flex flex-row justify-center item-center">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => setUploadOpen(true)}
                >
                  Upload Example Attachment
                </button>
                <UploadDocument
                  header="Upload Example Attachment"
                  onClose={() => {
                    setUploadOpen(false);
                  }}
                  open={uploadOpen}
                  upload={files => {
                    setUploadOpen(false);
                    upload(files);
                  }}
                  type={UploadType.Any}
                />
              </div>
            )}
          </div>
        </div>
      </label>
    </div>
  );
}
