import {useEffect, useState, useRef, useContext, useCallback} from 'react';
import {i16PCMToLittleEndian} from '../../utils/waveUtils';
import useWebSocket from 'react-use-websocket';
import {
  TranscriptTextMessage,
  isFinalTranscript,
  isPartialTranscript,
  isSessionBegins,
  isSessionTerminated,
} from '../../api/messages';
import {
  PauseCircleIcon,
  PlayCircleIcon,
  TrashIcon,
  ExclamationTriangleIcon,
  CloudArrowUpIcon,
  MicrophoneIcon,
} from '@heroicons/react/20/solid';
import {useAuth0} from '@auth0/auth0-react';
import {rawAudioToMonoMp3} from '../../utils/mp3Utils';
import {UserContext} from '../../context';
import {isFeatureEnabled} from '../../utils/supportUtils';
import {InputSelection} from '../modals/AudioInputSelection';
import {CollectionType} from '../../api/client/collection';

const ASSISTANT_ENDPOINT = process.env.REACT_APP_ASSISTANT_WS_ENDPOINT;
const RECORDING_MAX_TIME = 7200000;
const SAVE_AUDIO_FEATURE = 'SaveNotedAudio';
const AUDIO_DB_NAME = 'AudioStorage';
const AUDIO_STORE = 'audiostash';
const AUDIO_FILE = 'audioblob';

export interface VoiceRecorderProps {
  onRecordingStart?: () => void;
  onRecordingStop?: () => void;
  onRecordingReset?: () => void;
  transcript: TranscriptTextMessage[];
  setTranscript: React.Dispatch<React.SetStateAction<TranscriptTextMessage[]>>;
}

export function VoiceRecorder({
  onRecordingStart = () => {},
  onRecordingStop = () => {},
  onRecordingReset = () => {},
  setTranscript,
}: VoiceRecorderProps) {
  const {profile, client} = useContext(UserContext);
  const {getAccessTokenSilently} = useAuth0();
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [recordingStartTime, setRecordingStartTime] = useState<number | null>(
    null
  );

  const [savingRecording, setSavingRecording] = useState<boolean>(false);

  const [collectionUuid, setCollectionUuid] = useState<string | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [hasFullRecording, setHasFullRecording] = useState<boolean>(false);
  const [socketUrl, setSocketUrl] = useState<string | null>(null);

  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const audioAnalyzer = useRef<AnalyserNode | undefined>();

  const {sendMessage, lastMessage} = useWebSocket(socketUrl);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [audioInput, setAudioInput] =
    useState<MediaStreamAudioSourceNode | null>();
  const [audioProcessor, setAudioProcessor] = useState<AudioWorkletNode | null>(
    null
  );
  const [recordingTimeRemaining, setRecordingTimeRemaining] = useState<
    number | null
  >(null);
  const [database, setDatabase] = useState<IDBDatabase>();
  const [inputDevices, setInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedInputDevice, setSelectedInputDevice] =
    useState<MediaDeviceInfo>();
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (profile && isFeatureEnabled(profile, SAVE_AUDIO_FEATURE)) {
      const dbOpenRequest = window.indexedDB.open(AUDIO_DB_NAME, 1);
      dbOpenRequest.onupgradeneeded = e => {
        if (e.target) {
          const target = e.target as IDBOpenDBRequest;
          const db = target.result;

          if (!db.objectStoreNames.contains(AUDIO_STORE)) {
            db.createObjectStore(AUDIO_STORE);
          }
        }
      };

      dbOpenRequest.onerror = e => {
        console.error('Error opening index db', e);
      };

      dbOpenRequest.onsuccess = e => {
        const target = e.target as IDBOpenDBRequest;
        const db = target.result;
        setDatabase(db);
      };
    }
  }, [profile]);

  useEffect(() => {
    client?.collection
      .list()
      .then(response => {
        for (const collection of response.result ?? []) {
          if (collection.name === 'Assistant Audio') {
            setCollectionUuid(collection.uuid);
          }
        }
      })
      .catch(console.error);

    navigator.mediaDevices.enumerateDevices().then(devices => {
      const allInputs = devices.filter(device => {
        return device.kind === 'audioinput';
      });
      const inputs = allInputs.filter(input => {
        return input.label.toLowerCase().indexOf(input.deviceId) === -1;
      });

      const defaultGroupInput = allInputs.find(input => {
        return input.deviceId === 'default';
      });

      if (defaultGroupInput) {
        const defaultInput = allInputs.find(input => {
          return (
            input.groupId === defaultGroupInput.groupId &&
            input.label.toLowerCase().indexOf(input.deviceId) === -1
          );
        });

        setSelectedInputDevice(defaultInput);
      } else if (inputs.length > 0) {
        setSelectedInputDevice(inputs[0]);
      }

      setInputDevices(inputs);
    });
  }, [client]);

  // Animate voice volume
  const animateRef = useRef<number | undefined>();
  const [voiceVolume, setVoiceVolume] = useState<number>(0);
  const animateVolume = useCallback(() => {
    if (animateRef.current !== undefined && audioAnalyzer.current) {
      const pcmData = new Float32Array(audioAnalyzer.current.fftSize);
      audioAnalyzer.current.getFloatTimeDomainData(pcmData);
      let sumSquares = 0.0;
      for (let i = 0; i < pcmData.length; i++) {
        sumSquares += pcmData[i] * pcmData[i];
      }

      const vol = Math.sqrt(sumSquares / pcmData.length) * 500;
      setVoiceVolume(vol > 100 ? 100 : vol);
    } else {
      setVoiceVolume(0);
    }

    animateRef.current = requestAnimationFrame(animateVolume);
  }, []);

  useEffect(() => {
    animateRef.current = requestAnimationFrame(animateVolume);
    return () => {
      if (animateRef.current) {
        cancelAnimationFrame(animateRef.current);
      }
    };
  }, [animateVolume]);

  const startListening = async function () {
    if (isRecording) {
      return;
    }

    setRecordingStartTime(Date.now());
    setIsRecording(true);
    onRecordingStart();

    // Activate websocket for data
    const access_token = await getAccessTokenSilently();
    let endpoint_url;
    if (ASSISTANT_ENDPOINT) {
      const endpoint = new URL(ASSISTANT_ENDPOINT);
      endpoint.searchParams.append('token', access_token);
      endpoint_url = endpoint.href;
      console.error(endpoint_url);
    }
    setSocketUrl(endpoint_url ?? '');

    // Create audio Context
    const constraints = {
      audio: {
        groupId: selectedInputDevice?.groupId,
      },
    };
    const context = new AudioContext({latencyHint: 'interactive'});
    console.debug('Sample Rate: ', context.sampleRate);

    // Register Processor
    await context.audioWorklet.addModule('./js/recorderWorkletProcessor.js');
    await context.audioWorklet.addModule('./js/fullQualityRecorder.js');
    context.resume();

    // Create Media Stream Input
    const userStream = await navigator.mediaDevices.getUserMedia(constraints);
    const input = context.createMediaStreamSource(userStream);

    // Load Processor Module and Connect
    const processor = new window.AudioWorkletNode(context, 'recorder.worklet');
    const fullprocessor = new window.AudioWorkletNode(
      context,
      'fullrecorder.worklet'
    );
    const analyzer = context.createAnalyser();
    processor.connect(context.destination);
    context.resume();

    if (profile && isFeatureEnabled(profile, 'SaveNotedAudio')) {
      fullprocessor.connect(context.destination);
      context.resume();
      input.connect(fullprocessor);
    }

    // Connect Input to Processor
    input.connect(analyzer);
    input.connect(processor);

    const buffer: Uint8Array[] = [];
    if (database) {
      const transaction = database.transaction([AUDIO_STORE], 'readwrite');
      const store = transaction.objectStore(AUDIO_STORE);
      const req = store.delete(AUDIO_FILE);

      req.onerror = () => {
        console.error('Error deleting audio blob');
      };
    }

    let timeout_set = false;
    fullprocessor.port.onmessage = audioEvent => {
      const audioData: ArrayBufferLike[] = audioEvent.data;

      if (database) {
        const transaction = database.transaction([AUDIO_STORE], 'readwrite');
        const store = transaction.objectStore(AUDIO_STORE);
        const getRequest = store.get(AUDIO_FILE);

        getRequest.onsuccess = e => {
          if (e.target) {
            let audio = getRequest.result;

            if (audio) {
              for (let i = 0; i < audioData.length; i++) {
                audio[i].push(audioData[i]);
              }
            } else {
              audio = [];
              for (let i = 0; i < audioData.length; i++) {
                audio.push([audioData[i]]);
              }
            }

            store.put(audio, AUDIO_FILE);
            setHasFullRecording(true);
          }
        };
      }
    };

    processor.port.onmessage = e => {
      // On Message add to the buffer
      const audioData: ArrayBufferLike = e.data;
      buffer.push(new Uint8Array(audioData));
      // Change to save to vfs so it is not in memory
      // Disable full recording saving until GUI is ready to go
      // fullRecording.buffer_full.push(new Uint8Array(audioData));
      // setHasFullRecording(true);

      // Send data on socket every half second
      if (!timeout_set) {
        setTimeout(() => {
          const buffer_len = buffer.length;
          let length = 0;
          for (let i = 0; i < buffer_len; i++) {
            length += buffer[i].length;
          }

          const merged = new Uint8Array(length);
          let offset = 0;
          buffer.forEach(item => {
            merged.set(item, offset);
            offset += item.length;
          });

          const wave = i16PCMToLittleEndian(merged.buffer);
          const wave_len = wave.length;
          let binary_str = '';
          for (let i = 0; i < wave_len; i++) {
            binary_str += String.fromCharCode(wave[i]);
          }
          const b64_str = btoa(binary_str);

          sendMessage(
            JSON.stringify({
              messageType: 'AudioMessage',
              audio_data: b64_str,
            })
          );
          buffer.splice(0, buffer_len);
          timeout_set = false;
        }, 500);
        timeout_set = true;
      }
    };

    audioAnalyzer.current = analyzer;
    setAudioContext(context);
    setStream(userStream);
    setAudioInput(input);
    setAudioProcessor(processor);
  };

  const stopListening = useCallback(() => {
    setIsRecording(false);
    onRecordingStop();
    setSocketUrl(null);
    setRecordingTimeRemaining(null);
    setRecordingStartTime(null);

    if (stream) {
      for (const track of stream.getTracks()) {
        track.stop();
      }
    }

    if (audioInput && audioProcessor && audioContext) {
      audioInput.disconnect(audioProcessor);
      audioProcessor.disconnect(audioContext.destination);
      audioContext.close().then(() => {
        audioAnalyzer.current = undefined;
        setAudioInput(null);
        setAudioProcessor(null);
        setAudioContext(null);
        setStream(null);
      });
    }
  }, [audioContext, audioInput, audioProcessor, onRecordingStop, stream]);

  const uploadAudio = async function (blob: Blob) {
    const date = Date.now().toString();
    const name = `noted_audio_${date}.mp3`;

    // Create collection if we don't already have one for audio.
    const collection_uuid = collectionUuid
      ? collectionUuid
      : await client?.collection
          .create('Assistant Audio', CollectionType.General)
          .then(resp => resp.result?.uuid);

    if (collection_uuid) {
      await client?.collection.uploadDocument(
        collection_uuid,
        name,
        blob.size,
        blob
      );
    }
  };

  const saveRecording = async function () {
    if (database) {
      const transaction = database.transaction([AUDIO_STORE], 'readwrite');
      const store = transaction.objectStore(AUDIO_STORE);
      setSavingRecording(true);
      const getRequest = store.get(AUDIO_FILE);

      getRequest.onsuccess = e => {
        if (e.target) {
          const audio = getRequest.result;
          const mergedChannels = [];

          for (let i = 0; i < audio.length; i++) {
            const buffer: Float32Array[] = audio[i];

            const buffer_len = buffer.length;
            let length = 0;
            for (let i = 0; i < buffer_len; i++) {
              length += buffer[i].length;
            }

            const merged = new Float32Array(length);
            let offset = 0;
            buffer.forEach(item => {
              merged.set(item, offset);
              offset += item.length;
            });

            mergedChannels.push(merged);
          }

          const blob = rawAudioToMonoMp3(mergedChannels);

          uploadAudio(blob)
            .catch(console.error)
            .finally(() => {
              setHasFullRecording(false);
              setSavingRecording(false);
            });

          // // const token = await getAccessTokenSilently();
          // if (collectionUuid) {
          //   await uploadDocument(token, collectionUuid, blob);
          // } else {
          //   const collectionDef = await addCollection(token, 'Assistant Audio');
          //   if (collectionDef.result) {
          //     setCollectionUuid(collectionDef.result.uuid);
          //     await uploadDocument(token, collectionDef.result.uuid, blob);
          //   }
          // }
        }
      };
    }
  };

  useEffect(() => {
    if (recordingStartTime !== null) {
      const timeTaken = Date.now() - recordingStartTime;
      // Give the user a 20 second warning
      if (timeTaken > RECORDING_MAX_TIME - 20000) {
        setRecordingTimeRemaining(RECORDING_MAX_TIME - timeTaken);
      }
    } else {
      setRecordingTimeRemaining(null);
    }
  }, [recordingStartTime]);

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const data = JSON.parse(lastMessage.data);

      if (isSessionBegins(data)) {
        setSessionId(data.session_id);
      } else if (isPartialTranscript(data)) {
        setTranscript(current => {
          let newMessages: TranscriptTextMessage[] = [];
          if (current && current.length > 0) {
            newMessages = [...current];
            const previous = newMessages[newMessages.length - 1];
            if (isPartialTranscript(previous)) {
              newMessages[newMessages.length - 1] = data;
            } else if (isFinalTranscript(previous)) {
              newMessages.push(data);
            }
          } else {
            newMessages.push(data);
          }

          return newMessages;
        });
      } else if (isFinalTranscript(data)) {
        setTranscript(current => {
          let newMessages: TranscriptTextMessage[] = [];
          if (current && current.length > 0) {
            newMessages = [...current];
            const previous = newMessages[newMessages.length - 1];
            if (isPartialTranscript(previous)) {
              newMessages[newMessages.length - 1] = data;
            } else if (isFinalTranscript(previous)) {
              newMessages.push(data);
            }
          } else {
            newMessages.push(data);
          }

          return newMessages;
        });
      } else if (isSessionTerminated(data)) {
        stopListening();
      }
    }
  }, [lastMessage]);

  return (
    <>
      <div className="flex flex-col gap-2 w-full">
        <progress
          className="progress w-full"
          value={voiceVolume}
          max="100"
        ></progress>
        <div className="flex flex-row items-center place-content-center gap-4 xs:flex-wrap">
          {isRecording ? (
            <button className="btn btn-error" onClick={() => stopListening()}>
              <PauseCircleIcon className="w-5" />
              Pause
            </button>
          ) : (
            <button className="btn btn-accent" onClick={() => startListening()}>
              <PlayCircleIcon className="w-5" />
              Start Listening
            </button>
          )}
          <button
            className="btn btn-error"
            onClick={() => onRecordingReset()}
            disabled={isRecording}
          >
            <TrashIcon className="w-5" />
            Reset
          </button>
          {!isRecording && hasFullRecording ? (
            <button
              disabled={savingRecording}
              className="btn btn-accent"
              onClick={() => saveRecording()}
            >
              {savingRecording ? (
                <span className="loading loading-spinner loading-md"></span>
              ) : (
                <CloudArrowUpIcon className="w-8 h-8" />
              )}
              Save Recording
            </button>
          ) : null}
          {inputDevices.length > 1 ? (
            <button
              className="btn btn-ghost"
              onClick={() => {
                if (dialogRef.current) {
                  dialogRef.current.showModal();
                }
              }}
            >
              <MicrophoneIcon className="w-4 h-4"></MicrophoneIcon>
              <span className="text-xs">
                {selectedInputDevice?.label.split('(')[0].trim()}
              </span>
            </button>
          ) : null}
        </div>
      </div>
      {recordingTimeRemaining !== null ? (
        <div role="alert" className="alert alert-warning">
          <ExclamationTriangleIcon className="w-8 h-8"></ExclamationTriangleIcon>
          <span>
            Reaching 2 hour recording limit.{' '}
            {Math.ceil(recordingTimeRemaining / 1000)} seconds remaining!
          </span>
        </div>
      ) : null}
      <InputSelection
        inputs={inputDevices}
        selectedInput={selectedInputDevice}
        setSelectedInput={selected => {
          setSelectedInputDevice(selected);
        }}
        dialogRef={dialogRef}
      ></InputSelection>
    </>
  );
}
