import {useContext, useEffect, useRef, useState} from 'react';
import {TranscriptTextMessage} from '../../api/messages';
import {VoiceRecorder} from '../../components/voice/recorder';
import {DateTime} from 'luxon';
import {UserContext} from '../../context';
import {TranscriptBox} from './TranscriptBox';
import {ActionItem, updateChecklist} from './actions';
import {RunningSummary} from './RunningSummary';
import {
  ChatBubbleBottomCenterTextIcon,
  ListBulletIcon,
  SparklesIcon,
} from '@heroicons/react/20/solid';
import {unstable_useBlocker as useBlocker} from 'react-router-dom';
import {ActionItems} from './ActionItems';
import {GoalItem} from '../../api/Client';
import {CheckList} from './CheckList';

const CHECKLIST_INTERVAL_MS = 30 * 1000;
const TRANSCRIPTION_LOCALSTORAGE = 'sg-audio-transcriptdata';

interface CompletedSchema {
  completed_goals: CompletedSchemaItem[];
}

interface CompletedSchemaItem {
  uuid: string;
  isCompleted: boolean;
}

// Keep track of various
interface AssistantMetrics {
  linesAnalyzed: number;
  tasksAnalyzed: number;
  analysisTimeSecs: number;
}

enum LiveComponent {
  Transcript,
  Summary,
  ActionItems,
}

export function Assistant() {
  const {client} = useContext(UserContext);
  const [assistantMetrics, setAssistantMetrics] = useState<
    AssistantMetrics | undefined
  >();
  const [isRecording, setIsRecording] = useState<boolean>(false);
  // Whether to show the live transcript or the live summary.
  const [liveComponent, setLiveComponent] = useState<LiveComponent>(
    LiveComponent.Transcript
  );
  // Keep track of both the transcript + the current checklist
  const [transcript, setTranscript] = useState<TranscriptTextMessage[]>([]);
  const [checklist, setCheckList] = useState<GoalItem[]>([]);
  // const [triggers, setTriggeredConnections] = useState<TriggeredConnection[]>(
  //   []
  // );

  const [lastGoalCheckTime, setLastGoalCheckTime] = useState<
    DateTime | undefined
  >();
  const [isAnalyzingChecklist, setIsAnalyzingChecklist] =
    useState<boolean>(false);
  const [actionItems, setActionItems] = useState<ActionItem[]>([]);

  const [updateActionItems, setUpdateActionItems] = useState<boolean>(true);
  const [summary, setSummary] = useState<string>();
  const [updateSummary, setUpdateSummary] = useState<boolean>(true);
  const [lastTranscriptRow, setLastTranscriptRow] = useState<number>(0);
  const warningDialog = useRef<HTMLDialogElement>(null);

  const resetListening = () => {
    setIsAnalyzingChecklist(false);
    setLastGoalCheckTime(undefined);
    setTranscript([]);
    setLastTranscriptRow(0);
    setIsRecording(false);
    setSummary(undefined);
    setActionItems([]);
    setCheckList(checklist =>
      checklist.map(item => {
        item.isComplete = false;
        return item;
      })
    );
  };

  useEffect(() => {
    if (localStorage) {
      const currentData = localStorage.getItem(TRANSCRIPTION_LOCALSTORAGE);
      if (currentData) {
        const dataObj = JSON.parse(currentData);
        if (dataObj.summary && dataObj.summary.length > 0) {
          setSummary(dataObj.summary);
        }

        if (dataObj.actionItems && dataObj.actionItems.length > 0) {
          setActionItems(dataObj.actionItems);
        }

        if (dataObj.transcript && dataObj.transcript.length > 0) {
          setTranscript(dataObj.transcript);
        }

        if (
          dataObj.transcript &&
          dataObj.transcript.length > 0 &&
          (!dataObj.summary || dataObj.summary.length <= 0)
        ) {
          setUpdateSummary(true);
        }

        if (
          dataObj.transcript &&
          dataObj.transcript.length > 0 &&
          (!dataObj.actionItems || dataObj.actionItems.length <= 0)
        ) {
          setUpdateActionItems(true);
        }
      }
    }
  }, [client]);

  useEffect(() => {
    setUpdateActionItems(false);
  }, [actionItems]);

  useEffect(() => {
    setUpdateSummary(false);
  }, [summary]);

  useEffect(() => {
    const transcriptData = {
      summary,
      actionItems,
      transcript,
    };

    if (localStorage) {
      localStorage.setItem(
        TRANSCRIPTION_LOCALSTORAGE,
        JSON.stringify(transcriptData)
      );
    }
  }, [summary, actionItems, transcript]);

  // Handle updates to the transcript
  useEffect(() => {
    if (isRecording && transcript && transcript.length > 0) {
      const now = DateTime.now();

      // Check for whether a new goal has been achieved every ~5 seconds. It takes
      // roughly that long to get a response back from the API.
      if (
        lastGoalCheckTime &&
        now.diff(lastGoalCheckTime).milliseconds > CHECKLIST_INTERVAL_MS
      ) {
        if (
          client &&
          !isAnalyzingChecklist &&
          lastTranscriptRow !== transcript.length
        ) {
          setIsAnalyzingChecklist(true);
          console.debug(
            'analyzing transcript against checklist, starting at row ',
            lastTranscriptRow
          );

          let checklistTranscript: TranscriptTextMessage[] = [];
          if (lastTranscriptRow > 0) {
            const start = Math.max(lastTranscriptRow - 10, 0);
            for (let i = start; i < transcript.length; i++) {
              checklistTranscript.push(transcript[i]);
            }
            setLastTranscriptRow(transcript.length);
          } else {
            checklistTranscript = [...transcript];
            setLastTranscriptRow(transcript.length);
          }
          updateChecklist(client, checklist, checklistTranscript)
            .then(result => {
              console.debug(result);

              if (result?.result) {
                const {completed_goals} = result.result
                  .content as CompletedSchema;
                console.debug('updating checklist: ', completed_goals);
                setAssistantMetrics({
                  linesAnalyzed: checklistTranscript.length,
                  tasksAnalyzed: checklist.length,
                  analysisTimeSecs: result.time,
                });

                setCheckList(goals => {
                  const completedUUIDs = completed_goals.flatMap(x =>
                    x.isCompleted ? [x.uuid] : []
                  );
                  return goals.map(x => {
                    if (completedUUIDs.indexOf(x.uuid) !== -1) {
                      const updated = {...x};
                      updated.isComplete = true;
                      return updated;
                    }
                    return x;
                  });
                });
              }
            })
            .catch(console.error)
            .finally(() => {
              setLastGoalCheckTime(DateTime.now());
              setIsAnalyzingChecklist(false);
            });
        }

        if (lastTranscriptRow === transcript.length) {
          console.debug(
            'No new text has been added to the call, skipping goal check'
          );
        }
      } else {
        console.debug('lastGoalCheckTime <= 5 secs');
      }
    }
  }, [
    transcript,
    checklist,
    client,
    isAnalyzingChecklist,
    isRecording,
    lastGoalCheckTime,
    lastTranscriptRow,
  ]);

  const blocker = useBlocker(
    ({currentLocation, nextLocation}) =>
      isRecording && currentLocation.pathname !== nextLocation.pathname
  );

  if (blocker && blocker.state === 'blocked' && isRecording) {
    if (warningDialog.current && !warningDialog.current.open) {
      console.error('dialog opening');
      warningDialog.current.showModal();
    }
  }

  return (
    <div>
      <div className="flex flex-col gap-4 max-w-[640px] mx-auto">
        <VoiceRecorder
          onRecordingStart={() => {
            setLastGoalCheckTime(DateTime.now());
            setIsRecording(true);
            setUpdateActionItems(true);
            setUpdateSummary(true);
          }}
          onRecordingStop={() => setIsRecording(false)}
          onRecordingReset={() => resetListening()}
          transcript={transcript}
          setTranscript={setTranscript}
        />

        <div className="join mt-8 items-center text-sm">
          <button
            className={`btn btn-sm join-item ${
              liveComponent === LiveComponent.Transcript ? 'btn-primary' : ''
            }`}
            onClick={() => setLiveComponent(LiveComponent.Transcript)}
          >
            <ChatBubbleBottomCenterTextIcon className="w-4" />
            Transcript
          </button>
          <button
            className={`btn btn-sm join-item ${
              liveComponent === LiveComponent.Summary ? 'btn-primary' : ''
            }`}
            onClick={() => setLiveComponent(LiveComponent.Summary)}
          >
            <SparklesIcon className="w-4" />
            Summary
          </button>
          <button
            className={`btn btn-sm join-item ${
              liveComponent === LiveComponent.ActionItems ? 'btn-primary' : ''
            }`}
            onClick={() => setLiveComponent(LiveComponent.ActionItems)}
          >
            <ListBulletIcon className="w-4" />
            Tasks
          </button>
        </div>

        <div className="w-full">
          {liveComponent === LiveComponent.Transcript ? (
            <TranscriptBox transcript={transcript} isRecording={isRecording} />
          ) : liveComponent === LiveComponent.ActionItems ? (
            <ActionItems
              enabled={
                liveComponent === LiveComponent.ActionItems && updateActionItems
              }
              transcript={transcript}
              currentActionItems={actionItems}
              transcriptionRunning={isRecording}
              updateActionItems={items => {
                setActionItems(items);
              }}
            />
          ) : (
            <RunningSummary
              transcriptionRunning={isRecording}
              summary={summary}
              enabled={liveComponent === LiveComponent.Summary && updateSummary}
              transcript={transcript}
              onSummarize={newSummary => {
                setSummary(newSummary);
              }}
            />
          )}
        </div>

        <div className="divider"></div>
        <CheckList
          isAnalyzing={isAnalyzingChecklist}
          checklist={checklist}
          setCheckList={setCheckList}
        />

        {/* <div className="divider"></div> */}
        {/* <TriggerList triggers={triggers} setTriggers={setTriggeredConnections} /> */}

        {process.env.NODE_ENV === 'development' ? (
          <AssistantMetricsPanel
            assistantMetrics={assistantMetrics}
            isAnalyzingChecklist={isAnalyzingChecklist}
            lastGoalCheck={lastGoalCheckTime}
            transcript={transcript}
          />
        ) : null}
      </div>
      <dialog
        ref={warningDialog}
        id="warning_dialog_block"
        className="modal modal-bottom sm:modal-middle"
      >
        <div className="modal-box">
          <h3 className="font-bold text-lg">Recording In Progress</h3>
          <p className="py-4">
            Please pause recording before navigating to another page
          </p>
          <div className="modal-action">
            <form method="dialog">
              <button
                className="btn"
                onClick={() => {
                  blocker.reset?.();
                }}
              >
                Close
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
}

interface AssistantMetricsPanelProps {
  assistantMetrics?: AssistantMetrics;
  isAnalyzingChecklist: boolean;
  lastGoalCheck?: DateTime;
  transcript: TranscriptTextMessage[];
  onHidePanel?: () => void;
}

function AssistantMetricsPanel({
  assistantMetrics,
  isAnalyzingChecklist,
  lastGoalCheck,
}: AssistantMetricsPanelProps) {
  // Only show metrics in devmode
  if (process.env.NODE_ENV === 'production') {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="font-mono">
        <div>
          {isAnalyzingChecklist ? (
            <span className="text-success font-bold">Analyzing...</span>
          ) : (
            <span className="text-error">Not Analyzing</span>
          )}
        </div>
        <div>
          Last Analysis time (s):&nbsp;
          <span className="text-success">
            {lastGoalCheck
              ? (DateTime.now().diff(lastGoalCheck).toMillis() / 1000).toFixed(
                  2
                )
              : 'N/A'}
          </span>
        </div>
        <div className="flex flex-col mt-4">
          <div className="font-bold text-primary">Last Analysis Details</div>
          <div>
            Lines analyzed:&nbsp;
            <span className="text-success">
              {assistantMetrics?.linesAnalyzed ?? 'N/A'}
            </span>
          </div>
          <div>
            Incomplete Tasks:&nbsp;
            <span className="text-success">
              {assistantMetrics?.tasksAnalyzed ?? 'N/A'}
            </span>
          </div>
          <div>
            Analysis time (s):&nbsp;
            <span className="text-success">
              {assistantMetrics?.analysisTimeSecs.toFixed(2) ?? 'N/A'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
