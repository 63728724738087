import {useEffect, useState} from 'react';
import {
  LOCAL_TOKEN_STORAGE,
  UserConnection,
  authorizeConnection,
  deleteConnection,
  listConnections,
  requestConnection,
} from '../api/Client';
import {useAuth0} from '@auth0/auth0-react';
import {useParams} from 'react-router-dom';
import {TrashIcon} from '@heroicons/react/20/solid';
import {createAuth0Client} from '@auth0/auth0-spa-js';
import {
  SiGooglesheets,
  SiHubspot,
  SiMicrosoftoutlook,
} from '@icons-pack/react-simple-icons';
import {MicrosoftTodoIcon} from '../components/icons/microsofttodo';
import {RingCentralIcon} from '../components/icons/ringcentral';

function Connections() {
  const [connections, setConnections] = useState<UserConnection[]>([]);
  const {isAuthenticated, getAccessTokenSilently, isLoading} = useAuth0();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getAccessTokenSilently()
        .then(token => listConnections(token))
        .then(resp => {
          if (resp.result) {
            setConnections(resp.result);
          }
        });
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  const onAddConnection = (apiId: string) => {
    getAccessTokenSilently()
      .then(token => requestConnection(token, apiId))
      .then(resp => {
        if (resp.result) {
          window.open(resp.result.url, 'blank');
        }
      });
  };

  const onDeleteConnection = (id: number) => {
    getAccessTokenSilently().then(token =>
      deleteConnection(token, id).then(() => {
        listConnections(token).then(resp => {
          if (resp.result) {
            setConnections(resp.result);
          }
        });
      })
    );
  };

  return (
    <div className="flex flex-col gap-4 w-full mb-32 max-w-[960px] mx-auto">
      <div>
        <h1 className="text-2xl font-bold pb-2">Available Connections</h1>
        <table className="table table-auto table-zebra text-lg w-1/2">
          <tbody>
            <tr>
              <td>
                <div className="flex flex-row gap-2 content-center items-center">
                  <SiGooglesheets className="w-4 h-4 text-[#34A853]"></SiGooglesheets>
                  <span>Google Sheets</span>
                </div>
              </td>
              <td className="text-center">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => onAddConnection('sheets.google.com')}
                >
                  Add
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex flex-row gap-2 content-center items-center">
                  <SiHubspot className="w-4 h-4 text-[#FF7A59]"></SiHubspot>
                  <span>Hubspot</span>
                </div>
              </td>
              <td className="text-center">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => onAddConnection('hubspot.com')}
                >
                  Add
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex flex-row gap-2 content-center items-center">
                  <MicrosoftTodoIcon className="w-4 h-4"></MicrosoftTodoIcon>
                  <span>Microsoft 365 To Do</span>
                </div>
              </td>
              <td className="text-center">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => onAddConnection('graph.microsoft.com+tasks')}
                >
                  Add
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex flex-row gap-2 content-center items-center">
                  <SiMicrosoftoutlook className="w-4 h-4 text-[#0078D4]"></SiMicrosoftoutlook>
                  <span>Microsoft 365 Outlook</span>
                </div>
              </td>
              <td className="text-center">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => onAddConnection('graph.microsoft.com+outlook')}
                >
                  Add
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex flex-row gap-2 content-center items-center">
                  <RingCentralIcon className="w-4 h-4"></RingCentralIcon>
                  <span>RingCentral</span>
                </div>
              </td>
              <td className="text-center">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => onAddConnection('ringcentral.com')}
                >
                  Add
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="divider"></div>
      <div>
        <h1 className="text-2xl font-bold pb-2">Added Connections</h1>
        <table className="table table-auto table-zebra">
          <thead>
            <tr>
              <th>Name</th>
              <th>Account</th>
              <th>Date Added</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {connections.map(conn => (
              <tr key={`${conn.apiId}-${conn.account}`}>
                <td>
                  <div className="flex flex-row gap-3 content-center items-center">
                    {conn.apiId === 'sheets.google.com' ? (
                      <SiGooglesheets className="join-item w-4 h-4 text-[#34A853]"></SiGooglesheets>
                    ) : conn.apiId === 'hubspot.com' ? (
                      <SiHubspot className="join-item w-4 h-4 text-[#FF7A59]"></SiHubspot>
                    ) : conn.apiId === 'graph.microsoft.com+tasks' ? (
                      <MicrosoftTodoIcon className="join-item w-4 h-4"></MicrosoftTodoIcon>
                    ) : conn.apiId === 'graph.microsoft.com+outlook' ? (
                      <SiMicrosoftoutlook className="join-item w-4 h-4 text-[#0078D4]"></SiMicrosoftoutlook>
                    ) : conn.apiId === 'ringcentral.com' ? (
                      <RingCentralIcon className="join-item w-4 h-4"></RingCentralIcon>
                    ) : null}
                    <span>
                      {conn.apiId === 'graph.microsoft.com+tasks'
                        ? 'Microsoft 365 To Do'
                        : conn.apiId === 'graph.microsoft.com+outlook'
                        ? 'Microsoft 365 Outlook'
                        : conn.apiId}
                    </span>
                  </div>
                </td>
                <td>{conn.account}</td>
                <td>{conn.grantedAt}</td>
                <td>
                  <button
                    className="btn btn-error btn-sm"
                    onClick={() => onDeleteConnection(conn.id)}
                  >
                    <TrashIcon className="w-4 h-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Connections;

export function ConnectionCallback() {
  const {apiId} = useParams();
  const [token, setToken] = useState<string | null>(null);
  const [isAuthFinished, setIsAuthFinished] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      // Check if we have an API key stored
      const token = localStorage.getItem(LOCAL_TOKEN_STORAGE);
      if (token) {
        console.debug('local api key found');
        setToken(token);
      } else {
        console.debug('trying auth0 token');
        // Check if the user is authenticated w/ Auth0. We create a client
        // manually since this component does not sit within the Auth0 SPA
        // context.
        const client = await createAuth0Client({
          cacheLocation: 'localstorage',
          useRefreshTokens: true,
          domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
          clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
          authorizationParams: {
            redirect_uri: window.location.origin + '/authorize',
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          },
        });

        if (await client.isAuthenticated()) {
          const token = await client.getTokenSilently();
          setToken(token);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (apiId && token) {
      const query = new URLSearchParams(window.location.search);
      const body = {
        scope: query.get('scope'),
        state: query.get('state'),
        code: query.get('code'),
      };

      authorizeConnection(token, apiId, body)
        .then(() => {
          setIsAuthFinished(true);
          // clear api key on completion
          localStorage.removeItem(LOCAL_TOKEN_STORAGE);
        })
        .catch(err => {
          setIsAuthFinished(true);
          setError(err.message || err.toString());
        });
    }
  }, [apiId, token]);

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content text-center flex flex-col items-center">
        <div className="flex flex-row items-center gap-4 text-lg font-bold">
          <img
            src={`${process.env.PUBLIC_URL}/logo@2x.png`}
            className="w-16"
            alt="Sightglass Logo"
          />
          {isAuthFinished
            ? error
              ? 'Authorization Failed'
              : 'Authorization Complete. You can close this window!'
            : 'Checking connection ...'}
        </div>
        {!isAuthFinished && !error && (
          <progress className="progress w-full"></progress>
        )}
        {error && (
          <div className="text-error text-lg font-bold">
            Unable to complete authorization.
          </div>
        )}
      </div>
    </div>
  );
}
