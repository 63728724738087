import {useContext, useEffect, useState} from 'react';
import {ArrowPathIcon} from '@heroicons/react/20/solid';
import {TranscriptTextMessage} from '../../api/messages';
import {UserContext} from '../../context';
import {summarize} from './actions';
import {CopyToClipboard} from '../../components/copyText';

interface RunningSummaryProps {
  transcript: TranscriptTextMessage[];
  enabled: boolean;
  transcriptionRunning: boolean;
  summary: string | undefined;
  onSummarize: (newSummary: string) => void;
}

export function RunningSummary({
  enabled,
  transcript,
  transcriptionRunning,
  summary,
  onSummarize,
}: RunningSummaryProps) {
  const {client} = useContext(UserContext);
  const [isSummarizing, setIsSummarizing] = useState<boolean>(false);

  useEffect(() => {
    if (
      enabled &&
      transcript &&
      transcript.length > 0 &&
      !transcriptionRunning
    ) {
      // Summarize existing transcript
      if (client && !isSummarizing) {
        setIsSummarizing(true);
        console.debug('summarizing transcript');
        summarize(client, transcript)
          .then(summary => {
            if (summary?.result?.message) {
              const newSummary = summary.result.message;
              onSummarize(newSummary);
            }
          })
          .finally(() => {
            setIsSummarizing(false);
          });
      }
    }
  }, [
    client,
    transcriptionRunning,
    enabled,
    isSummarizing,
    onSummarize,
    transcript,
  ]);

  return (
    <div className="flex flex-col gap-2 mx-auto w-full ">
      <div className="text-gray-500 text-sm text-center">
        Summary of your conversation. Updated when transcription is paused.
      </div>
      <div className="p-4 bg-neutral rounded">
        <pre className="text-sm overflow-auto">
          {isSummarizing ? (
            <ArrowPathIcon className="w-6 animate-spin mx-auto" />
          ) : summary && summary.length > 0 ? (
            summary
          ) : (
            '...'
          )}
        </pre>
      </div>
      <CopyToClipboard
        disabled={!summary || summary.length === 0}
        getText={() => {
          return summary;
        }}
      >
        Copy Summary
      </CopyToClipboard>
    </div>
  );
}
