import {UserContext} from '../context';
import {useContext, useEffect, useState} from 'react';
import {DetailedDocumentResponse} from '../../bindings/api/DetailedDocumentResponse';
import {useLoaderData} from 'react-router-dom';
import {BlockEditor} from '../components/editor/components/BlockEditor';

export interface EditorProps {
  collectionUuid: string;
  documentUuid: string;
}

export function Editor() {
  const {client} = useContext(UserContext);
  const {collectionUuid, documentUuid} = useLoaderData() as EditorProps;

  const [document, setDocument] = useState<DetailedDocumentResponse>();

  useEffect(() => {
    client?.collection
      .getDocumentDetail(collectionUuid, documentUuid)
      .then(result => {
        if (result && result.result) {
          setDocument(result.result);
        }
      });
  }, [collectionUuid, documentUuid, client]);

  return (
    <BlockEditor
      document={document}
      collectionUuid={collectionUuid}
      documentUuid={documentUuid}
    />
  );
}
