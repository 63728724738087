import {DateTime} from 'luxon';
import {TranscriptTextMessage} from '../../api/messages';
import {useEffect, useRef} from 'react';
import {CopyToClipboard} from '../../components/copyText';

interface TranscriptLineProps {
  data: TranscriptTextMessage;
  isCurrent: boolean;
  isListening: boolean;
}

export function TranscriptLine({
  data,
  isCurrent,
  isListening,
}: TranscriptLineProps) {
  // Timestamp comes in as UTC, but does not specify the timezone in the string,
  // so we manually specify UTC then set it to system timezone for
  // displaying.
  const time = DateTime.fromISO(data.created, {
    zone: 'UTC',
  }).setZone('system');
  const formattedTime = time.toLocaleString(DateTime.TIME_WITH_SECONDS);

  const lineStyle =
    isCurrent && isListening ? 'text-sm bg-success text-success-content' : '';
  let textString;
  if (data.text.length === 0 && isCurrent && isListening) {
    textString = (
      <div className="flex flex-row items-center">
        listening <span className="mt-2 loading loading-dots loading-xs"></span>
      </div>
    );
  } else {
    textString = data.text.length > 0 ? data.text : '';
  }

  return (
    <div className={`flex flex-row px-8 items-center ${lineStyle}`}>
      {time.isValid ? (
        <div className="mr-4 flex-none">{formattedTime}</div>
      ) : null}
      <div>{textString}</div>
    </div>
  );
}

interface TranscriptBoxProps {
  transcript: TranscriptTextMessage[];
  isRecording: boolean;
}

export function TranscriptBox({transcript, isRecording}: TranscriptBoxProps) {
  // Continuously scroll to the last line of the transcript
  const lastLineRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (transcript.length > 0 && lastLineRef.current && isRecording) {
      lastLineRef.current.scrollTop = lastLineRef.current.scrollHeight;
    }
  }, [transcript, isRecording]);

  return (
    <div className="card bg-neutral-900 flex flex-col w-full text-gray-500 font-mono">
      <div
        ref={lastLineRef}
        className="card-body px-0 overflow-y-scroll max-h-48 text-sm py-4"
      >
        {transcript.length === 0 ? (
          <div className="flex flex-row px-4">
            <div>
              click{' '}
              <span className="text-success font-bold">Start Listening</span> to
              begin
            </div>
          </div>
        ) : null}
        {transcript.map((data, idx) => (
          <TranscriptLine
            key={`transcript-line-${idx}`}
            data={data}
            isCurrent={idx === transcript.length - 1}
            isListening={isRecording}
          />
        ))}
      </div>
      <CopyToClipboard
        disabled={transcript.length === 0}
        getText={() => {
          return transcript.map(x => `${x.text}`).join('\n');
        }}
      >
        Copy Transcript
      </CopyToClipboard>
    </div>
  );
}
