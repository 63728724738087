import {useContext, useEffect, useState} from 'react';
import {DataNodeDefData, NodeType} from '../../types/node';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {UserContext} from '../../../../context';
import {UserConnection} from '../../../../api/Client';
import {DestinationConnectionData} from '../../../../../bindings/DestinationConnectionData';
import {Step} from '../../../../../bindings/Step';
import {MicrosoftTodoIcon} from '../../../icons/microsofttodo';

export class MicrosoftToDoNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Microsoft To Do';
  public nodeLabel = 'Add Microsoft To Do';
  public getNodeIcon(className?: string): JSX.Element {
    return <MicrosoftTodoIcon className={className}></MicrosoftTodoIcon>;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <MicrosoftToDoNode {...baseProps}></MicrosoftToDoNode>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'DataDestination',
      templateId: NodeType.MicrosoftTodo,
      data: {
        connectionData: {
          connectionType: 'MicrosoftTodo',
          action: 'append',
          connectionId: '',
          sheetId: null,
          spreadsheetId: null,
        },
      },
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const MICROSOFT_TODO_NODE_DEF = new MicrosoftToDoNodeDefinition();

export default function MicrosoftToDoNode({
  data,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const {client} = useContext(UserContext);

  const nodeData = data as DataNodeDefData;
  const connectionData = nodeData.connectionData as DestinationConnectionData;
  const [userConns, setUserConns] = useState<UserConnection[]>([]);
  const [connectionId, setConnectionId] = useState<string>('not-selected');

  useEffect(() => {
    if (client) {
      client.listConnections().then(result => {
        const connections = result.result;
        if (connections) {
          setUserConns(
            connections.filter(
              connection => connection.apiId === 'graph.microsoft.com+tasks'
            )
          );
        }
      });
    }
  }, [client]);

  useEffect(() => {
    if (connectionData) {
      if (connectionData.connectionId.trim().length !== 0) {
        setConnectionId(connectionData.connectionId);
      }
    }
  }, [connectionData]);

  const updateNodeData = (newData: Partial<DestinationConnectionData>) => {
    onUpdateData({
      connectionData: {
        action: newData.action ?? connectionData.action,
        connectionId: newData.connectionId ?? connectionData.connectionId,
        connectionType: newData.connectionType ?? connectionData.connectionType,
        spreadsheetId: newData.spreadsheetId ?? connectionData.spreadsheetId,
        sheetId: newData.sheetId ?? connectionData.sheetId,
      },
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="join items-center bg-base-100">
        <div className="join-item pl-4">
          <MicrosoftTodoIcon className="join-item w-4 h-4"></MicrosoftTodoIcon>
        </div>
        <select
          className="input join-item w-full placeholder:text-gray-700"
          onChange={event => {
            const connectionId = event.target.value;
            if (connectionId) {
              console.error('Connection id stuff ', connectionId);
              setConnectionId(connectionId);
              updateNodeData({
                connectionId,
              });
            }
          }}
          value={connectionId}
        >
          <option disabled value="not-selected">
            Select an account
          </option>
          {userConns.map((conn, idx) => (
            <option key={`conn-${idx}`} value={conn.id}>
              {conn.account}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
