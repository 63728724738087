import {CheckoutResponse, SightglassClient} from '../Client';
import {ApiResponse} from '../../../bindings/api/ApiResponse';

export class PaymentEndpoints {
  client: SightglassClient;

  constructor(client: SightglassClient) {
    this.client = client;
  }

  async checkout(lookupKey: string): Promise<ApiResponse<CheckoutResponse>> {
    return this.client.postJson<CheckoutResponse>('checkout', {lookupKey});
  }
}
