import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../../../context';
import {WorkflowTrigger} from '../../../../../bindings/WorkflowTrigger';
import {TriggerDefinition, TriggerProps} from '../../nodes';
import {UserConnection} from '../../../../api/Client';
import {RingCentralIcon} from '../../../icons/ringcentral';
import {
  InboxArrowDownIcon,
  PhoneArrowDownLeftIcon,
  PhoneXMarkIcon,
} from '@heroicons/react/20/solid';

enum TriggerType {
  Voicemail,
  Recording,
  Both,
}

export class RingCentralTriggerDefinition implements TriggerDefinition {
  public defaultTriggerConfig: WorkflowTrigger = {
    collection: null,
    email: null,
    hubspot: null,
    microsoft: null,
    schedule: null,
    ringcentral: {
      connectionUuid: '',
      triggerType: {
        triggerType: 'newVoicemailOrRecording',
        excludeExtensions: null,
        includeExtensions: null,
      },
    },
  };
  public renderTrigger(baseProps: TriggerProps): JSX.Element {
    return <RingCentralTriggerConfig {...baseProps}></RingCentralTriggerConfig>;
  }
}

export const RINGCENTRAL_TRIGGER_DEF = new RingCentralTriggerDefinition();

export function RingCentralTriggerConfig({
  config,
  onUpdate = () => {},
}: TriggerProps) {
  const {client} = useContext(UserContext);

  const [userConns, setUserConns] = useState<UserConnection[]>([]);
  const [connectionId, setConnectionId] = useState<string>('not-selected');
  const [component, setComponent] = useState<TriggerType>(TriggerType.Both);

  useEffect(() => {
    if (client) {
      client.listConnections().then(result => {
        const connections = result.result;
        if (connections) {
          setUserConns(
            connections.filter(
              connection => connection.apiId === 'ringcentral.com'
            )
          );
        }
      });
    }
  }, [client]);

  useEffect(() => {
    const connection = config.ringcentral?.connectionUuid;
    if (config && connection && connection.trim().length > 0) {
      setConnectionId(connection);
    }

    const triggerType = config.ringcentral?.triggerType.triggerType;
    if (triggerType === 'newCallRecording') {
      setComponent(TriggerType.Recording);
    } else if (triggerType === 'newVoicemail') {
      setComponent(TriggerType.Voicemail);
    } else if (triggerType === 'newVoicemailOrRecording') {
      setComponent(TriggerType.Both);
    }
  }, [config]);

  const updateTriggerType = (
    triggerType: 'newCallRecording' | 'newVoicemail' | 'newVoicemailOrRecording'
  ) => {
    if (onUpdate) {
      onUpdate({
        trigger: {
          collection: null,
          email: null,
          hubspot: null,
          schedule: null,
          microsoft: null,
          ringcentral: {
            connectionUuid: connectionId,
            triggerType: {
              triggerType: triggerType,
              excludeExtensions: null,
              includeExtensions: null,
            },
          },
        },
      });
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="tabs tabs-boxed" role="tablist">
        <a
          className={`tab ${
            component === TriggerType.Recording ? 'tab-active' : ''
          }`}
          onClick={event => {
            event.preventDefault();

            updateTriggerType('newCallRecording');
          }}
        >
          <PhoneArrowDownLeftIcon className="w-4 mr-2" />
          Call Recording
        </a>
        <a
          className={`tab ${
            component === TriggerType.Both ? 'tab-active' : ''
          }`}
          onClick={event => {
            event.preventDefault();
            updateTriggerType('newVoicemailOrRecording');
          }}
        >
          <InboxArrowDownIcon className="w-4 mr-2" />
          Both
        </a>
        <a
          className={`tab ${
            component === TriggerType.Voicemail ? 'tab-active' : ''
          }`}
          onClick={event => {
            event.preventDefault();
            updateTriggerType('newVoicemail');
          }}
        >
          <PhoneXMarkIcon className="w-4 mr-2" />
          Voicemail
        </a>
      </div>
      <div className="join items-center bg-base-100">
        <div className="join-item pl-4">
          <RingCentralIcon className="join-item w-4 h-4"></RingCentralIcon>
        </div>
        <select
          className="input join-item w-full placeholder:text-gray-700"
          onChange={event => {
            const connectionId = event.target.value;
            if (connectionId) {
              setConnectionId(connectionId);
              if (onUpdate) {
                onUpdate({
                  trigger: {
                    collection: null,
                    email: null,
                    hubspot: null,
                    schedule: null,
                    microsoft: null,
                    ringcentral: {
                      connectionUuid: connectionId,
                      triggerType: {
                        triggerType: 'newVoicemail',
                        excludeExtensions: null,
                        includeExtensions: null,
                      },
                    },
                  },
                });
              }
            }
          }}
          value={connectionId}
        >
          <option disabled value="not-selected">
            Select an account
          </option>
          {userConns.map((conn, idx) => (
            <option key={`conn-${idx}`} value={conn.id}>
              {conn.account}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
