import {useEffect, useState} from 'react';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {DataNodeDefData} from '../../types/node';
import JsonView from '@uiw/react-json-view';
import {githubDarkTheme} from '@uiw/react-json-view/githubDark';
import {
  CheckIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  PencilSquareIcon,
} from '@heroicons/react/20/solid';
import {JsonValue} from '../../../../../bindings/serde_json/JsonValue';
import {Step} from '../../../../../bindings/Step';
import {JSONContentType} from '../../../../../bindings/JSONContentType';
import {SiMicrosoftoutlook} from '@icons-pack/react-simple-icons';

export const EXAMPLE = {
  id: 'ABC1234567890',
  body: {
    content:
      '<html><head>\r\n<meta http-equiv="Content-Type" content="text/html; charset=utf-8"><style type="text/css" style="display:none">\r\n<!--\r\np\r\n\t{margin-top:0;\r\n\tmargin-bottom:0}\r\n-->\r\n</style></head><body dir="ltr"><div class="elementToProof" style="font-family:Aptos,Aptos_EmbeddedFont,Aptos_MSFontService,Calibri,Helvetica,sans-serif; font-size:12pt; color:rgb(0,0,0)"><br></div></body></html>',
    contentType: 'html',
  },
  flag: {
    flagStatus: 'flagged',
  },
  from: {
    emailAddress: {
      name: 'Bob Smith',
      address: 'bob@sightglass.ai',
    },
  },
  isRead: true,
  sender: {
    emailAddress: {
      name: 'Bob Smith',
      address: 'bob@sightglass.ai',
    },
  },
  isDraft: false,
  replyTo: [],
  subject: 'Test',
  webLink: 'http://test.test/ls/click',
  '@removed': null,
  changeKey: 'abc1234',
  categories: [],
  importance: 'normal',
  '@odata.etag': 'abc123',
  '@odata.type': '#microsoft.graph.message',
  bodyPreview: '',
  ccRecipients: [],
  sentDateTime: '2024-04-22T22:33:00Z',
  toRecipients: [
    {
      emailAddress: {
        name: 'fred@sightglass.ai',
        address: 'fred@sightglass.ai',
      },
    },
  ],
  bccRecipients: [],
  conversationId: 'abc123',
  hasAttachments: false,
  parentFolderId: 'abc123',
  createdDateTime: '2024-04-22T22:33:00Z',
  receivedDateTime: '2024-04-22T22:33:01Z',
  conversationIndex: 'abc123',
  internetMessageId: '<PH7P21234@PH7P1234.NAMP1234.PROD.OUTLOOK.COM>',
  lastModifiedDateTime: '2024-04-22T22:33:24Z',
  isReadReceiptRequested: false,
  inferenceClassification: 'focused',
  isDeliveryReceiptRequested: false,
};

export class Outlook365NodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Outlook Email Contents';
  public nodeLabel = 'Outlook Email Contents';
  public getNodeIcon(className?: string): JSX.Element {
    return <SiMicrosoftoutlook className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <Outlook365EmailContent {...baseProps}></Outlook365EmailContent>;
  }
  public createStep(): Step {
    return {
      nodeType: 'DataSource',
      data: {
        type: 'json',
        data: {
          content_type: 'OutlookEmailMessage',
          content: EXAMPLE as any as JsonValue,
        },
      },
      label: this.nodeLabel,
      parentNode: null,
      templateId: null,
      uuid: crypto.randomUUID(),
      inputSource: null,
    };
  }
}
export const OUTLOOK_EMAIL_CONTENT_NODE_DEF = new Outlook365NodeDefinition();

enum ViewType {
  Email,
  Raw,
}

export function Outlook365EmailContent({
  data,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [example, setExample] = useState<any>(EXAMPLE);
  const [tempValue, setTempValue] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [component, setComponent] = useState<ViewType>(ViewType.Email);
  const [to, setTo] = useState<string>('');
  const [from, setFrom] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [subject, setSubject] = useState<string>('');

  useEffect(() => {
    if (data) {
      const jsonData = data as DataNodeDefData;

      if (jsonData.content && Object.keys(jsonData.content).length > 0) {
        setExample(jsonData.content);
      }
    }
  }, [data]);

  useEffect(() => {
    if (example.toRecipients && example.toRecipients.length > 0) {
      setTo(example.toRecipients[0].emailAddress.address);
    }

    setFrom(example.from?.emailAddress?.address);
    setSubject(example.subject);
    setBody(example.body.content);
  }, [example]);

  const sendUpdate = (example: JsonValue | undefined) => {
    onUpdateData({
      content: example || {},
      content_type: 'OutlookEmailMessage' as JSONContentType,
    });
  };

  const saveEdit = () => {
    if (component === ViewType.Email) {
      const update = {...example};
      update.to = to;
      update.from = from;
      update.subject = subject;
      update.text = body;
      update.html = body;
      sendUpdate(update);
      setError(null);
    } else {
      try {
        const val = JSON.parse(tempValue);
        sendUpdate(val);
        setError(null);
      } catch (err) {
        setError('Invalid Json ' + err);
      }
    }

    setIsEditing(false);
  };

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="text-base">
        The following is an example of the content provided by an Outlook 365
        email. Values can be modified to run manual tests.
      </div>
      <div className="tabs tabs-boxed" role="tablist">
        <a
          className={`tab ${component === ViewType.Email ? 'tab-active' : ''}`}
          onClick={event => {
            event.preventDefault();
            setComponent(ViewType.Email);
          }}
        >
          <EnvelopeIcon className="w-4 mr-2" />
          Email View
        </a>
        <a
          className={`tab ${component === ViewType.Raw ? 'tab-active' : ''}`}
          onClick={event => {
            event.preventDefault();
            setComponent(ViewType.Raw);
          }}
        >
          <DocumentTextIcon className="w-4 mr-2" />
          Raw View
        </a>
      </div>
      <div>
        <div className="flex justify-end py-2 w-full">
          {isEditing ? (
            <button
              className="btn btn-xs btn-success"
              onClick={() => saveEdit()}
            >
              <CheckIcon className="w-4" />
              save
            </button>
          ) : (
            <button
              className="btn btn-xs"
              onClick={() => {
                setIsEditing(true);
                setTempValue(JSON.stringify(example, undefined, 2));
              }}
            >
              <PencilSquareIcon className="w-4" />
              edit
            </button>
          )}
        </div>
      </div>
      {component === ViewType.Email ? (
        <div className="flex flex-col w-full">
          <div className="flex flex-row gap-2">
            <label className="form-control w-full max-w-xs">
              <div className="label">
                <span className="label-text">To</span>
              </div>
              <input
                type="text"
                placeholder="To"
                disabled={!isEditing}
                value={to}
                onChange={event => {
                  setTo(event.target.value);
                }}
                className="input input-bordered w-full max-w-xs"
              />
            </label>
            <label className="form-control w-full max-w-xs">
              <div className="label">
                <span className="label-text">From</span>
              </div>
              <input
                type="text"
                placeholder="From"
                disabled={!isEditing}
                value={from}
                onChange={event => {
                  setFrom(event.target.value);
                }}
                className="input input-bordered w-full max-w-xs"
              />
            </label>
          </div>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Subject</span>
            </div>
            <input
              type="text"
              placeholder="Subject"
              disabled={!isEditing}
              value={subject}
              onChange={event => {
                setSubject(event.target.value);
              }}
              className="input input-bordered w-full "
            />
          </label>
          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text">Email Body</span>
            </div>
            <textarea
              className="textarea textarea-bordered h-32 w-full"
              disabled={!isEditing}
              placeholder="Email Body"
              onChange={event => {
                setBody(event.target.value);
              }}
              value={body}
            ></textarea>
          </label>
        </div>
      ) : (
        <>
          {isEditing ? (
            <textarea
              className="textarea textarea-primary w-full h-48"
              defaultValue={JSON.stringify(example, undefined, 2)}
              onChange={event => {
                setTempValue(event.target.value);
              }}
            ></textarea>
          ) : (
            <JsonView value={example} style={githubDarkTheme} collapsed={1} />
          )}
          <span className="text-error text-xs">{error}</span>
        </>
      )}
    </div>
  );
}
