import {RefObject, useCallback, useContext, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {UserContext} from '../../context';
import {CollectionType} from '../../api/client/collection';
import {toast} from 'react-toastify';
import {ModalType} from '../modals';
import {CloudArrowUpIcon} from '@heroicons/react/20/solid';
import {AxiosProgressEvent} from 'axios';
import {MAX_FILE_SIZE, SightglassClient} from '../../api/Client';

type UploadUpdateCallback = (filename: string, progress: number) => void;

interface CollectionDropUploadProps {
  // Leave out to create a new collection for each drop.
  collection?: string;
  onUploadFinished: (files: File[]) => void;
  // When an upload first starts
  onUploadStart?: (files: File[]) => void;
  onUploadUpdate?: UploadUpdateCallback;
  children: React.ReactNode;
}

export async function handleCollectionUpload(
  client: SightglassClient,
  collectionUuid: string,
  files: File[],
  onUploadUpdate: UploadUpdateCallback = () => {}
) {
  const uploads = [];
  // Loop through each file and upload them in parallel.
  for (const file of files) {
    console.debug('file size ', file.size);
    if (file.size > MAX_FILE_SIZE) {
      toast.error(
        `File too large to upload, max 500 MB. Skipping ${file.name}`
      );
      continue;
    }
    // Set initial upload state
    const toastRef = toast(`Uploading ${file.name}...`, {
      progress: 0,
      autoClose: false,
    });

    uploads.push(
      client?.collection
        .uploadDocument(
          collectionUuid,
          file.name,
          file.size,
          file,
          (p: AxiosProgressEvent) => {
            toast.update(toastRef, {progress: p.progress ?? 0});
            onUploadUpdate(file.name, p.progress ?? 0);
          }
        )
        .then(() => {
          toast.done(toastRef);
          toast(`Finished uploading ${file.name}!`, {
            theme: 'colored',
            type: 'success',
          });
        })
        .catch(err => {
          toast.done(toastRef);
          toast.error(`Network Error uploading ${file.name}!`);
          throw err;
        })
    );
  }

  return Promise.all(uploads);
}

export function CollectionDropUpload({
  collection,
  onUploadFinished,
  onUploadStart = () => {},
  onUploadUpdate,
  children,
}: CollectionDropUploadProps) {
  const {client} = useContext(UserContext);
  const [isDragging, setIsDragging] = useState(false);
  const dropIndicatorModal = useRef<ModalType>();
  const handleOnDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsDragging(false);
      if (acceptedFiles.length === 0) {
        // Nothing to do here;
        return;
      }

      // Create a new collection.
      let collection_uuid;
      if (!collection) {
        const response = await client?.collection
          .create('New Collection', CollectionType.General)
          .catch(err => {
            console.error(err);
            toast('Unable to upload files', {theme: 'colored', type: 'error'});
          });

        if (response && response.result) {
          collection_uuid = response.result.uuid;
        }
      } else {
        collection_uuid = collection;
      }

      if (!collection_uuid) {
        console.error('No collection_uuid set.');
        toast('Unable to upload files', {theme: 'colored', type: 'error'});
        return;
      }

      if (client) {
        onUploadStart(acceptedFiles);
        await handleCollectionUpload(
          client,
          collection_uuid,
          acceptedFiles,
          onUploadUpdate
        )
          .then(() => onUploadFinished(acceptedFiles))
          .catch(() => {});
      }
    },
    [client, collection, onUploadFinished, onUploadStart, onUploadUpdate]
  );

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: handleOnDrop,
    noClick: true,
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
    onDropRejected: () => setIsDragging(false),
  });
  return (
    <div {...getRootProps()} className="w-full">
      <input {...getInputProps()} />
      {children}
      <dialog
        ref={dropIndicatorModal as RefObject<HTMLDialogElement>}
        className={`bg-transparent outline-none modal ${
          isDragging ? 'modal-open' : ''
        }`}
      >
        <div className="modal-box border border-accent w-fit flex flex-col items-center gap-2">
          <CloudArrowUpIcon className="w-28" />
          <h3 className="font-bold text-lg">Drop files to upload</h3>
        </div>
      </dialog>
    </div>
  );
}
