import {RefObject} from 'react';

export interface ModalType extends HTMLDialogElement {
  showModal: () => void;
  close: () => void;
  open: boolean;
  returnValue: any;
}

export interface ModalProps {
  children: React.ReactNode;
  modalRef: RefObject<ModalType>;
  className?: string;
}

function Modal({children, modalRef, className = 'modal'}: ModalProps) {
  return (
    <dialog ref={modalRef as RefObject<HTMLDialogElement>} className="modal">
      <form
        method="dialog"
        className={`modal-box border-2 border-primary ${className}`}
      >
        <div>{children}</div>
        <div className="modal-action mt-auto">
          <button className="btn btn-sm btn-error">Cancel</button>
        </div>
      </form>
    </dialog>
  );
}

export default Modal;
