import {ApiResponse} from '../../../../bindings/api/ApiResponse';
import {
  GeneralTaskStatus,
  SightglassClient,
  UserWorkflow,
  WorkflowTaskResponse,
  waitForWorkflowCompletion,
} from '../../../api/Client';
import {LastRunDetails, NodeResult, NodeResultStatus} from '../types/node';
import {DateTime} from 'luxon';
import {Observable} from 'rxjs';

export async function runWorkflow(
  client: SightglassClient,
  workflow: UserWorkflow,
  cancelListener: Observable<boolean>,
  onRunningNodeChange: (uuid: string) => void,
  onResultChange: (nodeResults: Map<string, LastRunDetails>) => void
): Promise<NodeResult | null> {
  if (workflow.flow.steps.length > 0) {
    const {result: instanceUuid} = await client.workflow.trigger(workflow.uuid);
    if (!instanceUuid) {
      console.error('Unable to run workflow');
      return {
        status: NodeResultStatus.Error,
        error: 'Unable to run workflow',
      } as NodeResult;
    }

    const finalUpdate = await waitForWorkflowCompletion(
      client,
      instanceUuid,
      cancelListener,
      update => {
        if (update.result) {
          const resultChange = updateResults(update, onRunningNodeChange);
          onResultChange(resultChange);
        }
      }
    );

    if (finalUpdate.result) {
      const resultChange = updateResults(finalUpdate, onRunningNodeChange);
      onResultChange(resultChange);

      if (finalUpdate.result.tasks.length > 0) {
        const lastUpdate =
          finalUpdate.result.tasks[finalUpdate.result.tasks.length - 1];
        if (lastUpdate.status === GeneralTaskStatus.Failed) {
          return {
            status: NodeResultStatus.Error,
            error: lastUpdate.error,
          };
        } else {
          return {
            status: NodeResultStatus.Ok,
            data: lastUpdate.result,
          };
        }
      }
    }

    return {
      status: NodeResultStatus.Error,
      error: 'No Final Result Found',
    } as NodeResult;
  }

  return {
    status: NodeResultStatus.Ok,
    data: {
      content: 'No steps to execute',
    },
  } as NodeResult;
}

function updateResults(
  update: ApiResponse<WorkflowTaskResponse>,
  onRunningNodeChange: (uuid: string) => void
): Map<string, LastRunDetails> {
  const results: Map<string, LastRunDetails> = new Map();
  if (update.result) {
    for (const task of update.result.tasks) {
      const node_uuid = task.uuid.split('|')[1];
      if (
        task.status === GeneralTaskStatus.Started ||
        task.status === GeneralTaskStatus.Processing
      ) {
        onRunningNodeChange(node_uuid);
      }

      if (task.status === GeneralTaskStatus.Failed) {
        results.set(node_uuid, {
          startTimestamp: DateTime.fromISO(task.startedOn).toJSDate(),
          endTimestamp: DateTime.fromISO(task.finishedOn).toJSDate(),
          nodeResult: {
            status: NodeResultStatus.Error,
            error: task.error,
          },
        } as LastRunDetails);
      } else if (task.status === GeneralTaskStatus.Complete) {
        results.set(node_uuid, {
          startTimestamp: DateTime.fromISO(task.startedOn).toJSDate(),
          endTimestamp: DateTime.fromISO(task.finishedOn).toJSDate(),
          nodeResult: {
            status: NodeResultStatus.Ok,
            data: task.result,
          },
        } as LastRunDetails);
      }
    }
  }
  return results;
}
