import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {Step} from '../../../../../bindings/Step';
import {RingCentralIcon} from '../../../icons/ringcentral';
import {DocumentInputNode} from '../DocumentInputNode';

export class RingCentralNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'RingCentral Recording';
  public nodeLabel = 'RingCentral Recording';
  public getNodeIcon(className?: string): JSX.Element {
    return <RingCentralIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <DocumentInputNode {...baseProps}></DocumentInputNode>;
  }
  public createStep(): Step {
    return {
      nodeType: 'DataSource',
      data: {
        type: 'document',
        data: {
          collection: '',
          document: '',
          segments: null,
        },
      },
      label: this.nodeLabel,
      parentNode: null,
      templateId: null,
      uuid: crypto.randomUUID(),
      inputSource: null,
    };
  }
}
export const RINGCENTRAL_CONTENT_NODE_DEF = new RingCentralNodeDefinition();
