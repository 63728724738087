import {NodeBodyProps, NodeDefinition} from '../nodes';
import {Step} from '../../../../bindings/Step';
import {WorkflowSummary} from '../../../api/Client';
import {useContext, useEffect, useRef, useState} from 'react';

import {UserContext} from '../../../context';
import {UserIcon} from '@heroicons/react/20/solid';
import {AssistantNode} from '../../../../bindings/AssistantNode';
import {EditableTextarea} from '../editable';
import {Paginator} from '../../pager';
import {useNavigate} from 'react-router-dom';

export class AssistantNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Email Assistant';
  public nodeLabel = 'Email Assistant';
  public getNodeIcon(className?: string): JSX.Element {
    return <UserIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <AssistantNodeDisplay {...baseProps}></AssistantNodeDisplay>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'Assistant',
      templateId: null,
      data: {assistantUuid: null, workflows: []},
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const ASSISTANT_NODE_DEF = new AssistantNodeDefinition();

export function AssistantNodeDisplay({
  uuid,
  data,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const {client} = useContext(UserContext);
  const assistantNode = data as AssistantNode;
  const [selectionOpen, setSelectionOpen] = useState<boolean>(false);
  const [nameMap, setNameMap] = useState<{[key: string]: string}>({});

  useEffect(() => {
    client?.workflow.listAll().then(workflows => {
      if (workflows.result) {
        const newMap: {[key: string]: string} = {};
        for (const workflow of workflows.result) {
          newMap[workflow.uuid] = workflow.name || 'Untitled Workflow';
        }

        setNameMap(newMap);
      }
    });
  }, [data]);

  const updateChanges = (changes: {[key: string]: boolean}) => {
    const data = {...assistantNode};

    const filteredData = data.workflows?.filter(item => {
      const removed = changes[item.uuid];
      delete changes[item.uuid];
      if (removed !== undefined && removed === false) {
        return false;
      }
      return true;
    });

    data.workflows = filteredData || [];

    for (const uuid in changes) {
      const include = changes[uuid];
      if (include) {
        data.workflows.push({
          description: '',
          uuid: uuid,
        });
      }
    }

    onUpdateData(data);
    setSelectionOpen(false);
  };

  const updateDescription = (uuid: string, description: string) => {
    const data = {...assistantNode};

    if (data.workflows) {
      const index = data.workflows?.findIndex(flow => {
        return flow.uuid === uuid;
      });

      if (index !== -1) {
        const definition = {...data.workflows[index]};
        definition.description = description;
        data.workflows[index] = definition;
      }

      onUpdateData(data);
    }
  };

  return (
    <div key={uuid} className="flex flex-col gap-4">
      <div className="flex flex-col">
        <label className="uppercase font-semibold text-lg mb-1">
          Assistant Workflows
        </label>
        <span className="text-sm">
          To add additional capabilities to the email assistant add workflows
          that the assistant can execute. Provide a description that explains
          what the workflow does and when it should be used. This information
          will be used by the assistant to decide when it should execute the
          workflow.
        </span>
        <div className="my-4 flex flex-col gap-4">
          {assistantNode.workflows?.map(workflow => {
            return (
              <AssistantWorkflow
                onDelete={() => {
                  const update: {[key: string]: boolean} = {};
                  update[workflow.uuid] = false;
                  updateChanges(update);
                }}
                name={nameMap[workflow.uuid]}
                description={workflow.description}
                uuid={workflow.uuid}
                onDescriptionUpdate={description => {
                  updateDescription(workflow.uuid, description);
                }}
              ></AssistantWorkflow>
            );
          })}
        </div>

        <div className="justify-center">
          <button
            className="btn btn-primary "
            onClick={() => {
              setSelectionOpen(!selectionOpen);
            }}
          >
            Add Workflow To Assistant
          </button>
        </div>
        {selectionOpen ? (
          <WorkflowSelectionDialog
            open={selectionOpen}
            currentSelection={assistantNode}
            onChange={changes => {
              updateChanges(changes);
            }}
          ></WorkflowSelectionDialog>
        ) : null}
      </div>
    </div>
  );
}

export interface AssistantWorkflowNodeProps {
  onDelete: () => void;
  onDescriptionUpdate: (description: string) => void;
  uuid: string;
  name: string;
  description: string;
}

export function AssistantWorkflow({
  onDelete,
  onDescriptionUpdate,
  uuid,
  name,
  description,
}: AssistantWorkflowNodeProps) {
  const nav = useNavigate();
  return (
    <div className="flex flex-col card card-body bg-base-100 shadow-xl">
      <div className="card-title flex flex-col">
        <div className="text-lg font-semibold justify-center">
          {name} Workflow
        </div>
      </div>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Workflow Description</span>
        </div>
        <EditableTextarea
          className="h-20 textarea textarea-bordered w-full"
          data={description}
          onChange={newVal => {
            onDescriptionUpdate(newVal);
          }}
        ></EditableTextarea>
      </label>
      <div className="card-actions justify-end">
        <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            nav(`/workflows/${uuid}`);
          }}
        >
          Open
        </button>
        <button
          className="btn btn-sm btn-error"
          onClick={() => {
            onDelete();
          }}
        >
          Remove
        </button>
      </div>
    </div>
  );
}

interface WorkflowSelectionDialogProps {
  open: boolean;
  currentSelection: AssistantNode;
  onChange: (updates: {[key: string]: boolean}) => void;
}

function WorkflowSelectionDialog({
  open,
  currentSelection,
  onChange,
}: WorkflowSelectionDialogProps) {
  const {client} = useContext(UserContext);
  const [page, setPage] = useState<number>(0);
  const [numPages, setNumPages] = useState<number>(0);
  const [workflows, setWorkflows] = useState<WorkflowSummary[]>([]);
  const [loadingWorkflows, setLoadingWorkflows] = useState<boolean>(false);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [changes, setChanges] = useState<{[key: string]: boolean}>({});

  useEffect(() => {
    setChanges(() => {
      const newChanges: {[key: string]: boolean} = {};
      if (currentSelection.workflows) {
        for (const workflow of currentSelection.workflows) {
          newChanges[workflow.uuid] = true;
        }
      }
      return newChanges;
    });
  }, [currentSelection]);

  useEffect(() => {
    if (dialogRef.current && open) {
      dialogRef.current.showModal();
    }
  }, [open, dialogRef]);

  useEffect(() => {
    setLoadingWorkflows(true);
    client
      ?.getWorkflowOverview(page)
      .then(workflows => {
        if (workflows.result) {
          setWorkflows(workflows.result.workflows);
          setNumPages(workflows.result.totalCount / workflows.result.pageSize);
        }
      })
      .finally(() => {
        setLoadingWorkflows(false);
      });
  }, [page, client]);

  return (
    <dialog ref={dialogRef} id="workflowSelectionDialog" className="modal">
      <form method="dialog" className="modal-box flex flex-col gap-4 w-full">
        <h2 className="text-2xl font-bold">Select Workflow to Add</h2>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Workflow</th>
              <th>UUID</th>
            </tr>
          </thead>
          <tbody>
            {workflows.map(workflow => {
              return (
                <tr>
                  <td>
                    <input
                      key={`selected-workflow-${workflow.uuid}`}
                      type="checkbox"
                      defaultChecked={changes[workflow.uuid]}
                      onChange={val => {
                        const checkedValue = val.currentTarget.checked;
                        setChanges(currentChanges => {
                          const newChanges = {...currentChanges};
                          newChanges[workflow.uuid] = checkedValue;

                          return newChanges;
                        });
                      }}
                      className="checkbox"
                    />
                  </td>
                  <td>{workflow.name}</td>
                  <td className="text-xs">{workflow.uuid}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {!loadingWorkflows ? (
          <Paginator
            currentPage={page}
            preventDefault={true}
            numPages={numPages}
            onSelectPage={newPage => {
              setPage(newPage);
            }}
          ></Paginator>
        ) : null}
        {loadingWorkflows ? <span className="loading loading-spinner" /> : null}
        <div className="flex flex-row gap-2 w-full justify-end">
          <button
            className="btn btn-primary"
            onClick={event => {
              event.preventDefault();
              onChange(changes);
            }}
          >
            Add
          </button>
          <button className="btn btn-secondary">Cancel</button>
        </div>
      </form>
    </dialog>
  );
}
