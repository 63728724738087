import {BookOpenIcon} from '@heroicons/react/20/solid';
import {NodeBodyProps, NodeDefinition} from '../nodes';
import {Step} from '../../../../bindings/Step';
import {SummarizeNode} from '../../../../bindings/SummarizeNode';
import {useState} from 'react';

export class SummarizeNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Summarize';
  public nodeLabel = 'Summarize';
  public getNodeIcon(className?: string): JSX.Element {
    return <BookOpenIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <Summarize {...baseProps}></Summarize>;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'Summarize',
      templateId: null,
      data: {
        combine: true,
      } as SummarizeNode,
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const SUMMARIZE_NODE_DEFINITION = new SummarizeNodeDefinition();

export default function Summarize({
  data,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const summarizeConfig = data as SummarizeNode;
  const [combine, setCombine] = useState<boolean>(summarizeConfig.combine);

  const toggleCombine = () => {
    const newValue = !combine;
    onUpdateData({combine: newValue});
    setCombine(newValue);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="text-neutral-content">
        Generates a bullet point summary of the document(s) from the previous
        step.
      </div>
      <div className="divider my-0"></div>
      <div className="flex flex-col gap-4">
        <label className="form-control flex flex-row w-full justify-between items-center gap-4">
          <div>
            <span className="label-text text-base font-bold">
              Combine Summaries
            </span>
            <div className="text-xs text-neutral-500">
              When enabled, will summarize all documents from the previous step
              as one large single document.
            </div>
          </div>
          <input
            type="checkbox"
            className="toggle toggle-primary"
            checked={combine}
            onChange={() => toggleCombine()}
          />
        </label>
      </div>
    </div>
  );
}
