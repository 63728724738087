import {RefObject, useEffect, useRef} from 'react';
import {
  createConfirmation,
  createMountPoint,
  createReactTreeMounter,
} from 'react-confirm';
import {ModalType} from '../modals';

interface ConfirmDialogProps {
  title?: string;
  message?: string;
  confirmClass?: string;
  confirmLabel?: string;
  proceed: (confirm: boolean) => void;
}

function ConfirmDialog({
  title,
  message,
  proceed,
  confirmClass,
  confirmLabel,
}: ConfirmDialogProps) {
  const dialog = useRef<ModalType>(null);
  const handleConfirmation = (result: boolean) => {
    dialog.current?.close();
    proceed(result);
  };

  useEffect(() => {
    dialog.current?.showModal();
  }, []);

  const confirmClasses = `btn btn-sm ${confirmClass ?? 'btn-primary'}`;
  return (
    <dialog ref={dialog as RefObject<HTMLDialogElement>} className="modal">
      <div className="modal-box">
        {title ? <h3 className="font-bold text-lg">{title}</h3> : null}
        <p className="py-4">{message}</p>
        <div className="modal-action">
          <form method="dialog">
            <div className="gap-2 flex flex-row">
              <button
                className="btn btn-sm btn-neutral"
                onClick={() => handleConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className={confirmClasses}
                onClick={() => handleConfirmation(true)}
              >
                {confirmLabel}
              </button>
            </div>
          </form>
        </div>
      </div>
    </dialog>
  );
}
const mounter = createReactTreeMounter();
export const DialogMountPoint = createMountPoint(mounter);

export interface ConfirmButtonProps {
  title?: string;
  message: string;
  confirmLabel?: string;
  confirmClass?: string;
  className?: string;
  children?: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const confirm = createConfirmation(ConfirmDialog);

export function ConfirmButton({
  title,
  message,
  className,
  children,
  confirmClass = 'btn-error',
  confirmLabel = 'Confirm',
  onConfirm = () => {},
  onCancel = () => {},
}: ConfirmButtonProps) {
  const handleConfirm = (confirm: boolean) => {
    return confirm ? onConfirm() : onCancel();
  };

  return (
    <button
      className={className}
      onClick={() =>
        confirm({
          title,
          message,
          confirmClass,
          confirmLabel,
          proceed: handleConfirm,
        })
      }
    >
      {children}
    </button>
  );
}
