import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../../../context';
import {WorkflowTrigger} from '../../../../../bindings/WorkflowTrigger';
import {TriggerDefinition, TriggerProps} from '../../nodes';
import {UserConnection} from '../../../../api/Client';
import {SiMicrosoftoutlook} from '@icons-pack/react-simple-icons';

export class Outlook365TriggerDefinition implements TriggerDefinition {
  public defaultTriggerConfig: WorkflowTrigger = {
    collection: null,
    email: null,
    hubspot: null,
    microsoft: {
      outlookTrigger: {
        connectionUuid: '',
        triggerType: 'FlaggedEmails',
      },
    },
    schedule: null,
    ringcentral: null,
  };
  public renderTrigger(baseProps: TriggerProps): JSX.Element {
    return <Outlook365TriggerConfig {...baseProps}></Outlook365TriggerConfig>;
  }
}

export const OUTLOOK365_TRIGGER_DEF = new Outlook365TriggerDefinition();

export function Outlook365TriggerConfig({
  config,
  onUpdate = () => {},
}: TriggerProps) {
  const {client} = useContext(UserContext);

  const [userConns, setUserConns] = useState<UserConnection[]>([]);
  const [connectionId, setConnectionId] = useState<string>('not-selected');

  useEffect(() => {
    if (client) {
      client.listConnections().then(result => {
        const connections = result.result;
        if (connections) {
          setUserConns(
            connections.filter(
              connection => connection.apiId === 'graph.microsoft.com+outlook'
            )
          );
        }
      });
    }
  }, [client]);

  useEffect(() => {
    const connection = config.microsoft?.outlookTrigger?.connectionUuid;
    if (config && connection && connection.trim().length > 0) {
      setConnectionId(connection);
    }
  }, [config]);

  return (
    <div className="flex flex-col gap-4">
      <div className="join items-center bg-base-100">
        <div className="join-item pl-4">
          <SiMicrosoftoutlook className="join-item w-4 h-4"></SiMicrosoftoutlook>
        </div>
        <select
          className="input join-item w-full placeholder:text-gray-700"
          onChange={event => {
            const connectionId = event.target.value;
            if (connectionId) {
              setConnectionId(connectionId);
              if (onUpdate) {
                onUpdate({
                  trigger: {
                    collection: null,
                    email: null,
                    hubspot: null,
                    schedule: null,
                    ringcentral: null,
                    microsoft: {
                      outlookTrigger: {
                        connectionUuid: connectionId,
                        triggerType: 'FlaggedEmails',
                      },
                    },
                  },
                });
              }
            }
          }}
          value={connectionId}
        >
          <option disabled value="not-selected">
            Select an account
          </option>
          {userConns.map((conn, idx) => (
            <option key={`conn-${idx}`} value={conn.id}>
              {conn.account}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
