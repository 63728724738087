import {useContext} from 'react';
import {UserContext} from '../context';

export interface FeatureProps {
  feature: String;
}

export function Feature({
  feature,
  children,
}: React.PropsWithChildren<FeatureProps>) {
  const {profile} = useContext(UserContext);

  if (profile?.features) {
    const enabled_feature = profile.features.enabledFeatures.find(
      enabledFeature => {
        return enabledFeature.feature === feature;
      }
    );

    if (enabled_feature) {
      return <>{children}</>;
    }
  }

  return null;
}
