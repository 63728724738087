import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  CalendarIcon,
  CreditCardIcon,
} from '@heroicons/react/20/solid';
import {MouseEvent, RefObject, useContext, useState} from 'react';
import {UserContext} from '../../context';
import {ModalType} from '.';

export interface ModalProps {
  modalRef: RefObject<ModalType>;
}

const PAYMENT_LINKS = {
  free: {
    monthly: 'free',
    yearly: 'free',
  },
  starter: {
    monthly: 'starter_monthly',
    yearly: 'starter_yearly',
  },
  pro: {
    monthly: 'pro_monthly',
    yearly: 'pro_yearly',
  },
};

function SubscribeModal({modalRef}: ModalProps) {
  const [isMonthly, setIsMonthly] = useState<boolean>(true);
  return (
    <dialog ref={modalRef as RefObject<HTMLDialogElement>} className="modal">
      <form method="dialog" className="modal-box">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-between items-center">
            <h3 className="font-bold text-2xl">Choose a Plan</h3>
            <a
              href="https://sightglass.ai/#pricing"
              className="link flex flex-row items-center gap-2"
              target="_blank"
            >
              <ArrowTopRightOnSquareIcon className="w-4" />
              Compare plans
            </a>
          </div>
          <div>
            <div className="flex flex-row items-center gap-2 place-content-center">
              <div>Monthly</div>
              <input
                type="checkbox"
                className="toggle toggle-lg bg-accent hover:bg-accent border-accent"
                checked={!isMonthly}
                onChange={() => setIsMonthly(!isMonthly)}
                readOnly
              />
              <div>Yearly</div>
            </div>
            <div className="text-sm text-center mt-2">
              <span className="text-accent">2 months free</span> with yearly
              subscription
            </div>
          </div>
          <Tier
            name="Free"
            showMonthly={isMonthly}
            monthly={0}
            yearly={0}
            isFreeTier={true}
          />
          <Tier
            name="Starter"
            showMonthly={isMonthly}
            monthly={20}
            yearly={200}
            paymentLink={
              isMonthly
                ? PAYMENT_LINKS['starter']['monthly']
                : PAYMENT_LINKS['starter']['yearly']
            }
          />
          <Tier
            name="Pro"
            showMonthly={isMonthly}
            monthly={200}
            yearly={2000}
            paymentLink={
              isMonthly
                ? PAYMENT_LINKS['pro']['monthly']
                : PAYMENT_LINKS['pro']['yearly']
            }
          />
          <div className="p-4 bg-neutral rounded-lg flex flex-row items-center justify-between">
            <div>
              <div className="text-lg font-serif font-bold">Enterprise</div>
            </div>
            <div>
              <a
                className="btn btn-primary"
                href="https://calendly.com/andrew-sightglass/30-minute-chat"
              >
                <CalendarIcon className="w-4" />
                Schedule Demo
              </a>
            </div>
          </div>
        </div>
        <div className="text-sm p-2">
          If you have questions about our pricing or have custom requirements,
          please reach out to us directly at{' '}
          <a className="link" href="mailto:team@sightglass.ai">
            team@sightglass.ai
          </a>
        </div>
      </form>
    </dialog>
  );
}

interface TierProps {
  name: string;
  showMonthly?: boolean;
  monthly?: number;
  yearly?: number;
  callToAction?: string;
  paymentLink?: string | null;
  isFreeTier?: boolean;
}

function Tier({
  name,
  showMonthly,
  callToAction,
  monthly,
  yearly,
  paymentLink,
  isFreeTier = false,
}: TierProps) {
  const {client} = useContext(UserContext);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const onCheckout = (e: MouseEvent) => {
    if (paymentLink && !isFreeTier) {
      setInProgress(true);
      e.stopPropagation();
      e.preventDefault();
      return client?.payment
        .checkout(paymentLink)
        .then(result => {
          const url = result.result?.url;
          if (url) {
            window.location.replace(url);
          }
        })
        .catch(res => console.error(res))
        .finally(() => {
          setInProgress(false);
        });
    }
  };

  return (
    <div className="p-4 bg-neutral rounded-lg flex flex-row items-center justify-between">
      <div>
        <div className="text-lg font-serif font-bold">{name}</div>
        {isFreeTier && (
          <>
            <span className="text-accent font-bold">$0</span> /{' '}
            {showMonthly ? 'monthly' : 'yearly'}
          </>
        )}
        {monthly && showMonthly ? (
          <>
            <span className="text-accent font-bold">${monthly}</span> / month
          </>
        ) : null}
        {yearly && !showMonthly ? (
          <>
            <span className="text-accent font-bold">${yearly}</span> / year
          </>
        ) : null}
      </div>
      {isFreeTier ? (
        <button className="btn btn-primary">Continue</button>
      ) : (
        <button
          className="btn btn-primary"
          onClick={onCheckout}
          disabled={inProgress}
        >
          {inProgress ? (
            <ArrowPathIcon className="w-4 animate-spin" />
          ) : (
            <CreditCardIcon className="w-4" />
          )}
          {callToAction ?? 'Checkout'}
        </button>
      )}
    </div>
  );
}

export default SubscribeModal;
