import {SightglassClient} from '../Client';
import {CurrentUser} from '../../../../cloudflow/bindings/api/CurrentUser';
import {UserTokenQuery} from '../../../../cloudflow/bindings/api/UserTokenQuery';
import {ApiKey} from '../../../../cloudflow/bindings/api/ApiKey';
import {ApiKeyResponse} from '../../../../cloudflow/bindings/api/ApiKeyResponse';

export class AuthEndpoints {
  client: SightglassClient;

  constructor(client: SightglassClient) {
    this.client = client;
  }

  async getUserProfile(queryParams?: UserTokenQuery): Promise<CurrentUser> {
    let query: {[key: string]: string} | undefined;
    if (queryParams && queryParams.inviteCode) {
      query = {};
      query.inviteCode = queryParams.inviteCode;
    }

    return this.client.getJson<CurrentUser>('user', query);
  }

  async getApiKeys(): Promise<ApiKeyResponse> {
    return this.client.getJson<ApiKeyResponse>('security/keys');
  }

  async createApiKey(name: string): Promise<ApiKey | null> {
    return this.client
      .postJson<ApiKey>('security/keys', {name})
      .then(data => data.result);
  }

  async updateApiKey(uuid: string, name: string): Promise<ApiKey | null> {
    return this.client
      .putJson<ApiKey>(`security/keys/${uuid}`, {name})
      .then(data => data.result);
  }

  async deleteApiKey(uuid: string): Promise<boolean> {
    return this.client
      .deleteJson<boolean>(`security/keys/${uuid}`)
      .then(() => true);
  }
}
