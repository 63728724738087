import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  NodeDef,
  NodeType,
  PropertyType,
  TemplateNodeDef,
} from '../../types/node';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {parse} from '@handlebars/parser';
import {
  BlockStatement,
  MustacheStatement,
  PathExpression,
  Program,
} from '@handlebars/parser/types/ast';
import {EditableText, EditableTextarea} from '../../editable';
import {UserContext, WorkflowContext} from '../../../../context';
import {getPreviousNode} from '../../workflows/utils';
import {getObjectDefinition} from '../../types/typeutils';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  CodeBracketIcon,
} from '@heroicons/react/20/solid';
import {TextInput} from '../../../modals/TextInput';
import {Step} from '../../../../../bindings/Step';

export class TemplateNodeDefinition implements NodeDefinition {
  public nodeSelectionLabel = 'Template';
  public nodeLabel = 'Expand Template';
  public getNodeIcon(className?: string): JSX.Element {
    return <CodeBracketIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <TemplateNodeView {...baseProps} />;
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'Template',
      templateId: null,
      data: {
        template: '',
        varMapping: {},
      },
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const TEMPLATE_NODE_DEFINITION = new TemplateNodeDefinition();

function checkTemplate(ast: Program, vars: Array<string>) {
  ast.body.forEach(item => {
    if (item.type === 'MustacheStatement') {
      const stmt = item as MustacheStatement;
      if (stmt.path.type === 'PathExpression') {
        const path = stmt.path as PathExpression;
        vars.push(path.original);
      }
    } else if (item.type === 'BlockStatement') {
      const stmt = item as BlockStatement;
      stmt.params.forEach(param => {
        if (param.type === 'PathExpression') {
          const path = param as PathExpression;
          vars.push(path.original);
        }
      });
    }
  });
}

export default function TemplateNodeView({
  data,
  uuid,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const {client} = useContext(UserContext);
  const {workflowDef} = useContext(WorkflowContext);
  const [templateErrors, setTemplateErrors] = useState<string | null>(null);
  const [templateVars, setTemplateVars] = useState<Array<string>>([]);
  const [templateGen, setTemplateGen] = useState<boolean>(false);
  const [hasPreviousNode, setHasPreviousNode] = useState<boolean>(false);
  const inputRef = useRef<HTMLDialogElement>(null);

  const templateData = data as TemplateNodeDef;
  const varMapping = templateData.varMapping;

  useEffect(() => {
    try {
      const ast = parse(templateData.template);
      const vars: Array<string> = [];
      checkTemplate(ast, vars);
      setTemplateVars(vars);
      setTemplateErrors(null);
    } catch (err) {
      setTemplateErrors('' + err);
      console.error(err);
    }
  }, [templateData]);

  useEffect(() => {
    if (workflowDef) {
      const prevNode = getPreviousNode(uuid, workflowDef.flow.steps);
      const val = prevNode !== undefined || workflowDef.input !== undefined;
      setHasPreviousNode(val);
    } else {
      setHasPreviousNode(false);
    }
  }, [workflowDef, uuid]);

  const updateMapping = (varName: string, newValue: any) => {
    const newMapping = {
      ...varMapping,
      [varName]: newValue,
    };
    onUpdateData({
      ...templateData,
      varMapping: newMapping,
    } as TemplateNodeDef);
  };

  const generateNewTemplate = (userDescription: string) => {
    if (workflowDef) {
      const prevNode = getPreviousNode(uuid, workflowDef.flow.steps);
      let getObjectDefPromise;
      if (!prevNode && workflowDef.input) {
        getObjectDefPromise = getObjectDefinition(
          {
            data: workflowDef.input,
            label: '',
            nodeType: NodeType.DataSource,
            parentNode: false,
            uuid: 'INPUT',
          } as NodeDef,
          true,
          {},
          async () => ''
        );
      } else if (prevNode) {
        getObjectDefPromise = getObjectDefinition(
          prevNode,
          true,
          {},
          async () => ''
        );
      }

      if (getObjectDefPromise) {
        getObjectDefPromise.then(mappings => {
          let mappingString;
          if (mappings) {
            mappingString = JSON.stringify(mappings);
          }

          setTemplateGen(true);
          client
            ?.extractJson(
              `Generate a handlebars template that can be used to nicely display the input json data. The json data \
                 will have the following schema: ${mappingString}. For the handlebars template do not use mustache blocks \
                 and only #if #each raw, #unless #ith lookup > and log helpers are available. Note that when generating an html template \
                 no classes can be used only styles directly specified on the element. The provided text is \
                 the current template. follow these instructions while attempting to make the template meet the \
                 specified user description Description: ${userDescription}`,
              `${templateData.template}`,
              {
                type: PropertyType.Object,
                properties: {
                  responseSummary: {
                    type: PropertyType.String,
                  },
                  template: {
                    type: PropertyType.String,
                  },
                },
              }
            )
            .then(response => {
              if (response && response.result && response.result.content) {
                console.error(response?.result.content);
                onUpdateData({
                  template: response?.result.content.template,
                  varMapping,
                } as TemplateNodeDef);
              }
            })
            .finally(() => {
              setTemplateGen(false);
            });
        });
      }
    }
  };

  const actions = [];
  if (hasPreviousNode) {
    actions.push(
      <button
        key="generate-template"
        className="btn btn-sm"
        onClick={() => {
          inputRef?.current?.show();
        }}
      >
        {templateGen ? (
          <span className="loading loading-spinner" />
        ) : (
          <ChatBubbleOvalLeftEllipsisIcon className="w-4 h-4" />
        )}
        Generate Template
      </button>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <EditableTextarea
        label="Template"
        actions={actions}
        data={templateData.template}
        onChange={newTemplate =>
          onUpdateData({template: newTemplate, varMapping} as TemplateNodeDef)
        }
      />
      <div>
        <div className="text-sm text-error">{templateErrors}</div>
      </div>
      <div>
        <table className="table table-fixed w-full table-zebra table-sm">
          <thead className="text-secondary">
            <tr>
              <th>Template Variable</th>
              <th>Input Mapping</th>
            </tr>
          </thead>
          <tbody>
            {templateVars.map(varName => (
              <tr key={varName}>
                <td>{varName}</td>
                <td>
                  <EditableText
                    data={varMapping[varName as keyof object]}
                    onChange={mapping => updateMapping(varName, mapping)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <TextInput
        title="Describe the type of template to generate"
        label=""
        setValue={value => {
          generateNewTemplate(value);
        }}
        dialogRef={inputRef}
        placeholder="Description"
      ></TextInput>
    </div>
  );
}
