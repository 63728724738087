import {RefObject, useEffect, useState} from 'react';
import Modal, {ModalType} from '../../../components/modals';
import {NodeIcon, getNodeDefinition} from '../../../components/talos/nodes';
import {
  DataNodeType,
  NodeDef,
  NodeType,
} from '../../../components/talos/types/node';

interface AddNodeModalProps {
  lastNode: NodeDef | null;
  modalRef: RefObject<ModalType>;
  inLoop: boolean;
  onClick?: (nodeType: NodeType, subType: DataNodeType | null) => void;
}

// todo: validate which nodes can be used based on the last node.
const nodeList = [
  {
    name: 'Basic Actions',
    description: 'Helpful utilities to get you going.',
    nodes: [
      {nodeType: NodeType.Template, subType: null},
      {nodeType: NodeType.PullDocument, subType: null},
      {nodeType: NodeType.Ocr, subType: null},
      {nodeType: NodeType.AggregateOutput, subType: null},
    ],
  },
  {
    name: 'AI Assist',
    description:
      'Use AI to read through, analyze, and/or answer questions about a document or set of documents.',
    nodes: [
      {nodeType: NodeType.Extract, subType: null},
      {nodeType: NodeType.ExtractEmailResponse, subType: null},
      {nodeType: NodeType.ExtractTasksAndDecisions, subType: null},
      {nodeType: NodeType.Summarize, subType: null},
      {nodeType: NodeType.Assistant, subType: null},
    ],
  },
  {
    name: 'Audio',
    description: 'Handle common audio tasks.',
    nodes: [
      {nodeType: NodeType.AudioTranscription, subType: null},
      {nodeType: NodeType.BuildVtt, subType: null},
    ],
  },
  {
    name: 'Destinations',
    description: 'Save your output.',
    nodes: [
      {nodeType: NodeType.DataDestination, subType: null},
      {nodeType: NodeType.SendEmail, subType: null},
      {nodeType: NodeType.SaveToCollection, subType: null},
      {nodeType: NodeType.MicrosoftTodo, subType: null},
    ],
  },
];

export default function AddNodeModal({
  modalRef,
  onClick = () => {},
}: AddNodeModalProps) {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [tabTitle, setTabTitle] = useState<string>('');
  const [tabCaption, setTabCaption] = useState<string>('');

  useEffect(() => {
    setTabTitle(nodeList[activeTab].name);
    setTabCaption(nodeList[activeTab].description);
  }, [activeTab]);

  return (
    <Modal modalRef={modalRef} className="sm:w-auto md:w-[38rem] max-w-none">
      <div className="flex flex-col md:flex-row w-full gap-4 md:min-h-[480px]">
        <div className="tabs tabs-boxed flex flex-row md:flex-col tabs-md w-[10rem] flex-none">
          {nodeList.map((nodeType, idx) => (
            <div
              className={`w-full tab md:place-content-end md:py-2 md:max-h-none md:h-auto ${
                activeTab === idx ? 'tab-active md:h-auto' : ''
              }`}
              key={nodeType.name}
              onClick={() => setActiveTab(idx)}
            >
              {nodeType.name}
            </div>
          ))}
        </div>
        <div>
          <div className="mb-4">
            <h2 className="text-xl text-primary font-bold">{tabTitle}</h2>
            <div className="text-sm text-gray">{tabCaption}</div>
          </div>
          {nodeList.map((nodeType, idx) => {
            const activeClass = activeTab === idx ? 'grid' : 'hidden';
            return (
              <div
                className={`${activeClass} grid-cols-2 gap-4`}
                key={`nodetab-${idx}`}
              >
                {nodeType.nodes.map((node, idx) => {
                  const {nodeType, subType} = node;
                  return (
                    <button
                      key={`nodetype-${idx}`}
                      className="btn btn-neutral flex flex-row items-center gap-2"
                      onClick={() => onClick(nodeType, subType)}
                    >
                      <NodeIcon
                        nodeType={nodeType}
                        subType={subType}
                        className="w-4"
                      />
                      <div>
                        {
                          getNodeDefinition(nodeType, subType)
                            .nodeSelectionLabel
                        }
                      </div>
                    </button>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}
