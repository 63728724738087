import {AxiosError, AxiosProgressEvent} from 'axios';
import {API_ENDPOINT, GeneralTaskResponse, SightglassClient} from '../Client';
import {CollectionListing} from '../../../bindings/api/CollectionListing';
import {CollectionResponse} from '../../../bindings/api/CollectionResponse';
import {DetailedDocumentResponse} from '../../../bindings/api/DetailedDocumentResponse';
import {DocumentResponse} from '../../../bindings/api/DocumentResponse';
import {ApiResponse} from '../../../bindings/api/ApiResponse';

export interface CollectionUpdateRequest {
  name: string;
}

export interface DocumentSearchResponse {
  uuid: string;
  displayName: string;
  score: number;
  context: string[];
  metadata?: any;
}

export enum CollectionType {
  General = 'General',
  PodcastEpisode = 'PodcastEpisode',
}

export class CollectionEndpoints {
  client: SightglassClient;
  module: string;

  constructor(client: SightglassClient) {
    this.client = client;
    this.module = 'collections';
  }

  async create(
    name: string,
    type: CollectionType
  ): Promise<ApiResponse<CollectionResponse>> {
    const data = {
      name,
      configuration: {
        typeConfig: type,
      },
    };

    return this.client.postJson<CollectionResponse>(this.module, data);
  }

  async list(): Promise<ApiResponse<CollectionListing[]>> {
    return this.client.getJson(this.module);
  }

  async get(uuid: string): Promise<ApiResponse<CollectionResponse>> {
    return this.client.getJson(`${this.module}/${uuid}`);
  }

  async delete(uuid: string): Promise<ApiResponse<boolean>> {
    return this.client.deleteJson<ApiResponse<boolean>>(`collections/${uuid}`);
  }

  async update(
    uuid: string,
    updates: CollectionUpdateRequest
  ): Promise<ApiResponse<CollectionResponse>> {
    return this.client.patchJson(`${this.module}/${uuid}`, updates);
  }

  async listDocuments(
    collection: string
  ): Promise<ApiResponse<DocumentResponse[]>> {
    return this.client.postJson<DocumentResponse[]>(
      `${this.module}/${collection}/documents`,
      {}
    );
  }

  async downloadDocumentBlob(
    collection: string,
    document: string,
    returnProcessed?: boolean
  ): Promise<Blob> {
    return this.client.get(
      `${this.module}/${collection}/documents/${document}/download`,
      returnProcessed !== undefined ? {returnProcessed} : {},
      {responseType: 'blob'}
    );
  }

  async deleteDocument(
    collection: string,
    document: string
  ): Promise<ApiResponse<any>> {
    return this.client.deleteJson<ApiResponse<any>>(
      `${this.module}/${collection}/documents/${document}`
    );
  }

  async getDocumentDetail(
    collection: string,
    document: string
  ): Promise<ApiResponse<DetailedDocumentResponse>> {
    return this.client.getJson<ApiResponse<DetailedDocumentResponse>>(
      `${this.module}/${collection}/documents/${document}`,
      {detailed: 'true'}
    );
  }

  async updateDocument(
    collection: string,
    document: string,
    updates: {[key: string]: any}
  ): Promise<ApiResponse<DocumentResponse>> {
    return this.client.patchJson<DocumentResponse>(
      `${this.module}/${collection}/documents/${document}`,
      {
        text: null,
        displayName: updates.displayName,
        metadata: updates.metadata,
      }
    );
  }

  async extractFromDocument(
    collection: string,
    document: string,
    query: string,
    schema: any
  ): Promise<ApiResponse<string>> {
    return this.client.postJson(
      `${this.module}/${collection}/documents/${document}/tasks/extract`,
      {
        query: query,
        useContext: true,
        jsonSchema: schema,
      }
    );
  }

  async getAllTasksForCollection(
    collection: string
  ): Promise<ApiResponse<GeneralTaskResponse[]>> {
    return this.client.getJson<ApiResponse<GeneralTaskResponse[]>>(
      `${this.module}/${collection}/task`
    );
  }

  async uploadDocument(
    collection_uuid: string,
    fileName: string,
    fileSize: number,
    file: Blob,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<ApiResponse<DocumentResponse[]>> {
    const file_name = `${fileName}_${fileSize}`;
    const data: {[key: string]: any} = {[file_name]: file};

    return this.client.client
      .postForm(
        `${API_ENDPOINT}/${this.module}/${collection_uuid}/upload`,
        data,
        {
          headers: {Authorization: `Bearer ${this.client.token}`},
          onUploadProgress: progressEvent => {
            if (onUploadProgress) {
              onUploadProgress(progressEvent);
            }
          },
        }
      )
      .then(resp => resp.data)
      .catch((err: AxiosError) => {
        console.error('axios error: ', err);
        throw err;
      })
      .catch(err => {
        console.error('other error: ', err);
        throw err;
      });
  }

  async search(
    collection_uuid: string,
    query: string
  ): Promise<ApiResponse<DocumentSearchResponse[]>> {
    return this.client.client
      .post(`${API_ENDPOINT}/${this.module}/${collection_uuid}/search`, {
        query,
        withContext: true,
        withMetadata: true,
      })
      .then(resp => resp.data)
      .catch((err: AxiosError) => {
        console.error('axios error: ', err);
        throw err;
      })
      .catch(err => {
        console.error('other error: ', err);
        throw err;
      });
  }

  async searchDocumentContext(
    collection_uuid: string,
    document_uuid: string,
    query: string
  ): Promise<ApiResponse<DocumentSearchResponse[]>> {
    return this.client.client
      .post(
        `${API_ENDPOINT}/${this.module}/${collection_uuid}/documents/${document_uuid}/search`,
        {
          query,
          withContext: true,
          withMetadata: true,
        }
      )
      .then(resp => resp.data)
      .catch((err: AxiosError) => {
        console.error('axios error: ', err);
        throw err;
      })
      .catch(err => {
        console.error('other error: ', err);
        throw err;
      });
  }
}
